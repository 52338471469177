<template>
    <div class="days-container" :class="{'full-width': !isSidebarVisible}">
        <button class="day" @click="scrollToCollapseElement('day-collapse-' + day.id)" v-for="(day, index) in travelTip.itineraryDays" :key="day.id">{{ $t("general.dayOrder", [index + 1]) }}</button>
    </div>
</template>

<script>
    export default {
        props: {
            travelTip: {
                type: Object
            },
            isSidebarVisible: {
                type: Boolean,
                required: true
            }
        },
        data() {
            return {

            }
        },
        methods: {
            scrollToCollapseElement(elementId) {
                document.getElementById(elementId)?.scrollIntoView({behavior: "smooth"})
            },
        }
    }

</script>

<style scoped lang="scss">

@import '@/scss/variables';

    .days-container {
        position: fixed;
        background-color: white;
        box-shadow: 0 4px 5px rgba(0, 0, 0, 0.2);
        top: 0;
        z-index: 1040;
        left: calc(50% + 150px);
        transform: translateX(-50%);
        transition: left 0.3s ease-in-out;

        &.full-width {
            left: 50%
        }


        .day {
            background-color: white;
            padding: 10px 20px;
            border: none;

            &:hover {
              background-color: $tr-green;  
            }
        }
    }
</style>