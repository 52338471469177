<template>
    <div class="trip-card">
        <div class="top-part tr-border-bottom">
            <div v-if="trip.adminView && trip.state" class="tag position-absolute" style="top: 16px; z-index: 1;">
                <p>{{trip.state && states.find(val => val.code == trip.state).name}}</p>
            </div>
            <div class="travel-tip-image-wrapper">
                <img v-if="!trip.titlePicture || !trip.titlePicture.fileName" alt="some alt text" src="../../assets/svg/ManageTraveltipDumyImage.svg" class="imgdiv">
                <!-- <div v-else class="imgdiv" v-bind:style="{ backgroundImage: 'url(' + imgUrl+ trip.titlePicture.fileName.slice(0,trip.titlePicture.fileName.lastIndexOf('.')) + '-dimensions-320pxX240px' + trip.titlePicture.fileName.slice(trip.titlePicture.fileName.lastIndexOf('.')) + ')' }"></div> -->
                <!-- TEMPORARY -->
                <div v-else>
                    <div v-if="imgIsLoading" class="imgdiv position-relative">
                        <div class="loading-circle position-absolute"><div></div><div></div><div></div><div></div></div>
                    </div>
                    <img v-show="!imgIsLoading" @load="imgIsLoading=false" class="picture" :src="imgUrl + trip.titlePicture.fileName.slice(0, trip.titlePicture.fileName.lastIndexOf('.')) + '-dimensions-640pxX480px' + trip.titlePicture.fileName.slice(trip.titlePicture.fileName.lastIndexOf('.'))">
                </div>

            </div>
            <div class="trip-title-wrapper">
                <div style="display: flex">
                    <div class="trip-title-wrapper-theme">
                        <img id="trip-title-wrapper-theme-image" :src="imgSource">
                        <span>{{themeFormatted}}</span>
                    </div>
                    <div class="days-length">
                        {{ $tc("general.dayAmmount", trip && trip.itineraryDays.length) }}
                    </div>
                </div>
                <p class="trip-title" :data-title="trip.title">{{trip.localizations[0].title}}</p>
                <div class="trip-countries-container">
                    <span class="trip-countries" :data-title="countriesFormatted">{{countriesFormatted}}</span>
                </div>
                <div class="dotted-line"></div>
            </div>
        </div>
        <div class="bottom-part tr-border-top">
            <div v-if="$route.name != 'adminManageTravelTip'" class="icons-container">
                <div v-if="likedTravelTipIds && likedTravelTipIds.includes(trip.id)" v-tooltip.top="$t('info.removeFromFavourites')">
                    <button class="img-button" @click.prevent="unlikeTravelTip(trip.id)">
                        <img src="@/assets/svg/icon-liked.svg" class="icon-liked" />
                    </button>
                </div>
                <div v-else :class="{'cursor-not-allowed': !isUserLoggedIn}" v-tooltip.top="isUserLoggedIn ? $t('info.addToFavourites') : $t('info.loginToAddToFavourites')">
                    <button class="img-button" @click.prevent="likeTravelTip(trip.id)">
                        <img src="@/assets/svg/icon-my-trips.svg" class="icon-liked"/>
                    </button>
                </div>
                <button class="img-button" @click.prevent="isShareModalVisible = true">
                    <img src="@/assets/svg/icon-share.svg" alt="">
                </button>
            </div>
        </div>
        <ShareModal :travelTipId="trip.id" @hideModal="isShareModalVisible = false" :isVisible="isShareModalVisible" :tripTitle="trip.title"/>
    </div>
</template>

<script>
    import {CLEVERTRIP_IMG_URL}  from '@/definitions';
    import TravelTipService from '@/services/TravelTipService';
    import ShareModal from '../ShareModal.vue';

    export default {

        components: {
            ShareModal
        },

        data: function() {
            return {
                isShareModalVisible: false,
                imgUrl: CLEVERTRIP_IMG_URL,
                themeFormatted: null,
                imgSource: null,
                imgIsLoading: true,
            }
        },

        name: "TripItem",

        props: {
            trip: null,
            isMyTravelTip: {
                type: Boolean
            },
        },

        watch: {
            trip() {
                this.formatTheme();
            }
        },
        created: function() {
            this.formatTheme();
        },

        computed: {
            likedTravelTipIds() {
                return this.$store.state.likedTravelTipIds
            },
            isUserLoggedIn(){
                return this.$store.state.currentUserId != null
            },
            states() {
                return [
                    {code: 'IN_PROGRESS', name: this.$t("admin.inProgress")},
                    {code: 'TO_REWORK', name: this.$t("admin.toRework")},
                    {code: 'TO_CONTROL', name: this.$t("admin.toControl")},
                    {code: 'TO_APPROVE', name: this.$t("admin.toApprove")},
                    {code: 'PUBLISHED', name: this.$t("admin.published")},
                    {code: 'NOT_PUBLISHED', name: this.$t("admin.notPublished")},
                    {code: 'DECLINED', name: this.$t("admin.declined")},
                ]
            },
            countriesFormatted() {
                if (!this.trip) return
                let countriesInTravelTip = []
                this.trip.itineraryDays.forEach(itineraryDay => {
                    itineraryDay.itineraryItems.forEach(itineraryItem => {
                        itineraryItem.countries.forEach(country => {
                            if (!countriesInTravelTip.some(value => value.nameEnglish === country.nameEnglish)) {
                                countriesInTravelTip.push(country);
                            }
                        })
                    })
                })
                return countriesInTravelTip.map(country => country[this.countryLocalization]).join(', ')
            },
            countryLocalization() {
                return this.$store.getters.preferredLanguage == "en" ? "nameEnglish" : "nameCzech"
            }
        },

        methods: {
            async likeTravelTip(id) {
                if (!this.isUserLoggedIn) return
                await TravelTipService.likeTravelTip(id)
                    .then(() => {
                        this.$store.commit('setLikedTravelTipIds', [...this.$store.state.likedTravelTipIds, id])
                    })
            },
            async unlikeTravelTip(id) {
                await TravelTipService.unlikeTravelTip(id)
                    .then(() => {
                        this.$store.commit('setLikedTravelTipIds', this.likedTravelTipIds.filter(travelTipId => travelTipId != id))
                        if (this.isMyTravelTip) {
                            this.$emit('refetchMyTravelTips')
                        }
                    })
            },
            formatCountries() {
                if (!this.trip) return
                let countriesInTravelTip = new Set()
                this.trip.itineraryDays.forEach(itineraryDay => {
                    itineraryDay.itineraryItems.forEach(itineraryItem => {
                        itineraryItem.countries.forEach(country => {
                            countriesInTravelTip.add(country.nameCzech)
                        })
                    })
                })
                this.countriesFormatted = Array.from(countriesInTravelTip).join(', ')
            },
            formatTheme: function() {
                if (this.trip.theme === 'TOURISM') {
                    this.themeFormatted = this.$t("general.nature");
                    this.imgSource = require('@/assets/svg/homepage-theme-background-yellow.svg');
                }
                if (this.trip.theme === 'SPORT') {
                    this.themeFormatted = this.$t("general.adventure");
                    this.imgSource = require('@/assets/svg/homepage-theme-background-blue.svg');
                }
                if (this.trip.theme === 'CULTURE') {
                    this.themeFormatted = this.$t("general.culture");
                    this.imgSource = require('@/assets/svg/homepage-theme-background-orange.svg');
                }
                if (this.trip.theme === 'FOOD') {
                    this.themeFormatted = this.$t("general.food");
                    this.imgSource = require('@/assets/svg/homepage-theme-background-pink.svg');
                }
            },
        }

    }
</script>

<style scoped lang="scss">
    @import '@/scss/variables';

    .imgdiv{
      aspect-ratio: 32 / 24;
      width: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      overflow: hidden;
    }

    img {
        border-radius: unset;
    }

    .trip-card {
        max-width: 330px;
        z-index: 0;
        margin-left: auto;
        margin-right: auto;
        filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.05));
        
        .top-part {
            padding-top: 1px;
            margin-top: -1px;

            .tag p {
                background-color: $tr-black; 
                padding: 5px 10px;
                line-height: 20px;
                text-align: center;
                color: $tr-white
            }

        }
        .bottom-part {
            padding-inline: 20px;
            height: 104px;

            .icons-container {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                width: 100%;
                height: 100%;

                button {
                    width: 40px;
                    height: 40px;
                }
            }
        }
    }

    .trip-card:hover {  
        z-index: 20;
    }

    .trip-countries-container {
        display: flex;
        gap: 5px;
        padding-bottom: 2rem
    }
    .trip-title, .trip-countries {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        color: $tr-black;
        font-size: 20px;
        margin-top: 30px;
        margin-bottom: 0px;
        padding-bottom: 1px;
        position: relative;
        word-break: break-word;
        z-index: 4;

        &::after {
            display: none;
            position: absolute;
            left: -5px;
            top: 0;
            background-color: $tr-white;
            content: attr(data-title);
            width: calc(100% + 10px);
            padding-inline: 5px;
            height: fit-content;
            z-index: 2;
        }

        &:hover {
            overflow: visible;
            &::after {
                display: block;
            }
        }
    }
    .trip-countries {
        color: $tr-gray;
        margin-top: 0 !important;
        font-size: 16px;
        -webkit-line-clamp: 1;
        z-index: 3;
    }
    
    @media (min-width: 992px) {
      .trip-title {
        color: $tr-black;
        font-size: 25px;
        line-height: 30px;
      }
    }

    .trip-title-wrapper{
        height: 176px;
        position: sticky;
        position: relative;
        bottom: -5px;
        text-align: left;
        padding-left: 20px;
        padding-right: 20px;
    }

    .trip-title-wrapper-theme {
        position: relative;
    }

    .trip-title-wrapper-theme span {
        position: absolute;
        width: 100px;
        height: 55px;
        text-align: center;
        line-height: 53px;
        font-size: 16px;
        left: -5px;
        font-weight: bold;
    }

    .trip-title-wrapper-theme img {
        position: absolute;
        width: 100px;
        left: -5px;
    }

    .days-length{
        text-align: right;
        padding-top: 14px;
        font-weight: bold;
        display: block;
        width: 100%;
    }

    .dotted-line {
        position:absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 4px;
        height: 1px;
        width: calc(100% - 62px);
        border-bottom: dashed 3px #cfd8de;
        z-index: 1;
    }

    .travel-tip-image-wrapper {
        margin: 15px;
        width: calc(100% - 30px);
        overflow: hidden;

        img {
            object-fit: cover;
            width: 100%;
            aspect-ratio: 4/3;
        }
    }

    .travel-tip-image-wrapper img, .travel-tip-image-wrapper .imgdiv { transition: all .2s ease-in-out; }
    .trip-card:hover .travel-tip-image-wrapper img, .trip-card:hover .travel-tip-image-wrapper .imgdiv { transform: scale(1.2); }

</style>
