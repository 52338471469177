
import gql from 'graphql-tag'

export const TAG_CATEGORIES = gql`
    query tagCategories {
        tagCategories {
            id,
            localizations {
                title,
                description
            }
            tags {
                id,
                localizations {
                    title
                }
            }
        }
    }`;

export const TAG_CATEGORIES_ADMIN = gql`
    query tagCategories {
        tagCategoriesAdmin {
            id,
            originalLanguage
            localizations {
                language
                title,
                description
            }
            tags {
                id,
                originalLanguage
                localizations {
                    language
                    title
                }
            }
        }
    }`;

export const TAGS = gql`
    query tags {
        tags {
            id
            localizations {
                title
            }
        }
    }`;

export const CAN_DELETE_TAG = gql`
    query canDeleteTag($tagIds: [Int]!) {
        canDeleteTag (tagIds: $tagIds) {
            id,
            localizations {
                title
            }
            tagCategories {
                id
                tags {
                    id
                    localizations {
                        title
                    }
                }
            },
        }
    }`;