<template>
  <div>
    <div v-if="travelTipId && initialLoading" class="admin-page-loading">{{pageLoadingTitle}}</div>
    <div v-show="!travelTipId || !initialLoading" class='text-left admin-travel-tip' :class="{'collapsing-no-animation': !isCollapsingAnimated}">
      <div class="d-flex justify-content-between mb-4">
        <h1 class="travel-tips-title mb-1"><span class="arrow arrow--left mr-3" @click='$router.go(-1)'></span>{{form.localizations.length ? form.localizations[0].title : $t("admin.addTravelTip")}}</h1>
        <div class="buttons-localization" v-if="form.state === 'NOT_PUBLISHED' || form.state === 'PUBLISHED'">
          <TrekioButton v-if="form.localizations.length > 1" secondary @click="toggleManageLocalizations">{{ isManagingLocalizations ? $t("buttons.hideTranslations") : `${$t('buttons.showTranslations')} (${translatedLanguages.length})`}}</TrekioButton>
          <TrekioButton v-if="form.originalLanguage && languagesToBeTranslated.length" primary @click="$bvModal.show('create-language-translation-modal')">{{ $t("buttons.createTranslation") }}</TrekioButton>
        </div>
        <b-modal size="fit-content" body-class="p-0" class="modal" id="create-language-translation-modal" hide-footer hide-header>
          <div class="language-option" v-for="language in languagesToBeTranslated" :key="language.code" @click="createTranslation(language)">{{language.nameEnglish}}</div>
        </b-modal>

        <UnsavedChangesModal :isModalVisible="isUnsavedChangesModalVisible" @hideModal="isUnsavedChangesModalVisible = false" @discardChangesAndContinue="discardChangesAndContinue"/>
        <UnpublishOtherLocalizationsModal :isModalVisible="isUnpublishOtherLocalizationsModalVisible" @saveAndUnpublishOthers="saveAndUnpublishOthers" @save="unpublishLocalizationModalSaveAction()" />

        <TravelTipNavigation :travelTip="form" :isSidebarVisible="isSidebarVisible"/>
      </div>
      <b-alert :show="alert.dismissCountDown" fade id="alert"
              @dismiss-count-down="countDownChanged" class="alert-success">{{alert.notification}}
      </b-alert>
      <b-alert :show="alertDanger.dismissCountDown" fade id="alert-danger"
                @dismiss-count-down="countDownChangedDanger" class="alert-danger multi-line pre-formatted">
          {{ alertDanger.notification }}
          <div class="x dismiss-x" @click="() => {alertDanger.dismissCountDown = 0}"></div>
      </b-alert>
      <LocalizationSelects v-if="!isAuthor" style="margin-bottom: 30px;" :isManagingLocalizations="isManagingLocalizations" :originalLanguage="form.originalLanguage" :primaryLanguage="primaryLanguage" :editedLanguage="editedLanguage" :languages="languages"
          :localizations="form.localizations" :translatedLanguages="translatedLanguages" @setOriginalLanguage="setOriginalLanguage" @setPrimaryLanguage="setPrimaryLanguage" @showLocalization="showLocalization"
        />
        <TravelTipEquipmentList ref="travelTipEquipments" :primaryLanguage="primaryLanguage" :editedLanguage="editedLanguage" :preferredLanguage="preferredLanguage"
          :isManagingLocalizations="isManagingLocalizations" :travelTipId="travelTipId" :isCurrentUserTipAuthor="isCurrentUserTipAuthor" :state="state"
          @showAlert="showAlert" @showAlertDanger="showAlertDanger"
        />

      <a class="collapse-toggle" style="margin-top: -3px;" v-b-toggle.collapseBasicInfo>{{ $t("general.basicInfo") }}</a>
      
      <b-collapse id="collapseBasicInfo" visible style="padding-top: 30px">

        <TravelTipTags :isManagingLocalizations="isManagingLocalizations" :primaryLanguage="primaryLanguage" :tagIds="form.tagIds" @addOrRemoveTagId="addOrRemoveTagId"
          :isCurrentUserTipAuthor="isCurrentUserTipAuthor" :state="state"
        />

        <div v-if="!isManagingLocalizations && ['ADMIN', 'ADMIN_SUPERVISOR'].includes(userRole) && ['PUBLISHED', 'NOT_PUBLISHED'].includes(state)" class="d-flex justify-content-between" style="margin-bottom: 30px;">
          <div class="col-8" :class="{ 'd-none': isManagingLocalizations, 'd-flex': !isManagingLocalizations }">
            <TrekioSwitch v-model="cbPublished" :constantText="form.state === 'PUBLISHED' ? $t('admin.published') : $t('admin.notPublished')"/>
            <TrekioSwitch class="ml-5" v-model="form.purchasable" :constantText="form.purchasable ? $t('admin.forSale') : $t('admin.notForSale')"/>
          </div>

        </div>
        <div class="forms-container" :class="{'visible-localizations': isManagingLocalizations}">
          <form @submit.prevent="onSubmit" novalidate v-for="(localization, localizationIndex) in form.localizations" :key="localization.language">
            <div v-if="primaryLanguage === localization.language || isManagingLocalizations && editedLanguage === localization.language" class="row mx-0">
              <div class="col-8 px-0" :class="{'col-12': isManagingLocalizations}">
                <div class="form-content-container">
                    <TrekioSelect v-if="!isAuthor && !isManagingLocalizations" :label="$t('inputLabels.travelTipCampaign')" v-model="form.campaignId" :placeholder="$t('admin.notSelected')"
                      :text="selectCampaignsText"
                    >
                      <TrekioSelectOption :value="campaign.id" v-for="campaign in sortedCampaigns" :key="campaign.id">{{ campaign.localizations[0] && campaign.localizations[0].title }}</TrekioSelectOption>
                    </TrekioSelect>

                    <TrekioInput id="title" trim v-model="localization.title" :label="$t('inputLabels.travelTipTitle')" maxLength="100" :disabled="isManagingLocalizations && primaryLanguage == localization.language"
                      :error="error.title" placeholder=" " required :info="isInfoIconVisible(localization.language) ? $t('info.travelTipTitle') : null"
                    />
                    <TrekioInput id="shortInfo" trim v-model="localization.shortInfo" :label="$t('inputLabels.travelTipShortInfo')" maxLength="40" :disabled="isManagingLocalizations && primaryLanguage == localization.language"
                      :error="error.shortInfo" placeholder=" " required :info="isInfoIconVisible(localization.language) ? $t('info.travelTipShortInfo') : null" :hint="$t('inputHints.maxLength40')"
                    />
                    <TrekioTextarea id="perex" trim v-model="localization.perex" :label="$t('inputLabels.travelTipPerex')" required :info="isInfoIconVisible(localization.language) ? $t('info.travelTipPerex') : null"
                      :hint="$t('inputHints.maxLength160')" :error="error.perex" maxlength="160" :disabled="isManagingLocalizations && primaryLanguage == localization.language" :height="textareaHeights.perex"
                      @resizeTextarea="setTextareaHeight"
                    />
                    <TrekioTextarea id="description" trim v-model="localization.description" :label="$t('inputLabels.travelTipDescription')" required :info="isInfoIconVisible(localization.language) ? $t('info.travelTipDescription') : null"
                      :hint="$t('inputHints.maxLength1024')" :error="error.description" maxlength="1024" :disabled="isManagingLocalizations && primaryLanguage == localization.language" :height="textareaHeights.description"
                      @resizeTextarea="setTextareaHeight"
                    />
                    <TrekioTextarea id="practicalInfo" trim v-model="localization.practicalInfo" :label="$t('inputLabels.practicalInfo')" :info="isInfoIconVisible(localization.language) ? $t('info.travelTipPracticalInfo') : null"
                      maxlength="1024" :disabled="isManagingLocalizations && primaryLanguage == localization.language" :height="textareaHeights.practicalInfo"
                      @resizeTextarea="setTextareaHeight"
                      />
                    <TrekioTextarea v-if="!isManagingLocalizations" id="hashtags" trim v-model="form.hashtags" :label="$t('inputLabels.travelTipHashtags')" :info="isInfoIconVisible(localization.language) ? $t('info.travelTipHashtags') : null"
                      maxlength="160" :hint="$t('inputHints.maxLength160')" :disabled="isManagingLocalizations && primaryLanguage == localization.language" :height="textareaHeights.hashtags"                    
                      @resizeTextarea="setTextareaHeight"
                      />
    
                    <TrekioSelect v-if="!isManagingLocalizations" :label="$t('inputLabels.travelTipTheme')" v-model="form.theme" :placeholder="$t('admin.notSelected')" required :error="error.theme"
                      :info="isInfoIconVisible(localization.language) ? $t('info.travelTipTheme') : null" :text="form.theme ? themes.find(value => value.code === form.theme).name : $t('admin.notSelected')"
                    >
                      <TrekioSelectOption :value="theme.code" v-for="theme in themes" :key="theme.code">{{ theme.name }}</TrekioSelectOption>
                    </TrekioSelect>
                    
                    <div v-if="(isAdmin || isSupervisor) && (!isManagingLocalizations || localization.language != primaryLanguage)">
                      <Checkbox v-model="localization.translated" @change="translatedChanged({isTranslated: localization.translated, localizationIndexes: [localizationIndex]})">
                        {{ $t("admin.approveTravelTipTranslation") }} ({{ localization.language }})
                      </Checkbox >
                    </div>
    
                    <div v-if="!isManagingLocalizations">
                      <div class="img-container" :class="{'noborder': pictureFileName, error: error.titlePicture}">
                        <div v-if="isInfoIconVisible(localization.language)" class="info-icon" v-tooltip.right-start="$t('info.travelTipPicture')"></div>
                        <div v-if="pictureFileName" class="avatar-image">
                          <img alt="TravelTip Picture" @click="$bvModal.show('fullscreen-picture')" :src="imgUrl + pictureFileName.slice(0,pictureFileName.lastIndexOf('.')) + '-dimensions-640pxX480px' + pictureFileName.slice(pictureFileName.lastIndexOf('.'))" class="tr-points-img card-picture" style="position: absolute">
                          <b-modal size="fit-content" class="modal pl-0" id="fullscreen-picture" hide-footer hide-header>
                            <div @click="$bvModal.hide('fullscreen-picture')" class="x"></div>
                            <figure v-lazyload class="position-relative mb-0">
                              <div class="loading-circle position-absolute"><div></div><div></div><div></div><div></div></div>
                              <img class="picture" :data-url="imgUrl + pictureFileName.slice(0, pictureFileName.lastIndexOf('.')) + '-dimensions-1600pxX1200px' + pictureFileName.slice(pictureFileName.lastIndexOf('.'))">
                              <p class="fullscreen-picture-description">{{travelTipPicture && travelTipPicture.localizations[0].title}}</p>
                            </figure>
                          </b-modal>
                          <div v-if="!disableInputs" class="icon-with-tooltip-container">
                            <img class="choose-day-picture-icon" src="../../assets/svg/icon-choose-as-day-picture.svg" v-tooltip.top="$t('info.changeTravelTipPicture')" v-b-modal.modal-picture-select alt="">
                          </div>
                          <a v-if="userRole == 'ADMIN_SUPERVISOR'" :href="imgUrl + pictureFileName"><div class="img-download"></div></a>
                          <div class="x ml-auto" @click.stop="removeTitlePicture"></div>
                        </div>
                        <div v-else v-b-modal.modal-picture-select class="d-flex flex-column align-items-center justify-content-center" style="width: 100%; height: 100%">
                          <div class="plus mx-auto"></div>
                          <p class="mt-4 text-center">{{ $t("admin.addTravelTipPicture") }}</p>
                        </div>
                      </div>
                      <div class="input-alert mt-2">{{ $t("inputErrors.travelTipPictureRequired") }}</div>
                    </div>
                </div>
              </div>
    
              <div v-if="!isManagingLocalizations" class="col-4">
                <div class="sticky-top" style="margin-top: 40px">
                  <TripItem :trip="trip"/>
                </div>
              </div>
            </div>
          </form>
        </div>
      </b-collapse>

      <ConfirmModal :action="modalAction" @confirmed="onConfirmed" :passedObject="modalObjectPassed" :userRole="userRole" :state="state" :loadingModal="loading.modal" />
      
      <!-- MODAL WITH PICTURES -->
      <b-modal class="modal" id="modal-picture-select" hide-footer hide-header>
        <div class="modal-container py-5 d-flex flex-column justify-content-center mx-auto"  style="max-width: none">
            <b-tabs card :key="imageComponentKey">
              <b-tab :title="$t('admin.choosePicture')" active>
                <b-card-text>
                  
                  <!-- Temporary filter -->
                  <div class="filters-container text-left d-flex">
                    <TrekioInput id="pictures-search" whiteBg v-model="filter.searchString" :placeholder="$t('general.search')" searchInput/>

                    <TrekioSelect whiteBg :label="$t('inputLabels.countries')" v-model="filter.country" :searchInput="allCountriesInPictures.length > 10" :placeholder="$t('general.allFemAndLifeless')"
                      @searchInput="value => pictureCountriesSearchString = value" :searchString="pictureCountriesSearchString"
                      :text="filter.country ? filter.country[countryLocalization] : $t('general.allFemAndLifeless')"
                    >
                      <TrekioSelectOption :value="null">{{ $t("general.allFemAndLifeless") }}</TrekioSelectOption>
                      <TrekioSelectOption v-for="country in filteredCountriesInPictures" :key="country.id" :value="country">{{ country[countryLocalization] }}</TrekioSelectOption>
                    </TrekioSelect>

                    <TrekioSelect v-if="!isAuthor" whiteBg :label="$t('inputLabels.author')" v-model="filter.author" :searchInput="allCountriesInPictures.length > 10" :placeholder="$t('general.allFemAndLifeless')"
                      :text="filter.author ? filter.author : $t('general.allFemAndLifeless')"
                    >
                      <TrekioSelectOption :value="null">{{ $t("general.allFemAndLifeless") }}</TrekioSelectOption>
                      <TrekioSelectOption v-for="author in authorsList" :key="author.id" :value="author.userName">{{ author.userName }}</TrekioSelectOption>
                    </TrekioSelect>
                  </div>
                  <!--  -->
                  <div v-if="!filteredPictures || filteredPictures.length < 1">{{ $t("filter.noResults") }}</div>
                  <PictureList :pictures="filteredPictures" :travelTipPictures="true" @onPictureClick="onPictureClick"/>
                </b-card-text>
              </b-tab>
              <b-tab :title="$t('admin.addNewPicture')">
                <b-card-text>
                  <div class="add-new-picture-container" :key="imageComponentKey">
                    <AddPhotos @weHaveNewFoto="newPictureAdded" :whiteInputs="true" :isTravelTipPicture="true"></AddPhotos>
                  </div>
                </b-card-text>
              </b-tab>
              <b-tab :title="$t('admin.picturesTabChoosePictureFromTravelTip')">
                <p v-if="allPicturesInTravelTip.length < 1">{{ $t("admin.noPictureInTravelTip") }}</p>
                <PictureList v-else :pictures="allPicturesInTravelTip" :travelTipPictures="true" @onPictureClick="onPictureClick"/>
              </b-tab>
            </b-tabs>
        </div>
      </b-modal>

      <p v-if="form.originalLanguage && !travelTipId">{{ $t("admin.saveTravelTipBeforeAddingDays") }}</p>
      <div v-if="form.originalLanguage" :class="{disabledDays: !travelTipId}">
        <a class="collapse-toggle days-tab" style="margin-top: -3px;" v-b-toggle.collapseDays>{{ $t("general.daysCapitalized") }}<span>({{sortedDays && sortedDays.length}})</span></a>

        <b-collapse id="collapseDays" visible>
          <div :class="{'hide-buttons': isAdmin && state == 'TO_CONTROL'}">
            <div class="row">
              <div v-for="(itineraryDay, index) in sortedDays" :key='itineraryDay.id' class="col-12">
                <ItineraryDay ref='itineraryDay'
                  @alert="showAlert" @alertDanger="showAlertDanger" :disableInputs="disableInputs" @checkTravelTipPicture="checkTravelTipPicture" @updateAllPicturesInTravelTip="updateAllPicturesInTravelTip"
                  :itineraryDay='itineraryDay' :previousDay="sortedDays[index - 1]" :allDays="sortedDays" :travelTipId='travelTipId' :travelTipTitle='travelTip.title' :pictures="pictures"
                  :isManagingLocalizations="isManagingLocalizations" :editedLanguage="editedLanguage" :primaryLanguage="primaryLanguage" :localizationCodes="form.localizations.map(loc => loc.language)"
                  :itineraryItems="itineraryDay.itineraryItems" @translatedChanged="translatedChanged" 
                  />
              </div>
            </div>
            <div v-if="(!isAuthor || ['IN_PROGRESS', 'TO_REWORK'].includes(state)) && !isManagingLocalizations">
              <button type="button" class="add-itinerary-day" @click="createNewDay"><div class="calendar-icon"></div>{{ isCreatingDay ? $t("loading.dayCreate") : $t("buttons.addNewDay") }}</button>
            </div>
          </div>
        </b-collapse>
      </div>
      <TravelTipComments ref="travelTipComments" v-if="!isManagingLocalizations && (travelTipId && travelTip && travelTip.state != 'IN_PROGRESS')" :travelTipId="travelTipId" :travelTipAuthor="travelTip.author" @alert="showAlert" @alertDanger="showAlertDanger"/>
      <div class="fixed-buttons" :class="{'full-width': !isSidebarVisible}">
        
        <div v-if="isManagingLocalizations">
          <TrekioButton type="submit" :isLoading="loading.save != ''" :loadingText="loading.save" primary @click="saveAllForms(false, 'save', $t('loading.save'))">{{ $t("buttons.saveTranslations") }}</TrekioButton>
        </div>
        <div v-else-if="!$route.params.travelTipId">
          <TrekioButton primary type="submit" :isLoading="loading.save != ''" :loadingText="loading.save" @click="onSubmit(false)">{{ $t("buttons.save")}}</TrekioButton>
        </div>

        <div v-else-if="isSupervisor && state == 'DECLINED'">
          <TrekioButton primary @click="showModal('onDeleteConfirmed(true)')">{{ $t("buttons.delete") }}</TrekioButton>
        </div>

        <div v-else-if="isAlreadyAprroved">
          <TrekioButton v-if="isSupervisor" secondary @click="showModal('onDeleteConfirmed(true)')">{{ $t("buttons.delete") }}</TrekioButton>
          
          <TrekioButton secondary @click="showModal('onDeleteConfirmed(false)')">{{ $t("buttons.decline") }}</TrekioButton>
          <TrekioButton primary :isLoading="loading.save != ''" :loadingText="loading.save" @click="saveAllForms(true, 'save', $t('loading.save'))">{{ $t("buttons.save")}}</TrekioButton>
        </div>

        <div v-else-if="travelTip">
          <TrekioButton v-if="!isAuthor && ['IN_PROGRESS', 'TO_REWORK'].includes(state)" type="button" secondary @click="showModal('onDeleteConfirmed(true)')">{{ $t("buttons.delete") }}</TrekioButton>
          <TrekioButton v-else type="button" secondary @click="showModal('onDeleteConfirmed(false)')">{{$t("buttons.decline")}}</TrekioButton>

          <TrekioButton v-if="shouldShowToReworkButton" primary @click="showModal('toRework')">{{ $t("buttons.returnToRework") }}</TrekioButton>
          <TrekioButton v-else primary :isLoading="loading.save != ''" :loadingText="loading.save" @click="saveAllForms(false, 'save', $t('loading.save'))">{{ $t("buttons.saveAsConcept") }}</TrekioButton>

          <TrekioButton primary @click="showModal('saveAllForms(true)')">{{isSupervisor ? $t("buttons.approve") : isAdmin && ['IN_PROGRESS', 'TO_REWORK', 'TO_CONTROL'].includes(state) ? $t("buttons.sendToApprove") : $t("buttons.sendToControl")}}</TrekioButton>
        </div>
      </div>
      <button v-if="isAdmin || isSupervisor"  class="floating-button preview-translation-icon" v-tooltip.left="'Zobrazit schválení překladů'" @click="showTripLocalizationApprovals"></button>
      <router-link class="floating-button preview-trip-icon" v-tooltip.top-start="$t('info.travelTipPreview')" :to="{ name: 'adminTravelTipPreview', params: {travelTipId: travelTipId}}" target="_blank" ></router-link>
      <ModalDifficultyInfo />
      <b-modal body-class="p-4" class="modal" id="translation-approvals" hide-footer hide-header>
        <div class="d-flex" style="gap: 30px">
          <div class="localization-approval-group" v-for="(localizationApprovalEntry, index) in localizationApprovalsByLanguage" :key="localizationApprovalEntry.language">
            <h3>{{ localizationApprovalEntry.language }} <span>{{ $t('admin.published') }}: <b>{{ form.localizations[index].published ? $t('general.yes') : $t('general.no') }}</b></span></h3>
            <div @click="scrollToLocalizationElement(item, localizationApprovalEntry)" class="title-with-checkbox" v-for="item in localizationApprovalEntry.items" :key="item.key">
              <p class="mb-0" :class="{'ml-3': item.type === 'itineraryDay', 'ml-5': item.type === 'itineraryItem'}">
              {{ (item.type === 'itineraryDay' ? $t("general.dayCapitalized") + " - " : item.type === 'itineraryItem' ? $t("general.itineraryItem") + " - " + item.title : $t('general.basicInfo')) }}
              </p>
              <Checkbox style="pointer-events: none;" :value="item.isTranslated"/>
  
            </div>
          </div>
        </div>
      </b-modal>
      
    </div>
  </div>
</template>

<script>
import ItineraryDay from "@/components/admin/ItineraryDay";
import TravelTipComments from "@/components/admin/TravelTipComments";
import AddPhotos from "./ManagePicturePage";
import {CAMPAIGN_TITLES_ADMIN} from "@/api/graphql/query/CampaignQuery";
import {PICTURES} from "@/api/graphql/query/PictureQuery";
import {TRAVEL_TIP_BY_ID_ADMIN} from "@/api/graphql/query/TravelTipQuery";
import TravelTipService from "@/services/TravelTipService";
import ItineraryDayService from "@/services/ItineraryDayService";
import ItineraryService from "@/services/ItineraryService";
import {CLEVERTRIP_IMG_URL, CLEVERTRIP_API_URL}  from '@/definitions';
import LazyLoadDirective from "@/components/LazyLoadDirective.js";
import TripItem from "@/components/redesign/TripItem";
import ConfirmModal from "@/components/admin/ConfirmModal";
import PictureList from "../../components/admin/PictureList.vue";
import {travelTipInfo } from "../../components/InfoIconTexts.js";
import ModalDifficultyInfo from "../../components/ModalDifficultyInfo.vue";
import { assignLocalizationProperties } from "../../utils/LocalizationDataConverter";
import UnsavedChangesModal from "../../components/admin/UnsavedChangesModal.vue";
import UnpublishOtherLocalizationsModal from '@/components/admin/UnpublishOtherLocalizationsModal'
import LocalizationSelects from '@/components/admin/LocalizationSelects'
import Checkbox from "../../components/Checkbox.vue";
import TravelTipEquipmentList from "../../components/admin/TravelTipEquipmentList.vue";
import sharedUtils from "../../utils/sharedUtils";
import TravelTipNavigation from "../../components/admin/TravelTipNavigation.vue";
import TravelTipTags from "../../components/admin/TravelTipTags.vue";

export default {
  directives: {
        lazyload: LazyLoadDirective
  },
  name: "ManageTravelTipDlg",
  props: ['isSidebarVisible'],
  components: {
    ItineraryDay,
    AddPhotos,
    TravelTipComments,
    TripItem,
    ConfirmModal,
    PictureList,
    ModalDifficultyInfo,
    UnsavedChangesModal,
    UnpublishOtherLocalizationsModal,
    LocalizationSelects,
    Checkbox,
    TravelTipEquipmentList,
    TravelTipNavigation,
    TravelTipTags
  },

  data: function() {
    return {
      isCollapsingAnimated: true,
      textareaHeights: {
        perex: '100px',
        description: '100px',
        practicalInfo: '100px',
        hashtags: '100px'
      },
      pageLoadingTitle: this.$t("loading.loading"),
      initialLoading: true,
      infoTexts: travelTipInfo,
      filter: {
        searchString: '',
        country: {},
        authorId: '',
      },
      pictureCountriesSearchString: '',
      modalAction: null,
      modalObjectPassed: null,
      leaveOnCreateOrDelete: false,
      loading: {
        save: '',
        control: '',
        modal: '',
      },
      imgUrl: CLEVERTRIP_IMG_URL,
      avatarImgUrl: CLEVERTRIP_API_URL + "/avatar/downloadFile/",
      isImageLoading: false,
      imgWidth: null,
      imgHeight: null,
      travelTipId: null,
      campaigns: null,
      form: {
        campaignId: null,
        theme: null,
        titlePicture: null,
        hashtags: '#',
        purchasable: true,
        state: null,
        author: '',
        tagIds: [],
        originalLanguage: this.$store.state.currentUserRole === 'ADMIN_SUPERVISOR' ? null : this.$store.state.preferredLanguage,
        localizations: [],
      },
      error: {
        title: '',
        shortInfo: '',
        perex: '',
        description: '',
        theme: '',
        titlePicture: '',
      },
      localizationApprovalsByLanguage: [],
      primaryLanguage: this.$store.state.preferredLanguage,
      isManagingLocalizations: false,
      editedLanguage: null,
      isUnsavedChangesModalVisible: false,
      isUnpublishOtherLocalizationsModalVisible: false,
      modalContinueAction: null,
      unpublishLocalizationModalSaveAction: null,
      travelTipPicture: null,
      allPicturesInTravelTip: [],
      lastSavedForm: {},
      cbPublished: null,
      stateChangeFailed: false,
      pictureFileName: '',
      newItineraryDay: {
        id: null,
        dayNumber: null,
        itineraryItems: null,
        localizations: []
      },
      isCreatingDay: false,

      alert: {
        dismissSecs: 6,
        dismissCountDown: 0,
        showDismissibleAlert: false,
        notification: ''
      },
      alertDanger: {
        dismissSecs: 999,
        dismissCountDown: 0,
        showDismissibleAlert: false,
        notification: ''
      },

      cities: [],
      countries: [],
      imageComponentKey: 0,
      disableInputs: false,
      errorsInForms: 0,
      userRole: null,
      currentUser: null,
      state: null,
      isCurrentUserTipAuthor: null,
    }
  },

  beforeRouteLeave (to, from , next) {
    if (this.leaveOnCreateOrDelete == true || !this.changeInForm()) {
      next()
      return
    }
    const answer = window.confirm(this.$t("admin.unsavedChangesWarning"))
    if (answer) next()
    else next(false)
  },

  computed: {
    countryLocalization() {
      return this.preferredLanguage == "en" ? "nameEnglish" : "nameCzech"
    },
    themes() {
      return [
        {code: 'FOOD', name: this.$t("general.food")},
        {code: 'TOURISM', name: this.$t("general.nature")},
        {code: 'SPORT', name: this.$t("general.adventure")},
        {code: 'CULTURE', name: this.$t("general.culture")}
      ]
    },
    preferredLanguage() {
      return this.$store.state.preferredLanguage
    },
    languages() {
      return this.$store.state.languages;
    },
    translatedLanguages() {
      return this.form.localizations.map(localization => localization.language).filter(lang => {
        return lang != this.primaryLanguage
      })
    },
    languagesToBeTranslated() {
      const usedLanguageCodes = this.form.localizations.map(localization => localization.language);
      return this.languages.filter(language => !usedLanguageCodes.includes(language.code));
    },
    originalLanguage() {
      return this.form.originalLanguage ? this.form.originalLanguage : 'cs'
    },
    originalLanguageLocalization() {
      if (!this.form.localizations) return
      return this.form.localizations.find(localization => localization.language == this.originalLanguage)
    },
    isButtonsVisible() {
      return !((this.userRole == 'AUTHOR' && !['IN_PROGRESS', 'TO_REWORK'].includes(this.state)) || (this.userRole == 'ADMIN' && (['TO_APPROVE','DECLINED'].includes(this.state) || (!this.isCurrentUserTipAuthor && ['IN_PROGRESS', 'TO_REWORK'].includes(this.state))))) || !this.$route.params.travelTipId
    },
    shouldShowToReworkButton() {
      return !this.isAuthor && this.state == 'TO_CONTROL' || this.userRole == 'ADMIN_SUPERVISOR' && this.state == 'TO_APPROVE'
    },
    isAlreadyAprroved() {
      return ['ADMIN', 'ADMIN_SUPERVISOR'].includes(this.userRole) && ['PUBLISHED', 'NOT_PUBLISHED'].includes(this.state)
    },
    isAuthor() {
      return this.userRole === "AUTHOR"
    },
    isAdmin() {
      return this.userRole === "ADMIN"
    },
    isSupervisor() {
      return this.userRole === "ADMIN_SUPERVISOR"
    },
    filteredCountriesInPictures() {
      if (!this.allCountriesInPictures) return []
      return this.allCountriesInPictures.filter(pic => {
        return pic[this.countryLocalization].toLowerCase().includes(this.pictureCountriesSearchString.toLowerCase())
      })
    },
    allCountriesInPictures() {
      if (!this.pictures) return []
      let allCountries = [];
          this.pictures.forEach(pic => {
              if (!allCountries.some(value => value.code === pic.country.code)) {
                  allCountries.push(pic.country);
              }
          });
          return allCountries.sort((a, b) => {
            return sharedUtils.removeAccents(a[this.countryLocalization]).localeCompare(sharedUtils.removeAccents(b[this.countryLocalization]), this.$store.state.preferredLanguage)
          });
    },
    authorsList() {
      if (!this.pictures) return
      let authors = [];
      this.pictures.forEach((picture) => {
        if (!authors.some(author => author.userName == picture.author.userName)) {
          authors.push(picture.author)
        }
      });
      return authors;
    },
    filteredPictures() {
      let filter = {...this.filter}
      if (!this.pictures) return []
      return this.pictures.filter(picture => {
        if (filter.searchString && !picture.title.toLowerCase().includes(filter.searchString.toLowerCase())) return false
        if (filter.country?.code && picture.country.code != filter.country.code) return false
        if (filter.author && picture.author.userName != filter.author) return false
        return true
      })
    },
    trip() {
      return {...this.form, adminView: true, titlePicture: {fileName: this.pictureFileName}, itineraryDays: this.sortedDays}
    },
    sortedCampaigns() {
      if (!this.campaigns) return
      const sortedCampaigns = this.campaigns.map(campaign => {
        return {
          ...campaign,
          localizations: this.getCorrectCampaignLocalization(campaign.localizations)
        }
      })
      return sortedCampaigns.sort((a, b) => {
        return sharedUtils.removeAccents(a.localizations[0]?.title).localeCompare(sharedUtils.removeAccents(b.localizations[0]?.title), this.$store.state.preferredLanguage)
      })
    },
    selectCampaignsText() {
      return this.form.campaignId && this.campaigns ? this.getCorrectCampaignLocalization(this.campaigns.find(val => val.id == this.form.campaignId).localizations)[0].title : this.$t('admin.notSelected')
    },
    getAllCountriesInTravelTip() {
      if (!this.travelTip) return
      let countriesInTravelTip = new Set()

      this.travelTip.itineraryDays.forEach(itineraryDay => {
        itineraryDay.itineraryItems.forEach(itineraryItem => {
          itineraryItem.countries.forEach(country => {
            countriesInTravelTip.add(country[this.countryLocalization])
          })
        })
      })

      return countriesInTravelTip
    },

    sortedDays() {
      if (typeof this.form.itineraryDays === "function" || this.form.itineraryDays == null)
        return [];
      return this.form.itineraryDays.slice().sort((a, b) => {return a.dayNumber - b.dayNumber})
    }
  },

  methods: {
    scrollToLocalizationElement(item, localizationApprovalEntry) {
      if (localizationApprovalEntry.language != this.form.localizations[0].language) {
        if (this.isChangeInForms(() => this.scrollToLocalizationElement(item, localizationApprovalEntry))) return
        if (!this.isManagingLocalizations) {
          this.isManagingLocalizations = true
        }
        this.showLocalization(localizationApprovalEntry.language)
      } else if (this.isManagingLocalizations) {
        if (this.isChangeInForms(() => this.scrollToLocalizationElement(item, localizationApprovalEntry))) return
        this.toggleManageLocalizations()
      }
      this.isCollapsingAnimated = false
      let scrollToElementId = null
      if (item.type === "travelTip") {
        const collapseBasicInfoElement = document.querySelector('#collapseBasicInfo')
        if (!collapseBasicInfoElement.classList.contains('show')) {this.$root.$emit('bv::toggle::collapse', 'collapseBasicInfo')}
        scrollToElementId = 'collapseBasicInfo'

      } else if (item.type === "itineraryDay") {
        const collapseDayElement = document.querySelector('#collapseDayInfo' + item.id)
        if (!collapseDayElement.classList.contains('show')) {this.$root.$emit('bv::toggle::collapse', 'collapseDayInfo' + item.id)}
        scrollToElementId = 'day-collapse-' + item.id


      } else if (item.type === "itineraryItem") {
        const dayId = item.itineraryDayId
        const collapseDayElement = document.querySelector('#collapseDayInfo' + dayId)
        const collapseItemsElement = document.querySelector('#collapseItemsList' + dayId)
        this.$nextTick(() => {
          if (!collapseDayElement.classList.contains('show')) {this.$root.$emit('bv::toggle::collapse', 'collapseDayInfo' + dayId)}
          if (!collapseItemsElement.classList.contains('show')) {this.$root.$emit('bv::toggle::collapse', 'collapseItemsList' + dayId)}
        })
        const dayRef = this.$refs.itineraryDay.find(day => day.form.id == dayId)
        dayRef?.setActiveItemForm(item)
        scrollToElementId = 'itineraryItem' + item.id
      }

      setTimeout(() => {
        document.getElementById(scrollToElementId)?.scrollIntoView({behavior: 'smooth'})
        this.isCollapsingAnimated = true
      }, 50);
      this.$bvModal.hide('translation-approvals')
    },
    getCorrectCampaignLocalization(localizations) {
      let selectedLocalization = null
      let highestPriority = -1

      for (const localization of localizations) {
        if (localization.language === this.primaryLanguage && localization.title != '') {
          selectedLocalization = localization
          break
        } else if (localization.language === this.preferredLanguage && localization.title != '' && highestPriority < 2) {
          selectedLocalization = localization;
          highestPriority = 2
        } else if (localization.language === this.form.originalLanguage && localization.title != '' && highestPriority < 1) {
          selectedLocalization = localization;
          highestPriority = 1
        } else if (!selectedLocalization) {
          selectedLocalization = localizations[0]
        }
      }
      if (!selectedLocalization?.title) {
        selectedLocalization = localizations.find(loc => loc.title != '')
      }
      return [selectedLocalization]
    },
    setTextareaHeight({id, height}) {
      this.textareaHeights[id] = height + "px"
    },
    translatedChanged({isTranslated, localizationIndexes}) {
      for (const localizationIndex of localizationIndexes) {
        if (!isTranslated) {
          this.form.localizations[localizationIndex].published = false
          continue
        }
        const isTravelTipTranslated = this.form.localizations[localizationIndex].translated
        if (!isTravelTipTranslated) {
          this.form.localizations[localizationIndex].published = false
          continue
        }
        // check if every itineraryDay in travelTip is translated
        const dayRefs = this.$refs.itineraryDay

        let isEverythingTranslated = true
        if (dayRefs) {
          for (const dayRef of dayRefs) {
            if (!dayRef.form.localizations[localizationIndex].translated) {
              isEverythingTranslated = false
              continue
            }
            
            if (dayRef.$refs.itineraryItem) {
              for (const itemRef of dayRef.$refs.itineraryItem) {
                if (itemRef.form.itineraryItemType != "EMPTY" && !itemRef.form.localizations[localizationIndex].translated) {
                  isEverythingTranslated = false
                  continue
                }
                
                if (!isEverythingTranslated) continue
              }
            }
          }
        }
        if (!isEverythingTranslated) {
          this.form.localizations[localizationIndex].published = false
          continue
        }
        this.form.localizations[localizationIndex].published = true
      }
    },
    createCopyOfForm(form) {
      return {
        ...form,
        localizations: form.localizations.map(localization => ({...localization}))
      }
    },
    discardChangesAndContinue() {
      this.form = this.createCopyOfForm(this.lastSavedForm)
      this.$root.$emit('discardChanges')
      this.sortLocalizations()
      this.isUnsavedChangesModalVisible = false
      this.removeErrors()
      this.modalContinueAction()
    },

    isInfoIconVisible(language) {
      // Show info icons only for the main form on the left
      return this.form.localizations[0].language === language
    },

    createLocalization(languageCode) {
      this.form.localizations.push({
        language: languageCode,
        title: '',
        description: '',
        shortInfo: '',
        perex: '',
        practicalInfo: '',
        published: false,
        translated: false
      })
      this.$root.$emit('createLocalization', languageCode)
    },
    setOriginalLanguage(languageCode) {
      this.form.originalLanguage = languageCode
      this.primaryLanguage = languageCode
      this.createLocalization(languageCode)
    },
    setPrimaryLanguage(languageCode) {
      if (this.primaryLanguage == languageCode) return
      if (this.isChangeInForms(() => this.setPrimaryLanguage(languageCode))) return
      this.primaryLanguage = languageCode
      if (this.editedLanguage == languageCode) {
        this.editedLanguage = null
      }
      this.sortLocalizations()
    },
    findLanguage(languageCode) {
      return this.languages.find(lang => lang.code == languageCode)
    },
    createTranslation(language) {
      if (this.isChangeInForms(() => this.createTranslation(language))) return
      this.createLocalization(language.code)
      this.showLocalization(language.code, true)
      this.isManagingLocalizations = true
      this.$bvModal.hide('create-language-translation-modal')
    },
    showLocalization(languageCode, isCreated = false) {
      if (this.editedLanguage == languageCode) return
      if (!isCreated) {
        if (this.isChangeInForms(() => this.showLocalization(languageCode, isCreated))) return
      }
      this.removeErrors()
      this.editedLanguage = languageCode
      this.sortLocalizations()
    },
    sortLocalizations(){
      sharedUtils.sortLocalizations(this.form.localizations, this.primaryLanguage, this.editedLanguage)
      if (this.lastSavedForm) {
        sharedUtils.sortLocalizations(this.lastSavedForm.localizations, this.primaryLanguage, this.editedLanguage)
      }
      if (this.travelTipPicture) {
        sharedUtils.sortLocalizations(this.travelTipPicture.localizations, this.primaryLanguage, this.editedLanguage)
      }
    },
    toggleManageLocalizations() {
      if (this.isChangeInForms(this.toggleManageLocalizations)) return
      this.isManagingLocalizations = !this.isManagingLocalizations
    },
    addOrRemoveTagId(tagId) {
      if (this.form.tagIds.includes(tagId)) {
        this.form.tagIds = this.form.tagIds.filter(formTagId => formTagId != tagId)
      } else {
        this.form.tagIds.push(tagId)
      }
    },
    checkTravelTipPicture(pictures) {
      const pictureIds = pictures.map( pic => pic.id)
      if (this.travelTipPicture && pictureIds.includes(this.travelTipPicture.id)) {
        this.removeTitlePicture()
      }
      this.allPicturesInTravelTip = this.allPicturesInTravelTip.filter( picture => !pictureIds.includes(picture.id))
    },
    updateAllPicturesInTravelTip(pictures = null, pictureToAdd = null, pictureToRemove = null) {
      if (pictureToAdd != null) {
        if (!this.allPicturesInTravelTip.some(pic => pic.id == pictureToAdd.id)) {
          this.allPicturesInTravelTip.push(pictureToAdd)
        }
      }
      else if (pictureToRemove != null) {
        this.allPicturesInTravelTip = this.allPicturesInTravelTip.filter(picture => picture.id != pictureToRemove.id)
        if (this.travelTipPicture && this.travelTipPicture.id == pictureToRemove.id) {
          this.removeTitlePicture()
        }
      }
      else {
        pictures?.forEach( picture => {
          if (!this.allPicturesInTravelTip.some(pic => pic.id == picture.id)) {
            this.allPicturesInTravelTip.push(picture)
          }
        })
      }
    },
    async createNewDay() {
      this.isCreatingDay = true
      this.newItineraryDay.dayNumber = this.sortedDays.length > 0 ? this.sortedDays[this.sortedDays.length - 1].dayNumber + 1 : 1
      this.newItineraryDay.localizations = this.form.localizations.map(localization => {
        return {
          language: localization.language,
          title: '',
          description: ''
        }
      })

      await ItineraryDayService.createItineraryDay(this.travelTip.id, this.newItineraryDay)
        .then((result) => {
          this.showAlert(this.$t('alerts.dayCreated'))
          const createdItineraryDay = result.data.createItineraryDay
          this.form.localizations.forEach(loc => {
            loc.published = false
          })
          this.form.itineraryDays.push({...createdItineraryDay, itineraryItems: []})
          this.isCreatingDay = false
          setTimeout(() => {
            this.$root.$emit('bv::toggle::collapse', 'collapseDayInfo' + result.data.createItineraryDay.id)
          }, 50)
        })
        .catch(err => {
          console.log(err)
          this.isCreatingDay = false
          this.showAlertDanger(this.$t("alerts.dayCreateError"))
        });
    },
    
    isChangeInForms(callback) {
      let isChangeInForms = false
      if (JSON.stringify(this.lastSavedForm) !== JSON.stringify(this.form)) isChangeInForms = true
      else {
        let refsArray = this.$refs.itineraryDay
        if (refsArray) {
          refsArray.forEach(day => {
            if (day.changeInForm() == true) {
              isChangeInForms = true
            }
          })
        }
      }
      if (isChangeInForms) {
        this.modalContinueAction = callback
        this.isUnsavedChangesModalVisible = true
        return true
      }
      
      return false  
    },

    changeInForm() {
      if (JSON.stringify(this.lastSavedForm) !== JSON.stringify(this.form)) return true
      else {
        let refsArray = this.$refs.itineraryDay
        if (refsArray) {
          let changeInForm = false
          refsArray.forEach(day => {
            if (day.changeInForm() == true) {changeInForm = true}
          })
         return changeInForm
        }
      }
      return false
    },
    async onConfirmed() {
      if (this.modalAction == 'onDeleteConfirmed(true)') {await this.onDeleteConfirmed(true)}
      else if (this.modalAction == 'onDeleteConfirmed(false)') {await this.onDeleteConfirmed(false)}
      else if (this.modalAction == 'declineTravelTip') {await this.declineTravelTip()}
      else if (this.modalAction == 'saveAllForms(true)') {await this.saveAllForms(true, 'modal', this.$t("loading.formsValidation"))}
      else if (this.modalAction == 'toRework') {await this.toRework()}
      this.hideModal()
    },
    hideModal() {
      this.$bvModal.hide('modal-travelTip')
      this.modalAction = null
      this.modalObjectPassed = null
    },
    showModal(action) {
      this.modalObjectPassed = this.travelTip
      this.modalAction = action
      this.$bvModal.show('modal-travelTip')
    },
    removeAllErrors() {
      this.$store.commit('resetErrorIds')
      this.removeErrors()

      this.errorsInForms = 0
      let refsArray = this.$refs.itineraryDay
      if (refsArray) {
        refsArray.forEach(day => {
          day.removeErrors()
        })
      }
    },
    manageUserRolesAndTipState() {
      this.userRole = this.$store.state.currentUserRole
      this.currentUser = this.$store.state.currentUser
      if (this.travelTip) {
        this.state = this.travelTip.state
        this.isCurrentUserTipAuthor = this.$store.state.currentUserId == this.travelTip.author.id
      }
    },
    async declineTravelTip() {
      await TravelTipService.declineTravelTip(this.travelTip.id)
        .then(() => {
          this.showAlert(this.$t('alerts.travelTipDeclined'))
          this.resetToDefault()
        })
        .catch((err) => console.log(err))
    },
    async toRework() {
      await this.saveAllForms(false)
      this.loading.modal = this.$t("loading.travelTipToRework")
      await TravelTipService.toReworkTravelTip(this.travelTip.id)
        .then(() => {
          this.showAlert(this.$t('alerts.travelTipSentToRework', [this.travelTip.author.userName]))
          this.resetToDefault()
        })
        .catch((err) => {
          console.log(err)
          this.showAlertDanger(this.$t("alerts.travelTipToReworkError"))
        })
    },

    addErrorsInForm(numberOfErrors) {
      this.errorsInForms = this.errorsInForms + numberOfErrors
    },

    async validateAllForms() {
      this.validateInputs()
      let refsArray = this.$refs.itineraryDay
      if (refsArray) {
        for (const day of refsArray) {
          await day.validateInputs() 
        }
      }
    },

    async saveAllForms(validateForms, loadingType, loadingMessage, isModalAction = false) {
      if (!isModalAction && this.isChangeInLocalizationInputs(() => {this.saveAllForms(validateForms, loadingType, loadingMessage, true)})) return
      if (this.isUnpublishOtherLocalizationsModalVisible) this.isUnpublishOtherLocalizationsModalVisible = false

      // Ensure localization is published before proceeding with saving forms and approving the travelTip (hideTravelTip).
      if (validateForms && this.userRole === "ADMIN_SUPERVISOR") {
        this.publishLocalization(0)
      }

      if (!this.isManagingLocalizations) this.removeAllErrors()

      if (loadingType) this.loading[loadingType] = loadingMessage
      await this.onSubmit(validateForms) 
      
      let refsArray = this.$refs.itineraryDay
      if (!refsArray && !validateForms) return this.showAlert(this.$t('alerts.travelTipUpdated'))
      if (refsArray) {
        for (const ref of refsArray) {
          await ref.onSubmit(validateForms)
        }
      }

      if (validateForms) {
        let doesTheDayHaveItems = true
        if (!refsArray || refsArray.length < 1) doesTheDayHaveItems = false
        else {
          refsArray.forEach(day => {
            if (!day.doesTheDayHaveItems()) doesTheDayHaveItems = false
          })
        }
        await this.validateAllForms()

        // There is no error in forms & the travelTip has at least one day with one item
        if (this.errorsInForms < 1 && doesTheDayHaveItems) {
          if (['ADMIN','ADMIN_SUPERVISOR'].includes(this.userRole) && ['PUBLISHED', 'NOT_PUBLISHED'].includes(this.state)) {
            this.showAlert(this.$t('alerts.travelTipChangesUpdated'))
          }
          else if (this.userRole == 'ADMIN_SUPERVISOR') {
            this.loading[loadingType] = loadingMessage
            await TravelTipService.hideTravelTip(this.travelTip.id).then(() => {
              this.showAlert(this.$t('alerts.travelTipChangesUpdated'))
            })
          }
          else if (this.userRole == "ADMIN") TravelTipService.toApproveTravelTip(this.travelTip.id).then(() => this.showAlert(this.$t('alerts.travelTipUpdatedAndSentToApprove')))
          else TravelTipService.toControlTravelTip(this.travelTip.id).then(() => this.showAlert(this.$t('alerts.travelTipUpdatedAndSentToControl')))
        }
        // The travelTip does NOT have at least one day with one item
        else if (!doesTheDayHaveItems) return this.showAlertDanger(this.errorsInForms > 0 ? this.$t('alerts.travelTipNoDaysOrItemsError') + " " + this.$t('alerts.errorsInForms', [this.errorsInForms]) : this.$t('alerts.travelTipNoDaysOrItemsError'))
        else {
          const collapseDayElement = document.querySelector('#collapseDays')
          if (!collapseDayElement.classList.contains('show')) {this.$root.$emit('bv::toggle::collapse', 'collapseDays')}
          this.showAlertDanger(this.$tc("alerts.foundErrorsInForm", this.errorsInForms))
        }
      }
      else {
        this.removeAllErrors()
        this.showAlert(this.$t('alerts.travelTipUpdated'))
      }
    },
    checkState() {
      if ((this.userRole == "AUTHOR" && !['IN_PROGRESS', 'TO_REWORK'].includes(this.state)) || (this.userRole == "ADMIN" && (['TO_APPROVE','DECLINED'].includes(this.state) || (!this.isCurrentUserTipAuthor && ['IN_PROGRESS', 'TO_REWORK'].includes(this.state))))) {
        this.disableInputs = true
        const adminTravelTipEl = document.querySelector(".admin-travel-tip")
        adminTravelTipEl.classList.add ("no-interaction")
        const allInputContainers = document.querySelectorAll(".input-container, .select-container")
        const allInputs = document.querySelectorAll("input, textarea:not(.comment-area), button.dropdown-toggle")
        allInputs.forEach(input => {
          input.setAttribute("disabled", true)
        })
        allInputContainers.forEach(input => {
          input.classList.add("disabled")
        })
      }
    },

    async newPictureAdded(newPictures){
      newPictures?.forEach(picture => {
        this.onPictureClick(picture)
      })
      await this.$apollo.queries.pictures.refetch();
      this.imageComponentKey += 1;
    },

    onPictureClick(picture) {
      const pictureLocalizations = [...picture.localizations]
      sharedUtils.sortLocalizations(pictureLocalizations, this.primaryLanguage, this.editedLanguage)
      this.travelTipPicture = {...picture, localizations: pictureLocalizations}
      this.form.titlePicture = picture.id
      this.pictureFileName = picture.fileName
      this.$bvModal.hide('modal-picture-select')
    },

    removeTitlePicture() {
      this.pictureFileName = ''
      this.form.titlePicture = null
    },
    validateLocalizationInputs() {
      const localizations = this.isManagingLocalizations ? this.form.localizations[1] : this.form.localizations[0]
      if (localizations) {
          let errorCount = 0
          const { title, description, shortInfo, perex, practicalInfo } = localizations

          if (!title) {
            errorCount += 1
            this.error.title = this.$t("inputErrors.required")
          }
          if (!description) {
            errorCount += 1
            this.error.description = this.$t("inputErrors.required")
          }
          if (!shortInfo) {
            errorCount += 1
            this.error.shortInfo = this.$t("inputErrors.required")
          }
          if (!perex) {
            errorCount += 1
            this.error.perex = this.$t("inputErrors.required")
          }
          if (errorCount > 0) {
            this.form.localizations[this.isManagingLocalizations ? 1 : 0].published = false
            this.form.localizations[this.isManagingLocalizations ? 1 : 0].translated = false
            this.showAlertDanger(this.$tc("alerts.foundErrorsInForm", errorCount))
          }
          
          return errorCount        
      }

    },
    removeErrors() {
      for (let key in this.error) {
        this.error[key] = ''
      }
    },
    validateInputs() {
      let errorCount = 0
      this.removeErrors()
      if (!this.form.theme) {
        errorCount += 1
        this.error.theme = this.$t('inputErrors.required')
      }
      if (!this.form.titlePicture) {
        this.error.titlePicture = this.$t('inputErrors.required')
        errorCount +=1
      }

      const ammountOfLocalizationInputErrors = this.validateLocalizationInputs()
      errorCount += ammountOfLocalizationInputErrors

      this.addErrorsInForm(errorCount)
      if (errorCount > 0) {
        const collapseBasicInfoElement = document.querySelector('#collapseBasicInfo')
        if (!collapseBasicInfoElement.classList.contains('show')) {this.$root.$emit('bv::toggle::collapse', 'collapseBasicInfo')}
      }
      return errorCount > 0 ? false : true
  },

  
  isChangeInLocalizationInputs(saveAction) {
      if (this.form.localizations.length < 1) return false
      let isAnyLocalizationPublished = false
      for (let i = 1; i < this.form.localizations.length; i++) {
        if (this.form.localizations[i].published) {
          isAnyLocalizationPublished = true
        }
      }
      if (!isAnyLocalizationPublished) return
      this.unpublishLocalizationModalSaveAction = saveAction

      this.isChangedLocalizationInputsTravelTip = false
      this.changedLocalizationInputDayIds = []
      this.changedLocalizationInputItemIds = []

      if (JSON.stringify(this.form.localizations[0]) != JSON.stringify(this.lastSavedForm.localizations[0])) {
        this.isChangedLocalizationInputsTravelTip = true
      }

      const dayRefs = this.$refs.itineraryDay
      if (dayRefs) {
        for (const day of dayRefs) {
          if (day.isChangeInLocalizationInputs()) {
            this.changedLocalizationInputDayIds.push(day.itineraryDay.id)
          }
          if (day.$refs.itineraryItem) {
            for (const item of day.$refs.itineraryItem) {
              if (item.isChangeInLocalizationInputs()) {
                this.changedLocalizationInputItemIds.push(item.itineraryItem.id)
              }
            }
          }
        }
      }

      if (this.isChangedLocalizationInputsTravelTip || this.changedLocalizationInputDayIds.length > 0 || this.changedLocalizationInputItemIds.length > 0) {
        this.isUnpublishOtherLocalizationsModalVisible = true
        return true
      }


      return false
      
    },
    saveAndUnpublishOthers() {
      this.isUnpublishOtherLocalizationsModalVisible = false
      this.unpublishOtherLocalizations()
      this.unpublishLocalizationModalSaveAction()
    },
    publishLocalization(index) {
      this.form.localizations[index].translated = true
      this.form.localizations[index].published = true

      const dayRefs = this.$refs.itineraryDay
      if (!dayRefs) return
      for (const day of dayRefs) {
        day.form.localizations[index].translated = true

        const itemRefs = day.$refs.itineraryItem
        if (itemRefs) {
          for (const item of itemRefs) {
            item.form.localizations[index].translated = true
          }
        }
      }
    },
    unpublishOtherLocalizations() {
      const localizationLanguage = this.form.localizations[0].language
      this.form.localizations.forEach(loc => {
        if (localizationLanguage != loc.language) {
          loc.published = false
        }
        if (localizationLanguage != loc.language && this.isChangedLocalizationInputsTravelTip) {
          loc.translated = false
        }
      })

      if (this.changedLocalizationInputDayIds.length === 0 && this.changedLocalizationInputItemIds.length === 0) return

      const dayRefs = this.$refs.itineraryDay
      for (const day of dayRefs) {
        day.form.localizations.forEach(loc => {
          if (localizationLanguage != loc.language && this.changedLocalizationInputDayIds.includes(day.itineraryDay.id)) {
            loc.translated = false
          }
        })
        for (const item of day.$refs.itineraryItem) {
          item.form.localizations.forEach(loc => {
            if (localizationLanguage != loc.language && this.changedLocalizationInputItemIds.includes(item.itineraryItem.id)) {
                loc.translated = false
            }
          })
        }
      }
    },

    onSubmit: async function(validateForms = false) {
      if(!this.$route.params.travelTipId) {
          this.loading.save = this.$t("loading.save")
          await TravelTipService.createTravelTip(this.form).then((result) => {
            // this.$refs.itineraryDay.onSubmit(null, result.data.createTravelTip.id);
            this.onCreate(result.data.createTravelTip.id)
          })
          .catch(err => {
            if (err.message.includes('exceeded limit for creating new travel tips.')) {
              this.showAlertDanger(this.$t("alerts.maxInProgressTravelTips"))
            } else {
              this.showAlertDanger(this.$t("alerts.travelTipCreateError"))
            }
          })
      } else {
        await TravelTipService.updateTravelTip(this.travelTipId, this.form).then((result) => {
          this.$apollo.queries.travelTip.refetch({
            travelTipId: this.travelTipId
          });
        });
        
      }

      const travelTipEquipmentsRef = this.$refs.travelTipEquipments
      await travelTipEquipmentsRef.setTravelTipEquipments()
    },
    async onCreate(id) {
      this.initialLoading = false
      this.$router.replace({ params: { travelTipId: id } })
      this.travelTipId = parseInt(id)
      await this.$apollo.queries.travelTip.refetch({
            travelTipId: this.travelTipId
      })
      this.showAlert(this.$t('alerts.travelTipUpdated'))
      this.resetToDefault()
    },

    resetToDefault: function() {
      this.manageUserRolesAndTipState()
      if(this.travelTip) {
        if (this.lastSavedForm.itineraryDays && this.lastSavedForm.itineraryDays.length < this.travelTip.itineraryDays.length) {
          return
        }
        if (this.lastSavedForm.itineraryDays && this.lastSavedForm.itineraryDays.length > this.travelTip.itineraryDays.length) {
          this.showAlert(this.$t('alerts.dayDeleted'))
          const copyOfItineraryDays = JSON.parse(JSON.stringify(this.travelTip.itineraryDays))
          this.form.itineraryDays = copyOfItineraryDays
          this.lastSavedForm.itineraryDays = copyOfItineraryDays
          return
        }
        const currentLocalizations = [...this.form.localizations]
        this.form = {
          campaignId: this.travelTip.campaign ? this.travelTip.campaign.id : null,
          hashtags: this.travelTip.hashtags,
          titlePicture: this.travelTip.titlePicture ? this.travelTip.titlePicture.id : null,
          itineraryDays: JSON.parse(JSON.stringify(this.travelTip.itineraryDays)),
          purchasable: this.travelTip.purchasable,
          theme: this.travelTip.theme,
          state: this.travelTip.state,
          top: this.travelTip.top,
          author: this.travelTip.author.userLogin,
          tagIds: this.travelTip.tagCategories ? this.travelTip.tagCategories.flatMap(category => category.tags.map(tag => tag.id)): [],
          originalLanguage: this.travelTip.originalLanguage ? this.travelTip.originalLanguage : 'cs',
          localizations: this.form.localizations.length < 2 ? 
            this.travelTip.localizations.map(localization => {
              const localizationCopy = {...localization, language: localization.language ? localization.language : 'cs' }
              delete localizationCopy['__typename'];
              return localizationCopy
            })
            : currentLocalizations
        }
        if (this.travelTip.titlePicture) {
          this.travelTipPicture = {...this.travelTip.titlePicture, localizations: [...this.travelTip.titlePicture?.localizations]}
        }

        if (this.initialLoading) {
          const tripLocalizationsLanguages = this.form.localizations.map(loc => loc.language);
          if (tripLocalizationsLanguages.includes(this.preferredLanguage)) {
            this.primaryLanguage = this.preferredLanguage
          } else if (this.preferredLanguage == 'cs' && tripLocalizationsLanguages.includes('sk')) {
            this.primaryLanguage = 'sk'
          } else {
            this.primaryLanguage = this.travelTip.originalLanguage
          }
          this.sortLocalizations()
        }

        this.checkState()
        this.cbPublished = this.travelTip.state == "PUBLISHED" ? true : this.travelTip.state == 'NOT_PUBLISHED' ? false : null
        this.pictureFileName = this.travelTip.titlePicture ? this.travelTip.titlePicture.fileName : ''
        this.lastSavedForm = {...this.form, localizations: this.form.localizations.map(loc => {return {...loc}})}
      } else {
        this.lastSavedForm = {...this.form, localizations: this.form.localizations.map(loc => {return {...loc}})}
        this.cbPublished = null
      }
    },

    onDeleteConfirmed: async function(permanently = false) {
      const comments = this.$refs.travelTipComments
      this.loading.modal = this.$t("loading.travelTipDelete")
      if (this.userRole === "AUTHOR" && ["IN_PROGRESS", "TO_REWORK"].includes(this.state)) {
        if (comments) await comments.deleteAllComments()
        await TravelTipService.deleteInProgressTravelTip(this.travelTip.id)
          .then(() => {
            this.$emit('showAlert', this.$t('alerts.travelTipDeleted'))
            this.leaveOnCreateOrDelete = true
            this.$router.push({name: 'adminTravelTips'});
          })
          .catch(() => this.showAlertDanger(this.$t('alerts.travelTipDeleteError')))
        return
      }
      else if (permanently) {
        if (comments) await comments.deleteAllComments()
        if (this.form.itineraryDays !== null) {
          for (const itineraryDay of this.form.itineraryDays) {
            if (itineraryDay.itineraryItems && itineraryDay.itineraryItems.length > 0) {
              for (const itineraryItem of itineraryDay.itineraryItems) {
                await ItineraryService.deleteItineraryItem(itineraryItem.id)
              }
            }
            await ItineraryDayService.deleteItineraryDay(itineraryDay.id)
          }
        }
        if (this.travelTip.id != null)
          await TravelTipService.deleteTravelTip(this.travelTip.id);
          this.$emit('showAlert', this.$t('alerts.travelTipDeleted'))
          this.leaveOnCreateOrDelete = true
          await this.$router.push({name: 'adminTravelTips'});
      } 
      else {
        this.loading.modal = this.$t("loading.travelTipDecline")
        await TravelTipService.declineTravelTip(this.travelTip.id)
          .then(() => {
            this.showAlert(this.$t('alerts.travelTipDeclined'))
            this.$bvModal.hide('modal-delete-travelTip')
          })
      }
    },

    countDownChanged(dismissCountDown) {
      this.alert.dismissCountDown = dismissCountDown
    },

    showAlert(notification) {
      for (let key of Object.keys(this.loading)) this.loading[key] = ''
      this.alert.dismissCountDown = this.alertDanger.dismissCountDown = 0
      this.alert.dismissCountDown = this.alert.dismissSecs;
      this.alert.notification = notification;
    },

    showAlertDanger: function (notification) {
      for (let key of Object.keys(this.loading)) this.loading[key] = ''
      this.alert.dismissCountDown = this.alertDanger.dismissCountDown = 0
      this.alertDanger.dismissCountDown = this.alertDanger.dismissSecs;
      this.alertDanger.notification = notification;
    },
    countDownChangedDanger: function(dismissCountDown) {
      this.alertDanger.dismissCountDown = dismissCountDown
    },

    showTripLocalizationApprovals() {
      this.$bvModal.show('translation-approvals')
      const localizationsApprovals = []
      this.form.localizations.forEach((localization, index) => {
        let allItems = []
        const dayRefs = this.$refs.itineraryDay
        allItems.push({id: this.travelTipId, key: this.travelTipId, type: "travelTip", title: this.form.localizations[index].title, isTranslated: this.form.localizations[index].translated})
        dayRefs?.forEach(day => {
          allItems.push({id: day.form.id, key: 'day' + day.form.id, type: "itineraryDay", title: day.form.localizations[index].title, isTranslated: day.form.localizations[index].translated})
          day.$refs.itineraryItem?.forEach(item => {
            if (item.form.itineraryItemType != 'EMPTY') {
              allItems.push({
                id: item.form.id,
                key: 'item' + item.form.id,
                type: "itineraryItem",
                title: item.form.localizations[index].title,
                isTranslated: item.form.localizations[index].translated,
                itineraryDayId: item.itineraryDay.id,
                itineraryItemType: item.form.itineraryItemType
              })
            }
          })
        })
        localizationsApprovals.push({
          language: localization.language,
          items: allItems
        })
      })
      this.localizationApprovalsByLanguage = localizationsApprovals
    },
    isTripLocalizationApproved() {
      if (this.form.localizations[0].published) return true
      return false
    },

    async handlePublishSwitch(nV, oV) {
      this.removeAllErrors()
      await this.validateAllForms()
      if (this.errorsInForms > 0 && nV !== false) {
        this.stateChangeFailed = true
        this.cbPublished = oV
        this.showAlertDanger(this.$t("alerts.travelTipStateChangeFormErrors", [this.errorsInForms]))
      }
      else {
        if (nV && !this.isTripLocalizationApproved()) {
          this.showAlertDanger(this.$t("alerts.localizationApprovalsError"))
          this.showTripLocalizationApprovals()
          this.stateChangeFailed = true
          this.cbPublished = oV
        } else if (nV) {
          TravelTipService.publishTravelTip(this.travelTip.id).then(() => this.showAlert(this.$t('alerts.travelTipPublished')))
        } else {
          TravelTipService.hideTravelTip(this.travelTip.id).then(() => this.showAlert(this.$t('alerts.travelTipUnpublished')))
        }
      }
    },
    onMapApiLoaded() {
      console.log("Loader loaded")
    },
    loadMapScript() {
      /*eslint-disable*/
        Loader.async = true
        Loader.load(null, {suggest: true}, this.onMapApiLoaded);
        Loader.lang = "cs";
      /*eslint-enable*/
    },
  },

  apollo: {
    travelTip: {
      query: TRAVEL_TIP_BY_ID_ADMIN,

      variables: function() {
        return {
          travelTipId: parseInt(this.travelTipId),
        }
      },
      error(error) {
        console.log(error)
        this.pageLoadingTitle = this.$t("loadingErrors.travelTipError")
      },

      skip: function () {
        return this.travelTipId == null
      },
    },

    campaigns: {
      query: CAMPAIGN_TITLES_ADMIN,

      skip: function() {
        return !this.$store.state.currentUserRole || this.$store.state.currentUserRole == "AUTHOR"
      },
      fetchPolicy: "no-cache"
    },
    pictures: {
      query: PICTURES,

      update(data) {
        return data.pictures.map(picture => assignLocalizationProperties(picture))
      },

      variables: function() {
        if (this.$store.state.currentUserRole == "AUTHOR") {
          return {filter: {authorId: this.$store.state.currentUserId}}
        }
      }
    },
  },

  watch: {
    async cbPublished(nV, oV) {
      this.form.state = nV ? 'PUBLISHED' : 'NOT_PUBLISHED'
      if (this.stateChangeFailed == true || oV !== !nV || nV === null) return this.stateChangeFailed = false
      this.handlePublishSwitch(nV, oV)
    },
    travelTip: function () {
      this.resetToDefault();
      if (this.initialLoading) this.initialLoading = false
    },
    '$store.state.currentUserRole'(nV) {
      this.userRole = nV
    },
    'form.itineraryDays.length'(nV, oV) {
      if (oV && nV > oV) {
        this.translatedChanged({isTranslated: false, localizationIndexes: this.form.localizations.map((_, index) => index)})
      } else if (oV) {
        this.$nextTick(() => {
          this.translatedChanged({isTranslated: true, localizationIndexes: this.form.localizations.map((_, index) => index)})
        })
      }
    },
  },

  mounted() {
    this.$root.$on('refetchPictures', this.newPictureAdded)
    this.$root.$on('showAlertFromItem', this.showAlert)
    this.$root.$on('addErrorsInForm', this.addErrorsInForm)
    this.loadMapScript()
  },

  created: function() {
    this.resetToDefault()
    if (this.$route.params.travelTipId !== undefined) {
      this.travelTipId = parseInt(this.$route.params.travelTipId);
    } else if (this.userRole != "ADMIN_SUPERVISOR") {
      this.createLocalization(this.$store.state.preferredLanguage)
    }
  },

}
</script>

<style scoped lang="scss">
    @import '@/scss/variables';

    #translation-approvals {
      
      .localization-approval-group {
        display: flex;
        flex-direction: column;
        flex: 1;
        gap: 10px;
      }

      .title-with-checkbox {
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        gap: 10px;

        &:hover p {
          color: $tr-green-alt-link;
        }
      }
    }

.forms-container {
  gap: 30px;
  padding-bottom: 50px;

  form {
    width: 100%;

    .form-content-container {
      display: flex;
      flex-direction: column;
      gap: 30px;
    }
  }

  &.visible-localizations {
    display: flex;
    flex-wrap: wrap;

    form {
      width: calc(50% - 15px);
    }
  }
}

.buttons-localization {
  display: flex;
  flex-direction: column;
  max-width: 330px;
  gap: 20px;
  width: 100%
}


.language-option {
  height: 60px;
  width: 200px;
  padding: 20px;
  line-height: 20px;
  text-align: center;
  cursor: pointer;

  &:hover {
    background-color: $tr-green;
  }
}

.close-modal {
  background-image: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(%23a)"><path d="M4.858 19.142 19 5" stroke="%23222"/><path d="M4.858 19.142 19 5M4.858 19.142 19 5M4.858 19.142 19 5M4.858 19.142 19 5" stroke="%23000" stroke-opacity=".2"/><path d="M19.142 19.142 5 5" stroke="%23222"/><path d="M19.142 19.142 5 5m14.142 14.142L5 5m14.142 14.142L5 5m14.142 14.142L5 5" stroke="%23000" stroke-opacity=".2"/></g><defs><clipPath id="a"><path fill="%23fff" d="M0 0h24v24H0z"/></clipPath></defs></svg>');
  background-size: 30px;
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  background-position: center;
  margin-left: auto;
}

.fullscreen-picture-description {
  position: absolute;
  max-width: calc(100% - 40px);
  width: max-content;
  z-index: 1;
  top: -35px;
  left: 50%;
  transform: translateX(-50%);
  background-color: $tr-white-alt;
  padding: 3px 10px;
}

.icon-with-tooltip-container {
  height: 50px;
  width: 50px;
  cursor: pointer;
  position: absolute;
  z-index: 1;
  top: 0;
  z-index: 1030;
  background-color: rgba(255, 255, 255, 0.85);

  &:hover {
    background-color: $tr-white;
  }
}

.admin-page-loading {
  transform: translate(-50%, -50%);
  position: fixed;
  top: 50%;
  left: calc(50% + 140px);
  font-size: 20px;
}

.img-download {
  background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21 10a1 1 0 1 0-2 0h2Zm-1.707 17.707a1 1 0 0 0 1.414 0l6.364-6.364a1 1 0 0 0-1.414-1.414L20 25.586l-5.657-5.657a1 1 0 0 0-1.414 1.414l6.364 6.364ZM19 10v17h2V10h-2Z' fill='%23000'/%3E%3Cpath d='M10 31h20' stroke='%23000' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E");
  background-size: 45px;
  background-position: center;
  cursor: pointer;
  width: 50px;
  height: 50px;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.85);
  left: 60px;

  &:hover {
    background-color: $tr-white;
  }
}

.filters-container {
  margin-bottom: 20px;
  gap: 20px;

  & > div, input {
    background-color: $tr-white;
  }
}

.calendar-icon {
  width: 50px;
  height: 50px;
  background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='10' cy='7' r='1' fill='%23222222'/%3E%3Ccircle cx='15' cy='7' r='1' fill='%23222222'/%3E%3Ccircle cx='20' cy='7' r='1' fill='%23222222'/%3E%3Ccircle cx='25' cy='7' r='1' fill='%23222222'/%3E%3Ccircle cx='30' cy='7' r='1' fill='%23222222'/%3E%3Cpath d='M35 7L35 35L5 35L5 7' stroke='%23222222'/%3E%3Cpath d='M5 11L35 11' stroke='%23222222'/%3E%3Cpath d='M9 30L31 30' stroke='%23222222'/%3E%3C/svg%3E%0A");
  background-size: 40px 40px;
  background-repeat: no-repeat;
  background-position: center center;
}

.add-itinerary-day {
  margin-top: -3px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  z-index: 1;
  width: 100%;
  height: 80px;
  color: $tr-black;
  background-color: $tr-white-alt;
  border: none;
  border-top: dotted 3px #c9c8c8;
  border-bottom: dotted 3px #c9c8c8;

  &:focus-visible {
    outline: none;
    border: 3px solid #3BEA7E;
  }
}
.no-interaction button, .no-interaction .x, .hide-buttons .add-day {
  display: none !important;
}

.fixed-buttons {
  display: flex;
  justify-content: center;
  max-width: calc(100% - 300px);
  width: 100%;
  bottom: 0;
  left: 300px;
  background-color: $tr-white;
  border-top: 1px solid $tr-light-gray;
  position: fixed;
  z-index: 1030;
  transition: 0.3s ease-in-out;
  transition-property: left, max-width;

  &.full-width {
    max-width: 100%;
    left: 0
  }

  &> div {
    max-width: 1090px;
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 40px;
    padding: 10px 40px;
  }
}

.disabledDays {
  pointer-events: none;
  div, a {
    opacity: 0.85;
  }
}
.collapse-toggle {
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 25px;
  color: $tr-black;
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  border-bottom: dotted 3px $tr-light-gray;
  border-top: dotted 3px $tr-light-gray;
  height: 80px;
  cursor: pointer;

  &:hover{
    color: $tr-black;
    text-decoration: none;
  }

  &::before{
    content: "";
    position: relative;
    display: inline-block;
    width: 35px;
    height: 20px;
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='17' viewBox='0 0 24 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 5.5L10.5 14L22.5 2' stroke='%233BEA7E' stroke-width='3'/%3E%3C/svg%3E%0A");
    background-position: left top;
    background-repeat: no-repeat;
    background-size: 24px 17px;
  }

  &::after{
    content: "";
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
    background-position: left top;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='15' viewBox='0 0 24 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M22 2L12 12L2 2' stroke='%23222222' stroke-width='3'/%3E%3C/svg%3E%0A");
    background-size: 24px 15px;
    margin-left: auto;
  }

  &[aria-expanded="true"]{
    &::after{
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='15' viewBox='0 0 24 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 13L12 3L22 13' stroke='%23222222' stroke-width='3'/%3E%3C/svg%3E%0A");
    }
  }
}

.days-tab::before{
  display: none;
}

a.days-tab span{
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 25px;
  color: $tr-gray;
  padding-left: 10px;
}

.card-picture {
  aspect-ratio: 4/3;
}

.img-container {
  margin-top: 20px;
  width: 100%;
  max-width: 330px;
  height: 250px;
  border: dashed 3px $tr-light-gray;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &.error {
    border: 2px solid $tr-pink;

    & + .input-alert {
      display: block
    }
  }

  .info-icon {
    position: absolute;
    top: 0;
    right: -35px;
  }

  &:hover {
      .plus {
          background-color: $tr-dark-gray;
      }
  }
  .avatar-image {
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;

      .x {
        margin: -10px -10px 0 0;
      }
  }
  &.noborder {
      border: none;
  }
  input {
      display: none;
  }
  img {
      width: 100%;
      height: 100%;
      object-fit: cover;
  }
  label {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 1;
      opacity: 0;
      cursor: pointer;
  }
  p::after{
    content: "*";
    margin-left: 3px;
  }
}

.img-container + .input-alert {
  display: none;
  font-size: 12px;
  color: $tr-pink;
}

.floating-button {
  width: 90px;
  height: 90px;
  z-index: 1040;
  position: fixed;
  right: 40px;
  bottom: 100px;
  background-size: 60px;
  background-color: $tr-white;
  border-radius: 50%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2) !important;
  border: none;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  
  &:focus-visible {
    border: 1px solid $tr-black;
  }
  &:active {
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.5) !important;
  }

}

.preview-translation-icon {
  bottom: 200px;
  background-image: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.31 12.155 8.97 5.71m2.55 6.465L8.97 5.71m1.7 4.3H7.195m9.12 2.68h3.36m-1.835 0c0 2.17-2.645 5.755-5.295 6.305" stroke="%23222" stroke-width=".8" stroke-linecap="round" stroke-linejoin="round"/><path d="M13.965 16.395c1.065 1.2 2.805 2.37 4.41 2.6" stroke="%23222" stroke-width=".8" stroke-linecap="round" stroke-linejoin="round"/><path d="M15.085 2.75H2.75v12.335h10.515l1.82-1.82z" stroke="%23222" stroke-width=".8" stroke-linecap="round"/><path d="M8.915 15.085v6.165h12.33V8.915H15.08" stroke="%23222" stroke-width=".8"/></svg>');
}

.preview-trip-icon {
  background-image: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18.167 22c2.3 0 4.166-2.5 4.166-2.5S20.468 17 18.167 17C15.865 17 14 19.5 14 19.5s1.865 2.5 4.167 2.5Z" stroke="%23222" stroke-linejoin="round"/><path d="M18.167 20.542a1.042 1.042 0 1 0 0-2.084 1.042 1.042 0 0 0 0 2.084Z" stroke="%23222" stroke-linejoin="round"/><path d="M18 15.5V6l-4.308-4H4v20h9.692" stroke="%23222"/><path d="M14 2v4h4" stroke="%23222"/><path stroke="%23000" d="M7 8.5h7m-7 2h7m-7 2h7m-7 2h7"/></svg>');
}

</style>

<style lang="scss">
  @import '@/scss/variables';

  .collapsing-no-animation .collapsing, .collapsing-no-animation .show {
    -webkit-transition: none !important;
    transition: none !important;
  }

  .admin-travel-tip {
    padding-bottom: 80px
  }

  .tabs .card-header a {
    color: #3bea7e
}

.card-header {
    background-color: transparent!important
}

</style>
