import gql from 'graphql-tag'

export const TRAVEL_TIP_COMMENTS = gql`query travelTipComments($travelTipId:Int $placeId: Int) {
  comments: adminConsoleComments(travelTipId: $travelTipId, placeId: $placeId){
      id
      text
      user {
        id
        userName
      }
      createdAt
  }
}`;