<template>
    <b-modal class="modal" id="modal-travelTip" hide-footer hide-header no-close-on-backdrop>
      <div class="modal-container py-5 d-flex flex-column justify-content-center mx-auto">
        <h3 class="moda-title pb-3">{{modalTitle}}</h3>
        <p class="moda-text pb-5">{{modalText}}</p>
        <div class="pb-5 d-flex" style="gap: 40px">
            <TrekioButton secondary :disabled="loadingModal != ''"  @click="hideModal()">{{$t("general.back")}}</TrekioButton>
            <TrekioButton primary :isLoading="loadingModal != ''" :loadingText="loadingModal" @click="$emit('confirmed')">{{buttonText}}</TrekioButton>
        </div>
      </div>
    </b-modal>
</template>

<script>
    export default {
        name: "ConfirmModal",
        props: {
            loadingModal: String,
            action: String,
            passedObject: Object,
            userRole: String,
            state: String,
        },

        data: function() {
            return {

            }
        },

        methods: {
            hideModal() {
                this.$bvModal.hide('modal-travelTip')
            }
        },

        computed: {
            isUserAuthor() {
                return this.userRole === "AUTHOR"
            },
            isUserAdmin() {
                return this.userRole === "ADMIN"
            },
            isUserAdminSupervisor() {
                return this.userRole === "ADMIN_SUPERVISOR"
            },
            buttonText() {
                if (this.action == 'onDeleteConfirmed(true)' || this.action == 'onDeleteConfirmed(false)' && this.isUserAuthor && ["IN_PROGRESS", "TO_REWORK"].includes(this.state)) return this.$t("buttons.delete")
                else if (['onDeleteConfirmed(false)', 'declineTravelTip'].includes(this.action)) return this.$t("buttons.decline")
                else if (this.action == 'toRework') return this.$t("buttons.returnToRework")
                else if (this.action == 'saveAllForms(true)') {
                    if (this.isUserAdminSupervisor) return this.$t("buttons.approve")
                    else if (this.isUserAdmin && ['IN_PROGRESS', 'TO_REWORK', 'TO_CONTROL'].includes(this.state)) return this.$t("buttons.sendToApprove")
                    else return this.$t("buttons.sendToControl")
                }
                return ''
            },
            modalTitle() {
                if (!this.passedObject) return ''
                if (this.action == 'onDeleteConfirmed(true)' || this.action == 'onDeleteConfirmed(false)' && this.isUserAuthor && ["IN_PROGRESS", "TO_REWORK"].includes(this.state)) return this.$t("modals.travelTipDeleteTitle", [this.passedObject.localizations[0].title])
                else if (['onDeleteConfirmed(false)', 'declineTravelTip'].includes(this.action)) return this.$t("modals.travelTipDeclineTitle", [this.passedObject.localizations[0].title])
                else if (this.action == 'toRework') return this.$t("modals.travelTipToReworkTitle", [this.passedObject.localizations[0].title])
                else if (this.action == 'saveAllForms(true)') {
                    if (this.isUserAdminSupervisor) return this.$t("modals.travelTipApproveTitle", [this.passedObject.localizations[0].title])
                    else if (this.isUserAdmin && ['IN_PROGRESS', 'TO_REWORK', 'TO_CONTROL'].includes(this.state)) return this.$t("modals.travelTipToApproveTitle", [this.passedObject.localizations[0].title])
                    else return this.$t("modals.travelTipToControlTitle", [this.passedObject.localizations[0].title])
                }
                return ''

            },
            modalText() {
                if (this.action == 'onDeleteConfirmed(true)' || this.action == 'onDeleteConfirmed(false)' && this.isUserAuthor && ["IN_PROGRESS", "TO_REWORK"].includes(this.state)) return this.$t("modals.travelTipDeleteText")
                else if (['onDeleteConfirmed(false)', 'declineTravelTip'].includes(this.action)) return this.$t("modals.travelTipDeclineText")
                else if (this.action == 'toRework') return this.$t("modals.travelTipToReworkText")
                else if (this.action == 'saveAllForms(true)') {
                    if (this.isUserAdminSupervisor) return this.$t("modals.travelTipApproveText")
                    else if (this.isUserAdmin && ['IN_PROGRESS', 'TO_REWORK', 'TO_CONTROL'].includes(this.state)) return this.$t("modals.travelTipToApproveText")
                    else return this.$t("modals.travelTipToControlText")
                    }
                return ''
            }
        },
    }
</script>

<style scoped>

</style>