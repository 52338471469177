import {CREATE_ITINERARY_DAY,UPDATE_ITINERARY_DAY,DELETE_ITINERARY_DAY} from "@/api/graphql/mutation/ItineraryDayMutation";
import {TRAVEL_TIP_BY_ID_ADMIN} from "@/api/graphql/query/TravelTipQuery";
import { apolloClient } from "@/api/apolloClient";

export default{
    createItineraryDay: function(travelTipId , form) {
        console.log("Creating new itinerary day with travel tip id : " + travelTipId);
        const {dayNumber, picture, localizations} = form;

        return apolloClient.mutate({
            mutation: CREATE_ITINERARY_DAY,
            variables: {
                travelTipId,
                dayNumber,
                picture,
                localizations
        },
        update: async (store, {data: {createItineraryDay}}) => {
            let newDay = {...createItineraryDay, itineraryItems: []}
            let data = await store.readQuery({ query: TRAVEL_TIP_BY_ID_ADMIN, variables: {travelTipId: parseInt(travelTipId)} })
            let dataCopy = {...data, travelTip: {...data.travelTip, itineraryDays: [...data.travelTip.itineraryDays, newDay]
            }}
            store.writeQuery({ query: TRAVEL_TIP_BY_ID_ADMIN, variables: {travelTipId: travelTipId}, data: dataCopy })
        }
        }).then((result) => {
            console.log("Itinerary day created: ", result);
            return result;
        });

    },

    updateItineraryDay: function(travelTipId, form, id, dayPicture) {
        console.log("Updating itinerary day for travel tip is :"+ travelTipId, id);
        const {dayNumber, picture, localizations} = form;

        return apolloClient.mutate({
            mutation: UPDATE_ITINERARY_DAY,
            variables: {
                travelTipId,
                id,
                dayNumber,
                picture,
                localizations
            },
            update: async (store) => {
                let data = await store.readQuery({ query: TRAVEL_TIP_BY_ID_ADMIN, variables: {travelTipId: parseInt(travelTipId)} })
                let dataCopy = {...data, travelTip: {...data.travelTip, itineraryDays: data.travelTip.itineraryDays.map(day => {
                    if (day.id == id) {
                        return {
                            ...day,
                            dayNumber,
                            picture: dayPicture 
                        }
                    }
                    return {...day}
                })},}
                store.writeQuery({ query: TRAVEL_TIP_BY_ID_ADMIN, variables: {travelTipId: travelTipId}, data: dataCopy })
            }
        }).then((result) => {
            console.log("Itinerary day updated: ", result);
            return result;
        });
    },

    deleteItineraryDay: function(itineraryDayId, travelTipId) {
        console.log("Deleting itinerary day with id : " + itineraryDayId);
        return apolloClient.mutate({
            mutation: DELETE_ITINERARY_DAY,
            variables: {
                itineraryDayId
        },
        update: async (store) => {
            let data = await store.readQuery({ query: TRAVEL_TIP_BY_ID_ADMIN, variables: {travelTipId: travelTipId} })
            let dataCopy = {...data, travelTip: {...data.travelTip, itineraryDays: data.travelTip.itineraryDays.filter(day => {
                if (day.id !== itineraryDayId) {
                    return true
                }
                return false
            })},}
            store.writeQuery({ query: TRAVEL_TIP_BY_ID_ADMIN, variables: {travelTipId: travelTipId}, data: dataCopy })
        }
        }).then((result) => {
            return result;
        });
    },
}
