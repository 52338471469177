import gql from "graphql-tag";

export const CREATE_ITINERARY_DAY = gql`
        mutation createItineraryDay(
            $travelTipId:ID!,
            $dayNumber:Int,
            $picture: ID,
            $localizations: [ItineraryDayLocalizationInput]
        ){
            createItineraryDay(
                travelTipId:$travelTipId,
                dayNumber:$dayNumber,
                picture: $picture
                localizations: $localizations
            )
            {
                id
                dayNumber
                picture {
                    id
                    localizations {
                        title
                    }
                }
                localizations {
                    translated
                    language
                    title,
                    description
                }
            }
        }`

export const UPDATE_ITINERARY_DAY = gql`
        mutation updateItineraryDay(
            $id:ID!,
            $travelTipId:ID!,
            $dayNumber:Int,
            $picture:ID,
            $localizations: [ItineraryDayLocalizationInput]
        ){
            updateItineraryDay(
                id:$id,
                travelTipId:$travelTipId,
                dayNumber:$dayNumber,
                picture:$picture,
                localizations: $localizations
            ) {
                id
            }
        }`

export const DELETE_ITINERARY_DAY = gql`
        mutation deleteItineraryDay($itineraryDayId:Int!){
            deleteItineraryDay(itineraryDayId:$itineraryDayId)
        }`


