<template>
  <div class='text-left'>
    <b-alert :show="alert.dismissCountDown" fade variant="success" id="showAlertDay"
    @dismiss-count-down="countDownChanged" class="alert-success">{{alert.notification}}
      </b-alert>
      <b-alert :show="alertDanger.dismissCountDown" fade variant="danger" id="showAlertDangerDay"
               @dismiss-count-down="countDownChangedDanger" class="alert-danger multi-line pre-formatted">{{alertDanger.notification}}
      </b-alert>
      
      <div class="row">
        <div class="col-12">
          <div class="day-header">
            <div class="row align-items-center m-0 h-100 day-collapse" v-b-toggle="`collapseDayInfo${itineraryDay.id}`" :id="`day-collapse-${itineraryDay.id}`">
              <div class="col-2"><div class="calendarWithNumber">{{ form.dayNumber }}</div></div>
              <div class="col-8 d-flex align-items-center" style="text-overflow: ellipsis;">
                <div class="day-number"><input type="number" id="dayNumber" :class="{'error': error.dayNumber}" @keypress="blockInvalidChar($event, 3)" @click.stop="" v-model="form.dayNumber"><span class="info-icon" v-tooltip.right-start="error.dayNumber"></span></div>
                <span style="white-space: nowrap" class="title">{{ form.localizations[0].title }}</span>
              </div>
              <div class="col-1"><b-button @click.stop="" v-if="!isManagingLocalizations && itineraryDay.id" v-b-modal="'modal-delete-itineraryDay'+itineraryDay.id" class="btn-delete"></b-button></div>
              <div class="col-1 p-0"><a class="collapseArrow" @click.prevent></a></div>
            </div>
          </div>
          
          <b-modal class="modal" :id="'modal-delete-itineraryDay' + itineraryDay.id" hide-footer hide-header>
            <div class="modal-container py-5 d-flex flex-column justify-content-center mx-auto">
              <h3 class="moda-title pb-3">{{ $t("modals.dayDeleteTitle", [itineraryDay.title, travelTipTitle]) }}</h3>
              <p class="moda-text pb-5">{{ $t("modals.dayDeleteText") }}</p>
              <div class="pb-5 d-flex" style="gap: 40px">
                <TrekioButton secondary @click="$bvModal.hide('modal-delete-itineraryDay' + itineraryDay.id)">{{$t("general.back")}}</TrekioButton>
                <TrekioButton primary :isLoading="loadingDelete !=''" :loadingText="loadingDelete" @click="onDeleteConfirmed">{{ $t("buttons.delete") }}</TrekioButton>
              </div>
            </div>
          </b-modal>
          
          <b-collapse style="margin-bottom: 50px;" :visible="!this.form.id" :id="('collapseDayInfo' + itineraryDay.id)">
          <div class="forms-container" :class="{'visible-localizations': isManagingLocalizations}">
            <form @submit.prevent="onSubmit(false)" novalidate v-for="(localization, localizationIndex) in form.localizations" :key="localization.language">
              <div class="form-content-container" v-if="primaryLanguage == localization.language || isManagingLocalizations && editedLanguage == localization.language">
                <div class="row">
                  <div class="offset-2 col-10 h-100">
                    <TrekioInput id="title" v-model="localization.title" :label="$t('inputLabels.dayTitle')" maxLength="40" :disabled="isManagingLocalizations && primaryLanguage == localization.language"
                      :error="error.title" required :info="isInfoIconVisible(localization.language) ? $t('info.dayName') : null" :hint="$t('inputHints.maxLength40')"
                    />
                  </div>
                </div>
                
                <div class="row">
                  <div class="col-10 offset-2 h-100">
                    <TrekioTextarea id="description" trim v-model="localization.description" :label="$t('inputLabels.dayDescription')" required :info="isInfoIconVisible(localization.language) ? $t('info.dayDescription') : null"
                      :hint="$t('inputHints.maxLength1024')" :error="error.description" maxlength="1024" :disabled="isManagingLocalizations && primaryLanguage == localization.language" :height="textareaHeights.description"
                      @resizeTextarea="setTextareaHeight"
                    />
                    <Checkbox class="mt-4" v-if="(isAdmin || isSupervisor) && (!isManagingLocalizations || localization.language != primaryLanguage)" v-model="localization.translated" @change="translatedChanged({isTranslated: localization.translated, isFromDay: true, localizationIndexes: [localizationIndex]})">
                      {{ `${$t('admin.approveDayTranslation')} (${localization.language})`}}
                    </Checkbox >
                  </div>
                </div>
  
                <div class="row" v-if="!isManagingLocalizations">
                  <div class="offset-2 col-10 h-100">
                    <div class="day-picture-text">
                      <p class="title">{{ $t("inputLabels.dayPicture") }}</p>
                      <p class="warning" v-if="allPicturesInDay.length < 1">{{ $t("admin.dayPictureWarning") }}</p>
                    </div>
                    <div class="img-container d-flex justify-content-center align-items-center flex-column" :class="{'noborder': dayPicture, 'disabled': allPicturesInDay.length < 1}">
                      <div v-if="isInfoIconVisible(localization.language)" class="info-icon" v-tooltip.right-start="$t('info.dayPicture')"></div>
                      <div v-if="dayPicture" class="image-container">
                        <div v-if="!disableInputs" class="x ml-auto" @click.stop="removeDayPicture"></div>
                        <img @click="showFullscreenDayPicture(dayPicture)" class="day-picture" :src="imgUrl + dayPicture.fileName.slice(0,dayPicture.fileName.lastIndexOf('.')) + '-dimensions-640pxX480px' + dayPicture.fileName.slice(dayPicture.fileName.lastIndexOf('.'))" alt="">
                        <div v-if="!disableInputs" class="icon-with-tooltip-container">
                          <img v-tooltip.top="$t('info.changeDayPicture')" src="../../assets/svg/icon-choose-as-day-picture.svg" v-b-modal="`modal-day-picture-select${itineraryDay.id}`" alt="">
                        </div>
                      </div>
                      <div v-else v-b-modal="`modal-day-picture-select${itineraryDay.id}`" class="image-container d-flex flex-column align-items-center justify-content-center">
                        <div class="plus mx-auto"></div>
                        <p class="mt-4 text-center">{{ $t("admin.dayPictureChoosePicture") }}</p>
                      </div>
                    </div>
  
                  </div>
                </div>
              </div>
            </form>
          </div>
          <b-modal size="fit-content" class="fullsize-picture modal pl-0" :id="'fullscreen-day-picture' + itineraryDay.id" hide-footer hide-header>
            <div @click="$bvModal.hide('fullscreen-day-picture' + itineraryDay.id)" class="x"></div>
            <figure v-lazyload>
              <div class="loading-circle position-absolute"><div></div><div></div><div></div><div></div></div>
              <img class="picture" :data-url="fullScreenPictureUrl">
              <p class="fullscreen-picture-description">{{dayPicture && dayPicture.localizations[0].title}}</p>
            </figure>
          </b-modal>

          <b-modal class="modal" :id="'modal-day-picture-select' + itineraryDay.id" hide-footer hide-header>
            <div class="modal-container py-5 d-flex flex-column justify-content-center mx-auto"  style="max-width: none">
              <div v-if="allPicturesInDay.length < 1">{{ $t("admin.dayPictureWarningNoItemPicture") }}</div>
              <PictureList :pictures="allPicturesInDay" :travelTipPictures="true" @onPictureClick="onPictureClick"/>
            </div> 
          </b-modal>
            <a class="days-tab" style="margin-top: -3px;" v-b-toggle="`collapseItemsList${itineraryDay.id}`">{{ $t("general.dayRoute") }} <span>({{itemsLengthWithoutEmptyItems()}})</span></a>
            <b-collapse :id="'collapseItemsList'+itineraryDay.id" @shown="itemIsOpened">
              <div class="map-with-item-list">
                <!-- <div :class="'map day-map' + itineraryDay.id">Mapa</div> -->
                <AdminMap v-if="!isManagingLocalizations" ref="map" :itineraryItems="itemsCopy" :itemsLength="itemsCopy.length" :editLocationItemId="editLocationItemId" :showMap="firstTimeOpened" :itineraryDay="itineraryDay" :activeItemFormId="activeItemFormId" :disableInputs="disableInputs"
                          @setActiveItemForm="setActiveItemForm" @setEditLocationItemId="setEditLocationItemId"/>

                <div class="item-list-container">
                  <div class="item-list-functions">
                    <a role="presentation" class="" :class="{active: chosenItemsAction == 'ITEMS'}" @click="chooseItemsAction('ITEMS')">{{ $t("general.itineraryItems") }}</a>
                    <a v-if="!isManagingLocalizations" role="presentation" class="" :class="{active: chosenItemsAction == 'EMPTY_ITEMS', error: isEmptyItemMissingLocation}" @click="chooseItemsAction('EMPTY_ITEMS')">{{ $t("admin.specifyRoute")}}</a>
                  </div>

                  <button v-if="!isManagingLocalizations && (itemsCopy.length < 1 || !isFirstItemStart)" type="button" class="add-itinerary-item only-border-bottom"  @click="createNewItem('START', 'addStart')">{{ loading.addStart ? $t("loading.startItemCreate") : $t("buttons.addStartItem") }}</button>

                  <div class="item-list" :id="'item-list' + itineraryDay.id" :class="{'max-h-450': isFirstItemStart ^ isLastItemFinish ,'max-h-500': isFirstItemStart && isLastItemFinish}">
                    <div v-for="(item, index) in itemsCopy" :key="item.id">
                      <div v-if="chosenItemsAction == 'EMPTY_ITEMS' && item.itineraryItemType != 'EMPTY' && index > 0 && itemsCopy[index - 1].itemNumber % 10 !== 9" class="add-empty-item" :class="{'mt-0' : itemsCopy[index - 1].itineraryItemType == 'EMPTY'}">
                        <button v-if="!isManagingLocalizations" @click="createEmptyItem(index)" >{{ loading.emptyItemIndex == index ? $t("loading.emptyItemCreate") : $t("buttons.addEmptyItem")}}</button>
                      </div>
                      <p v-else-if="chosenItemsAction == 'EMPTY_ITEMS' && item.itineraryItemType != 'EMPTY' && index > 0 && itemsCopy[index - 1].itemNumber % 10 === 9" class="add-empty-item warning-max-amount">{{ $t("admin.warningMaxEmptyItems") }}</p>
                      <ItineraryItemMenu :item="item" :index="index" :itineraryDay="itineraryDay" :activeItemFormId="activeItemFormId" :editLocationItemId="editLocationItemId" :chosenItemsAction="chosenItemsAction" :primaryLanguage="primaryLanguage" :isManagingLocalizations="isManagingLocalizations"
                            @setActiveItemForm="setActiveItemForm" @setEditLocationItemId="setEditLocationItemId" @dragStart="dragStart" @dragEnd="dragEnd" @dragOver="dragOver" @reloadMap="itemIsOpened"
                      />
                    </div>
                  </div>

                  <button v-if="!isManagingLocalizations" type="button" class="add-itinerary-item default"  @click="createNewItem('DEFAULT', 'addDefault')">{{ loading.addDefault ? $t("loading.itemCreate") : $t("buttons.addDefaultItem") }}</button>
                  <button v-if="!isManagingLocalizations && (itemsCopy.length < 1 || itemsCopy[0] && itemsCopy[itemsCopy.length - 1].itineraryItemType !=='FINISH')" type="button" class="add-itinerary-item only-border-bottom pointer-events-none" @click="createNewItem('FINISH', 'addFinish')">{{ loading.addFinish ? $t("loading.finishItemCreate") : $t("buttons.addFinishItem") }}</button>
               </div>
              </div>
              <div v-for="(item, index) in itemsCopy" :key="item.id">
                <ItineraryItem v-show="activeItemFormId == item.id && item.itineraryItemType !== 'EMPTY'" ref="itineraryItem" :index="index" :itineraryItem="item" :disableInputs="disableInputs" :itineraryDay='itineraryDay' :allItineraryItems="itemsCopy" 
                  :pictures="pictures" :dayPictureId="form.picture" :previousDayFinish="previousDayFinish" :previousDayId="previousDay && previousDay.id" :isFirstItemStart="isFirstItemStart"
                  :isManagingLocalizations="isManagingLocalizations" :originalLanguage="originalLanguage" :primaryLanguage="primaryLanguage" :editedLanguage="editedLanguage" :localizationCodes="localizationCodes"
                  @showAlert="showAlert" @showAlertDanger="showAlertDanger" @reloadMap="itemIsOpened" @saveItems="onSubmit" @missingEmptyItemLocation="missingEmptyItemLocation" @setDayPicture="setDayPicture" @updateAllPicturesInDay="updateAllPicturesInDay" @checkDayPicture="checkDayPicture"
                  @translatedChanged="translatedChanged"
                />
              </div>
              <button v-if="activeItemFormId && activeItemType !=='EMPTY'" type="button" class="close-collapse" @click="closeItemForm()"><div class="arrow-up"></div>{{ $t("admin.closeItem") }}</button>
            </b-collapse>
          </b-collapse>
        </div>
      </div>
  </div>
</template>

<script>
import ItineraryItem from "@/components/admin/ItineraryItem";
import ItineraryDayService from "../../services/ItineraryDayService";
import {GET_ITINERARY_ITEMS_BY_ITINERARY_DAY_ID} from "@/api/graphql/query/ItineraryItemQuery";
import ItineraryService from "@/services/ItineraryService";
import { travelTipInfo } from "../../components/InfoIconTexts.js";
import AdminMap from "./AdminMap.vue";
import ItineraryItemMenu from "./ItineraryItemMenu.vue";
import {CLEVERTRIP_IMG_URL} from "@/definitions";
import LazyLoadDirective from "@/components/LazyLoadDirective.js";
import PictureList from "../../components/admin/PictureList.vue";
import Checkbox from "../Checkbox.vue";
import sharedUtils from "../../utils/sharedUtils";

export default {
  directives: {
    lazyload: LazyLoadDirective
  },
  name: "itineraryDay",

  props: {
    itineraryDay: Object,
    previousDay: Object,
    travelTipId:Number,
    travelTipTitle: String,
    pictures: Array,
    allDays: Array,
    disableInputs: Boolean,
    isManagingLocalizations: Boolean,
    editedLanguage: String,
    originalLanguage: String,
    primaryLanguage: String,
    localizationCodes: Array
  },

  components: {
    ItineraryItem,
    AdminMap,
    ItineraryItemMenu,
    PictureList,
    Checkbox
},

  data: function() {
    return {
      imgUrl: CLEVERTRIP_IMG_URL,
      mapRef: null,
      infoTexts: travelTipInfo,
      loading: {
        day: '',
        addStart: false,
        addDefault: false,
        addFinish: false,
        emptyItemIndex: null, 
      },
      textareaHeights: {
        description: '100px'
      },
      loadingDelete: '',
      show: true,
      dayNumberError: this.$t("inputErrors.required"),
      isEmptyItemMissingLocation: false,
      alert: {
        dismissSecs: 5,
        dismissCountDown: 0,
        showDismissibleAlert: false,
        notification: ''
      },
      alertDanger: {
        dismissSecs: 999,
        dismissCountDown: 0,
        showDismissibleAlert: false,
        notification: ''
      },
      travelTip: null,
      itineraryItems: null,
      form: {
        dayNumber: null,
        picture: null,
        localizations: []
      },
      error: {
        dayNumber: null,
        title: '',
        description: ''
      },
      dayPicture: null,
      allPicturesInDay: [],
      refreshDayPictureKey: 0,
      activeItemFormId: null,
      lastSavedForm: {},
      newItineraryItem: {
        id: null,
        itineraryItemType: 'DEFAULT',
        duration: null,
        difficulty: null,
        itemNumber: null,
        countries: [],
        transferTypeValues: [{transferType: '', length: 0}],
        location: {
          latitude: null,
          longitude: null
        },
        localizations: [],
        itineraryItemPictureIds: [],
        itineraryItemPictures: []
      },
      firstTimeOpened: false,
      itemsCopy: [],
      draggingItemId: null,
      chosenItemsAction: 'ITEMS',
      isFirstLoad: true,
      editLocationItemId: null,
      activeItemType: null,
      previousDayItems: null,
    }
  },

  created() {
    this.resetToDefault()
    this.$root.$on('updateItems', this.updateItems)
    this.$root.$on('createLocalization', this.createLocalization)
    this.$root.$on('discardChanges', this.discardChanges)
  },
  mounted() {
    this.checkState()
    this.mapRef = this.$refs['map']
  },

  watch: {
    editedLanguage() {
      this.sortLocalizations()
    },
    primaryLanguage() {
      this.sortLocalizations()
    },
    disableInputs(nV) {
      nV && this.checkState()
    },
    itineraryDay() {
      this.lastSavedForm = {...this.form, localizations: this.form.localizations.map(localization => {return {...localization}})}
    },
    itineraryItems(nV, oV) {
      if (!this.isFirstLoad) return
      this.isFirstLoad = false
      this.updateItems(true)
      if (this.previousDay) {
        this.setupPreviousDayItemsWatch()
      }
    },
    'itemsCopy.length'(nV, oV) {
      if (oV && nV > oV) {
        this.$nextTick(() => {
          this.$emit("translatedChanged", {isTranslated: false, localizationIndexes: this.form.localizations.map((_, index) => index)})
        })
      }
      if (oV && nV < oV) {
        this.$nextTick(() => {
          this.$emit("translatedChanged", {isTranslated: true, localizationIndexes: this.form.localizations.map((_, index) => index)})
        })
      }
    }
  }, 

  computed: {
    isAdmin() {
      return this.$store.state.currentUserRole === "ADMIN"
    },
    isSupervisor() {
      return this.$store.state.currentUserRole === "ADMIN_SUPERVISOR"
    },
    previousDayFinish() {
      if (!this.previousDayItems) return null
      return this.previousDayItems.find(item => item.itineraryItemType === 'FINISH')
    },
    isFirstItemStart() {
      if (!this.itemsCopy || this.itemsCopy.length == 0) return
      return this.itemsCopy[0].itineraryItemType == "START"
    },
    isLastItemFinish() {
      if (!this.itemsCopy || this.itemsCopy.length == 0) return
      return this.itemsCopy[this.itemsCopy.length - 1].itineraryItemType == "FINISH"
    },
    fullScreenPictureUrl() {
      if (!this.dayPicture) return
      let fileName = this.dayPicture.fileName
      return this.imgUrl + fileName.slice(0, fileName.lastIndexOf('.')) + '-dimensions-1600pxX1200px' + fileName.slice(fileName.lastIndexOf('.'))
    }
  },

  methods: {
    setTextareaHeight({id, height}) {
      this.textareaHeights[id] = height + "px"
    },
    isChangeInLocalizationInputs() {
      if (JSON.stringify(this.form.localizations[0]) != JSON.stringify(this.lastSavedForm.localizations[0])) {
        return true
      }
      return false
    },
    translatedChanged({ isTranslated, localizationIndexes}) {
      this.$emit('translatedChanged', {isTranslated, localizationIndexes})
    },
    createCopyOfForm(form) {
      return {
        ...form,
        localizations: form.localizations.map(localization => ({...localization}))
      }
    },
    discardChanges() {
      this.form = this.createCopyOfForm(this.lastSavedForm) 
    },
    isInfoIconVisible(language) {
      // Show info icons only for the main form on the left
      return this.form.localizations[0].language === language
    },
    sortLocalizations(){
      sharedUtils.sortLocalizations(this.form.localizations, this.primaryLanguage, this.editedLanguage)
      if (this.lastSavedForm) {
        sharedUtils.sortLocalizations(this.lastSavedForm.localizations, this.primaryLanguage, this.editedLanguage)
      }
    },
    createLocalization(languageCode) {
      this.form.localizations.push({
        language: languageCode,
        title: '',
        description: '',
        translated: false
      })
    },
    setupPreviousDayItemsWatch() {
      this.previousDayItems = this.$store.state.admin.itemForms['day' + this.previousDay.id]
    },
    removeDayPicture() {
      this.dayPicture = null
      this.form.picture = null
    },
    checkDayPicture(pictures) {
      const pictureIds = pictures.map( pic => pic.id)
      if (this.dayPicture && pictureIds.includes(this.dayPicture.id)) {
        this.removeDayPicture()
      }
      this.$emit("checkTravelTipPicture", pictures)
      this.allPicturesInDay = this.allPicturesInDay.filter( picture => !pictureIds.includes(picture.id))
    },
    onPictureClick(picture) {
      this.setDayPicture(picture)
      this.$bvModal.hide(`modal-day-picture-select${this.itineraryDay.id}`)
    },
    updateAllPicturesInDay(pictures, pictureToAdd = null, pictureToRemove = null) {
      if (pictureToAdd != null && !this.allPicturesInDay.some(pic => pic.id == pictureToAdd.id)) {
        this.allPicturesInDay.push(pictureToAdd)
        this.$emit("updateAllPicturesInTravelTip", null, pictureToAdd, null )
        
      }
      else if (pictureToRemove != null) {
        this.allPicturesInDay = this.allPicturesInDay.filter(picture => picture.id != pictureToRemove.id)
        if (this.dayPicture && this.dayPicture.id == pictureToRemove.id) {
          this.removeDayPicture()
        }
        this.$emit("updateAllPicturesInTravelTip", null, null, pictureToRemove )
      }
      else {
        pictures?.forEach( picture => {
          if (!this.allPicturesInDay.some(pic => pic.id == picture.id)) {
            this.allPicturesInDay.push(picture)
          }
        })
        this.$emit("updateAllPicturesInTravelTip", pictures, null, null )
      }
    },
    showFullscreenDayPicture(picture) {
      this.$bvModal.show('fullscreen-day-picture' + this.itineraryDay.id)
    },
    setDayPicture(picture) {
      this.form.picture = picture.id
      this.dayPicture = picture
      this.refreshDayPictureKey += 1
    },

    missingEmptyItemLocation() {
      if (this.isEmptyItemMissingLocation) return
      this.isEmptyItemMissingLocation = true
      this.chosenItemsAction = 'EMPTY_ITEMS'
    },
    itemsLengthWithoutEmptyItems() {
      let itemsLength = 0
      for (let i=0; i < this.itemsCopy.length; i++) {
        if (this.itemsCopy[i].itineraryItemType != 'EMPTY') itemsLength++
      }
      return itemsLength
    },
    async createEmptyItem(index) {
      if (this.loading.emptyItemIndex == index) return
      this.loading.emptyItemIndex = index
      this.newItineraryItem.itineraryItemType = 'EMPTY'
      this.newItineraryItem.itemNumber = this.itemsCopy[index - 1].itemNumber + 1

      await ItineraryService.createItineraryItem(this.itineraryDay.id, this.newItineraryItem).then((result) => {
        this.$store.commit('addItineraryItem', {item: result.data.createItineraryItem, dayId: this.itineraryDay.id})
        this.setActiveItemForm(result.data.createItineraryItem)
        this.setEditLocationItemId(result.data.createItineraryItem.id)
        this.showAlert(this.$t("alerts.emptyItemCreated"))
      })
      .catch(err => {
        console.log(err)
        this.showAlertDanger(this.$t("alerts.emptyItemCreateError"))
      });
    },
    setEditLocationItemId(id, item = null, keepEditable) {
      if (this.editLocationItemId == id && !keepEditable) return this.editLocationItemId = null
      if (item != null) this.setActiveItemForm(item)
      this.editLocationItemId = id
    },
    updateItems(fromCache) {
      if (this.itineraryItems.length == 0) {
        this.$store.commit('createDayItemArray', this.itineraryDay.id)
        this.itemsCopy = this.$store.state.admin.itemForms['day' + this.itineraryDay.id]
      }
      else {
        let itemsCopy = JSON.parse(JSON.stringify(fromCache ? this.itineraryItems : this.$store.state.admin.itemForms['day' + this.itineraryDay.id]))
        itemsCopy.sort((a, b) => {
          if (a.itineraryItemType === "START" || b.itineraryItemType === 'FINISH') return -1;
          else if (b.itineraryItemType === "START" || a.itineraryItemType === 'FINISH') return 1;
          else return a.itemNumber - b.itemNumber;
        })
        
        let itemsWithNumbers = []
        let emptyItemNumber = 0
        let defaultItemsNumber = 0
        for (let i = 0; i < itemsCopy.length; i++) {
            if (itemsCopy[i].itineraryItemType == "START") {
              itemsWithNumbers.push({...itemsCopy[i], itemNumber: 0})
            }
            else if (itemsCopy[i].itineraryItemType == "FINISH") {
              itemsWithNumbers.push({...itemsCopy[i], itemNumber: 1000})
            }
            else if (itemsCopy[i].itineraryItemType == "DEFAULT") {
              defaultItemsNumber += 10
              emptyItemNumber = 0
              itemsWithNumbers.push({...itemsCopy[i], itemNumber: defaultItemsNumber})
            }
            else if (itemsCopy[i].itineraryItemType == "EMPTY") {
              emptyItemNumber += 1
              itemsWithNumbers.push({...itemsCopy[i], itemNumber: defaultItemsNumber + emptyItemNumber})
            }
        }
  
        let copy = []
        for (let i = 0; i < itemsWithNumbers.length; i++) {
          copy.push({...itemsWithNumbers[i], location: itemsWithNumbers[i].location ? itemsWithNumbers[i].location : {longitude: null, latitude: null}})
        }
  
        itemsCopy.forEach(item => {
          item.localizations.sort((a, b) => {
            if (a.language === this.primaryLanguage) return -1;
            else if (b.language === this.primaryLanguage) return 1;
            else if (a.language === this.editedLanguage) return -1;
            else if (b.language === this.editedLanguage) return 1;
            else return 0;
          })
        })
        this.$store.commit('setItemForms', {dayId: this.itineraryDay.id, items: copy})
        this.itemsCopy = this.$store.state.admin.itemForms['day' + this.itineraryDay.id]
      }
    },
    itemIsOpened() {
      this.firstTimeOpened = !this.firstTimeOpened
    },
    chooseItemsAction(action) {
      this.chosenItemsAction = action
    },
    dragStart(event, index) {
      this.draggedIndex = index;
      event.target.parentElement.parentElement.classList.add("dragging")
      const ghostElement = document.createElement("div")
      ghostElement.style.opacity = "0"
      event.dataTransfer.setDragImage(ghostElement, 0, 0);
    },
    dragEnd(event, index) {
      this.itemIsOpened()
      event.target.parentElement.parentElement.classList.remove("dragging")
      this.draggedIndex = null;
    },
    dragOver(event, item, index) {
      // If trying to drag EMPTY item over DEFAULT, START OR FINISH item that has max EMPTY items (9) -> return 
      if (this.itemsCopy[index > this.draggedIndex ? index + 9 : index - 1]?.itemNumber % 10 == 9) return

      if (this.draggedIndex == undefined) return
      event.dataTransfer.effectAllowed = "move"
      event.preventDefault()
      if (this.draggedIndex === null || index === this.draggedIndex) {
        return;
      }
      if (!["START", "FINISH"].includes(item.itineraryItemType)) {      
        const draggedItem = this.itemsCopy[this.draggedIndex];
        this.$store.commit('changeItemPlaces', {dayId: this.itineraryDay.id, draggedIndex : this.draggedIndex, draggedItem, index})
        this.draggedIndex = index;
      }
    },


    closeItemForm() {
      this.activeItemFormId = null
      this.activeItemType = null
      let itemEl = document.querySelector('[aria-controls="' + `collapseItemsList${this.itineraryDay.id}` + '"]')
      itemEl.scrollIntoView({behavior: 'smooth'})
    },

    setActiveItemForm(item, isClickedOnMarker = false) {
      if (item.id != this.activeItemFormId) this.editLocationItemId = null
      let map = this.mapRef ? this.mapRef : this.$refs.map

      let location = this.$store.getters.getItemLocation({dayId: this.itineraryDay.id, itemId: item.id})
      
      // If there is active marker and it isn't this one -> unhighlight it 
      if (this.activeItemFormId != null && this.activeItemFormId != item.id) {
          map.highlightMarker(this.activeItemFormId, false)                        
      }

      if (!map || !location.latitude || !location.longitude) {
        this.activeItemFormId = item.id
        this.activeItemType = item.itineraryItemType
        return
      }
      let coords
      try {
        // eslint-disable-next-line
        coords = SMap.Coords.fromWGS84(location.longitude, location.latitude);
      } catch (error) {
        console.log(error)
        return this.activeItemFormId = item.id
      }
      
      this.activeItemFormId = item.id
      this.activeItemType = item.itineraryItemType
      if (isClickedOnMarker) {
        if (item.itineraryItemType != 'EMPTY') this.chosenItemsAction = 'ITEMS'
        if (item.itineraryItemType == 'EMPTY') this.chosenItemsAction = 'EMPTY_ITEMS'
        this.$nextTick(() => {
          const container = document.getElementById('item-list' + this.itineraryDay.id);
          const element = document.getElementById('item-header' + item.id)
          container.scrollTop = element.offsetTop - container.offsetTop -200;
        })
      }
      map.highlightMarker(item.id, true, coords)
    },
    async createNewItem(type = 'DEFAULT', loading) {
      if (this.loading[loading]) return
      this.loading[loading] = true
      this.newItineraryItem.countries = []
      this.newItineraryItem.countriesObjects = []
      let newItemNumber = 10

      // Create new item with itemNumber following prev item (itemType 'START' has itemNumber 0 and itemType 'FINISH' has itemNumber 1000)
      if (type == 'START') newItemNumber = 0 
      else if (type == 'FINISH') newItemNumber = 1000
      else {        
        for (let i = this.itemsCopy.length - 1; i >= 0; i--) {
          if (this.itemsCopy[i].itineraryItemType === 'DEFAULT') {
            newItemNumber = this.itemsCopy[i].itemNumber + 10
            break;
          }
        }
      }
      this.newItineraryItem.itemNumber = newItemNumber
      this.newItineraryItem.itineraryItemType = type
      // Create new item with countries chosen in prev item
      const itineraryItems = this.$refs.itineraryItem
      if (itineraryItems) {
        for (let i = itineraryItems.length - 1; i >= 0; i--) {
          if (['START', 'DEFAULT'].includes(itineraryItems[i].form.itineraryItemType)) {
            this.newItineraryItem.countries = itineraryItems[i].form.countries
            this.newItineraryItem.countriesObjects = itineraryItems[i].form.countriesObjects
            break;
          }
        }
      }

      // Create new item with the same localizations as the travelTip
      this.newItineraryItem.localizations = this.localizationCodes.map(code => {
        return {
          language: code,
          title: '',
          description: '',
          warning: ''
        }
      })

      await ItineraryService.createItineraryItem(this.itineraryDay.id, this.newItineraryItem).then((result) => {
        this.$store.commit('addItineraryItem', {item: result.data.createItineraryItem, dayId: this.itineraryDay.id})
        this.setActiveItemForm(result.data.createItineraryItem)
        this.setEditLocationItemId(result.data.createItineraryItem.id)
        if (type == 'DEFAULT') this.showAlert(this.$t("alerts.defaultItemCreated"))
        else if (type == 'START') this.showAlert(this.$t("alerts.startItemCreated"))
        else if (type == 'FINISH') this.showAlert(this.$t("alerts.finishItemCreated"))

        this.$nextTick(() => {
          const element = document.getElementById('item-list' + this.itineraryDay.id) ;
          element.scrollTop = type == "START" ? 0 : element.scrollHeight;
        })
      })
      .catch(err => {
        console.log(err)
        this.showAlertDanger(this.$t("alerts.defaultItemCreateError"))
      });
    },

    changeInForm() {
      if (JSON.stringify(this.lastSavedForm) !== JSON.stringify(this.form)) {
        return true
      }
      else {
        const itineraryItemRef = this.$refs.itineraryItem
        if (itineraryItemRef) {
          let changeInForm = false
          itineraryItemRef.forEach(item => {
            if (item.changeInForm() == true) changeInForm = true
          })
          if (changeInForm) return true
        }
      }
      return false
      
    },
    doesTheDayHaveItems() {
      if (!this.itineraryItems || this.itineraryItems.length < 1) return false
      return true
    },
    checkState() {
      if (this.disableInputs) {
        const allInputContainers = document.querySelectorAll(".input-container, .select-container")
        const allInputs = document.querySelectorAll("input, textarea:not(.comment-area), button.dropdown-toggle")
        allInputs.forEach(input => {
          input.setAttribute("disabled", true)
        })
        allInputContainers.forEach(input => {
          input.classList.add("disabled")
        })
      }
    },
    blockInvalidChar(e, maxLength = 50) {
      if (['e', 'E', '+', '-', '.'].includes(e.key) || e.target.value.length == maxLength) e.preventDefault()
  
    },
    resetToDefault() {
      this.form = {
        id: this.itineraryDay.id,
        dayNumber: this.itineraryDay.dayNumber,
        picture: this.itineraryDay.picture?.id,
        localizations: this.itineraryDay.localizations.map(localization => {
          const localizationCopy = {...localization, language: localization.language ? localization.language : 'cs' }
          delete localizationCopy['__typename'];
          return localizationCopy
        })
      }

      this.sortLocalizations()
      this.dayPicture = this.itineraryDay?.picture
      this.lastSavedForm = this.createCopyOfForm(this.form)
    },
    removeErrors() {
      this.isEmptyItemMissingLocation = false
      this.dayNumberError = ''
      this.removeLocalizationInputErrors()
      const itineraryItemRef = this.$refs.itineraryItem
      if (itineraryItemRef) {
        itineraryItemRef.forEach(item => {
          item.removeErrors()
        })
      }
    },

    removeLocalizationInputErrors() {
      for (let key in this.error) {
          this.error[key] = '';
      }
    },

    async validateInputs() {
      let errorCount = 0
      const localizations = this.isManagingLocalizations ? this.form.localizations[1] : this.form.localizations[0]
      this.removeLocalizationInputErrors()
      if (localizations) {
          const { title, description, shortInfo, perex, practicalInfo } = localizations

          if (!title) {
            errorCount += 1
            this.error.title = this.$t("inputErrors.required")
          }
          if (!description) {
            errorCount += 1
            this.error.description = this.$t("inputErrors.required")
          }

          if (errorCount > 0) {
            this.form.localizations[this.isManagingLocalizations ? 1 : 0].translated = false
          }
      }
      if (this.isInCurrentItineraryDayList()) {errorCount += 1}

      this.$root.$emit('addErrorsInForm', errorCount)
      if (errorCount > 0) { 
        const collapseElement = document.querySelector('#collapseDayInfo' + this.itineraryDay.id)
        setTimeout(() => {
          if (!collapseElement.classList.contains('show')) {
            this.$root.$emit('bv::toggle::collapse', 'collapseDayInfo' + this.itineraryDay.id)}
        }, 100);
      }

      const itineraryItemsRef = this.$refs.itineraryItem
      if (itineraryItemsRef) {
        for (const ref of itineraryItemsRef) {
          await ref.validateInputs()
          await new Promise(resolve => setTimeout(resolve, 10))
        }
      }
      
      return errorCount > 0 ? false : true
      },

    onSubmit: async function(validateForms = false) {
      if (!this.form.dayNumber) this.form.dayNumber = 0
      if (!this.itineraryDay.id) {
        await ItineraryDayService.createItineraryDay(this.travelTipId, this.form)
          .then((result) => {
            this.onCreate(result)
          })
          .catch(err => {
            console.log(err)
            this.showAlertDanger(this.$t("alerts.dayCreateError"))
          });
      }
      else {
        await ItineraryDayService.updateItineraryDay(this.travelTipId, this.form, this.itineraryDay.id, this.dayPicture)
          .catch(err => {
            console.log(err)
            this.showAlertDanger(this.$t('alerts.dayUpdateError'))
          });
      }

      const itineraryItemRef = this.$refs.itineraryItem
      if (!itineraryItemRef) return
      // if (itineraryItemRef.length == 1) {
      //   if (itineraryItemRef.changeInForm() === true) {
      //     await itineraryItemRef[0].onSubmit(validateForms)
      //   }
      //   return
      // }
      for (const ref of itineraryItemRef) {
        // ONLY if there is a change in item form -> submit form 
        if (ref.changeInForm()) {
          await ref.onSubmit(validateForms)
        }
      }
    },

    onDeleteConfirmed: async function() {
      this.loadingDelete = this.$t("loading.dayDelete")
      if (this.itineraryItems) {
        for (const itineraryItem of this.itineraryItems) {
          await ItineraryService.deleteItineraryItem(itineraryItem.id)
        }
      }
      await ItineraryDayService.deleteItineraryDay(this.itineraryDay.id, this.travelTipId).catch(() => this.$emit("alertDanger", this.$t("alerts.dayDeleteError")))
      // this.showAlert('Den "' + this.form.name + '" byl úspěšně odstraněn');
      this.loadingDelete = ''
    },

    onCreate: function(result) {
      this.form.id = result.data.createItineraryDay.id
      this.resetToDefault()
    },

    countDownChanged: function(dismissCountDown) {
      this.alert.dismissCountDown = dismissCountDown
    },

    countDownChangedDanger: function(dismissCountDown) {
      this.alertDanger.dismissCountDown = dismissCountDown
    },

    showAlert (notification) {
      for (const key in this.loading) {
        this.loading[key] = false;
      }
      this.$emit('alert', notification)
    },

    showAlertDanger (notification) {
      for (const key in this.loading) {
        this.loading[key] = false;
      }
      this.$emit('alertDanger', notification)
    },

    addItineraryItem(itineraryItem){
      this.resetToDefaultItineraryItem();
      this.$root.$emit('bv::toggle::collapse', 'addItineraryItem' + this.itineraryDay.id);
    },

    resetToDefaultItineraryItem : function(){
      this.newItineraryItem = {
        id: null,
        itineraryItemType: 'DEFAULT',
        title: null,
        duration: null,
        difficulty: null,
        warning:null,
        itemNumber: null,
        description:null,
        countries: [],
        transferTypeValues: [{transferType: '', length: 0}],
        location: {
          latitude: null,
          longitude: null
        },
        itineraryItemPictureIds: [],
        itineraryItemPictures: []
      }
    },

    isInCurrentItineraryDayList: function() {
      this.error.dayNumber = ''
      if (!this.form.dayNumber) {
        this.error.dayNumber = this.$t("inputErrors.required")
        return true
      }
      if (this.form.dayNumber < 1) {
        this.error.dayNumber = this.$t("inputErrors.dayNumberNotNegative")
        return true
      }
      if (this.form.dayNumber.length > 3) {
        this.error.dayNumber = this.$t("inputErrors.dayNumberTooHigh")
        return true
      }
      if (!this.allDays) return
      this.allDays.forEach((itineraryDay) => {
        if (this.form.dayNumber == itineraryDay.dayNumber && this.itineraryDay.id !== itineraryDay.id) {
          this.error.dayNumber = this.$t("inputErrors.dayNumberUnique")
          return true;
        }
      });
      return false;
    },
  },

  apollo: {
    itineraryItems: {
      query: GET_ITINERARY_ITEMS_BY_ITINERARY_DAY_ID,

      variables: function() {
        return {
          itineraryDayId: this.itineraryDay.id,
        }
      },

      skip: function () {
        return this.itineraryDay.id == null;
      },
    },
  },
}
</script>

<style scoped lang="scss">
    @import '@/scss/variables';


.forms-container {
  padding-block: 30px 40px;
  gap: 30px;

  form {
    width: 100%;

    .form-content-container {
      display: flex;
      flex-direction: column;
      gap: 30px;
    }
  }

  &.visible-localizations {
    display: flex;
    flex-wrap: wrap;

    form {
      width: calc(50% - 15px);
    }
  }
}

.icon-with-tooltip-container {
  height: 50px;
  width: 50px;
  cursor: pointer;
  position: absolute;
  z-index: 1;
  top: 0;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.85);

  &:hover {
    background-color: $tr-white;
  }
}

.day-picture-text {
  p {
    font-size: 16px;
    margin: 0
  }

  .warning {
    color: $tr-gray;
  }
}

.img-container {
  margin-top: 20px;
  width: 100%;
  max-width: 330px;
  height: 250px;
  border: dashed 3px $tr-light-gray;
  position: relative;

  &.disabled {
    cursor: not-allowed;
  }

  &.disabled .image-container {
    opacity: 0.7;
    pointer-events: none;
  }

  &.disabled:hover .plus{
    background-color: $tr-black
  }

  .info-icon {
    position: absolute;
    top: 0;
    right: -35px;
  }

  .image-container {
      width: 100%;
      height: 100%;

      .x {
        right: 0;
        position: absolute;
        margin: -10px -10px 0 0;
      }
  }
  &.noborder {
      border: none;
  }
  input {
      display: none;
  }
  .photo {
      width: 100px;
      height: 100px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      border-radius: 50%;
  }
  img {
      width: 100%;
      height: 100%;
      object-fit: cover;
  }
  label {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 1;
      opacity: 0;
      cursor: pointer;
  }
}

.fullscreen-picture-description {
  position: absolute;
  max-width: calc(100% - 40px);
  width: max-content;
  z-index: 1;
  top: -35px;
  left: 50%;
  transform: translateX(-50%);
  background-color: $tr-white-alt;
  padding: 3px 10px;
}
.picture-of-the-day-container {
  figure {
    width: fit-content;
  }

  .day-picture {
    width: 350px;
    height: 100%;
    aspect-ratio: 4/3;
    cursor: pointer;
  }
}

.close-collapse {
  background: none;
  border: none;
  display: flex;
  margin-left: auto;
  
  & .arrow-up {
    display: inline-block;
    width: 25px;
    height: 16px;
    margin-right: 10px;
    background: url("data:image/svg+xml,%3Csvg width='24' height='15' viewBox='0 0 24 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 13L12 3L22 13' stroke='%23222222' stroke-width='3'/%3E%3C/svg%3E%0A");
    background-position: left top;
    background-repeat: no-repeat;
  }
}

.map-with-item-list {
  display: flex;
  margin-top: 30px;
  column-gap: 30px;
}

.day-header {
  cursor: pointer;
  align-items: center;
  height: 80px;
  background-color: $tr-white-alt;
  border-bottom: dashed 3px $tr-light-gray;

  .btn-delete{
    appearance: none;
    border-color: transparent !important;
    background-color: transparent !important;
    background-image: url("data:image/svg+xml,%3Csvg width='50' height='50' viewBox='0 0 50 50' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='25' cy='25' r='25' fill='%23222222'/%3E%3Cg clip-path='url(%23clip0_1668_4909)'%3E%3Cpath d='M17.8579 32.1421L32 18' stroke='white'/%3E%3Cpath d='M32.1421 32.1421L18 18' stroke='white'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_1668_4909'%3E%3Crect width='24' height='24' fill='white' transform='translate(13 13)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
    background-size: 50px 50px;
    background-position: center center;
    background-repeat: no-repeat;
    width: 50px;
    height: 50px;

    &:focus, &:active, &:hover {
      background-color: transparent !important;
    }
  }

  .item-number, .day-number {
    position: relative;
    display: flex;
    align-items: center;
    margin-right: 35px;
    width: 65px;
    text-align: center;

    & > div {
      padding-inline: 2px;
      width: 50px;
    }
    
    & input {
      max-width: 50px;
      font-size: 25px;
      padding-block: 0;
      padding-inline: 2px;
      background-color: $tr-white-alt;
      color: $tr-gray;
      text-align: center;
      border: none;
      border-bottom: solid 2px $tr-gray;

      &.error {
        border-bottom: solid 2px $tr-pink;
        & + span {
          display: block;
        }
      }

    }
    & span {
      cursor: default;
      display: none;
    }
  }

  .dayNumber{
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 25px;
    color: $tr-gray;
    padding-right: 20px;
  }

  .title{
    overflow-x: clip;
    text-overflow: ellipsis;
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 25px;
    color: $tr-black;
    width: 100%;
  }
}

.item-list-container {
  max-width: 600px;
  min-width: 400px;
  flex: 1;
  
  .item-list-functions {
    display: flex;
    height: 50px;
    text-align: center;
    // border-bottom: dashed 3px #c9c8c8;
    border-bottom: 1px solid rgba(0,0,0,.125);
  
    & a {
      width: 100%;
      color: $tr-green;
      margin-bottom: -1px;
      border: 1px solid transparent;
      padding: 0.5rem 1rem;
      cursor: pointer;
      line-height: 32px;

      &.error {
        border-top: 5px solid $tr-pink !important;
      }

      &:hover {
        text-decoration: none;
        border-color: #e9ecef #e9ecef #dee2e6;
      }
      
      &.active {
        color: #495057;
        background-color: $tr-white;
        border-color: #dee2e6 #dee2e6 $tr-white;
      }
    }
  }

  .item-list {
    max-height: 400px;
    overflow: auto;

    &.max-h-500 {
      max-height: 500px;
    }
    &.max-h-450 {
      max-height: 450px;
    }

    & .add-empty-item {
      padding-inline: 20px;

      &.warning-max-amount {
        text-align: center;
        font-weight: 600;
      }

      & p {
        margin-bottom: 0;
        margin-left: 14px;
      }

      & button {
        background-color: $tr-white;
        height: 50px;
        width: 100%;
        border: solid 1px $tr-black;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;
        border: dashed 3px #c9c8c8;

        &:hover {
          border-color: $tr-black;
        }
      }
    }
  }
}

.day-collapse {
  scroll-margin-top: 100px;
}

.collapseArrow{
  display: block;
  position: relative;
  width: 40px;
  height: 20px;
  background-position: left top;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='15' viewBox='0 0 24 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M22 2L12 12L2 2' stroke='%23222222' stroke-width='3'/%3E%3C/svg%3E%0A");
  background-size: 24px 15px;
  margin-left: auto;

  &[aria-expanded="true"], [aria-expanded="true"] &{
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='15' viewBox='0 0 24 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 13L12 3L22 13' stroke='%23222222' stroke-width='3'/%3E%3C/svg%3E%0A");
  }
}

.calendarWithNumber{
  background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='10' cy='7' r='1' fill='%23222222'/%3E%3Ccircle cx='15' cy='7' r='1' fill='%23222222'/%3E%3Ccircle cx='20' cy='7' r='1' fill='%23222222'/%3E%3Ccircle cx='25' cy='7' r='1' fill='%23222222'/%3E%3Ccircle cx='30' cy='7' r='1' fill='%23222222'/%3E%3Cpath d='M35 7L35 35L5 35L5 7' stroke='%23222222'/%3E%3Cpath d='M5 11L35 11' stroke='%23222222'/%3E%3Cpath d='M9 30L31 30' stroke='%23222222'/%3E%3C/svg%3E%0A");
  background-size: 40px 40px;
  background-repeat: no-repeat;
  background-position: center center;
  width: 50px;
  height: 50px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 51px;
  text-align: center;
  color: $tr-black;
  margin: 0 auto;
}


a.days-tab span{
font-size: 25px;
color: $tr-gray;
padding-left: 10px;
}
.basic-info, .days-tab{
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 25px;
  color: $tr-black !important;
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  border-bottom: dashed 3px $tr-light-gray;
  border-top: dashed 3px $tr-light-gray;
  height: 80px;
  cursor: pointer;


  &:hover{
    color: $tr-black;
    text-decoration: none !important;
  }

  &::before{
    content: "";
    position: relative;
    display: inline-block;
    width: 35px;
    height: 20px;
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='17' viewBox='0 0 24 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 5.5L10.5 14L22.5 2' stroke='%233BEA7E' stroke-width='3'/%3E%3C/svg%3E%0A");
    background-position: left top;
    background-repeat: no-repeat;
    background-size: 24px 17px;
  }

  &::after{
    content: "";
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
    background-position: left top;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='15' viewBox='0 0 24 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M22 2L12 12L2 2' stroke='%23222222' stroke-width='3'/%3E%3C/svg%3E%0A");
    background-size: 24px 15px;
    margin-left: auto;
  }

  &[aria-expanded="true"]{
    &::after{
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='15' viewBox='0 0 24 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 13L12 3L22 13' stroke='%23222222' stroke-width='3'/%3E%3C/svg%3E%0A");
    }
  }
}

.add-itinerary-item {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 50px;
  color: $tr-black;
  background-color: $tr-white-alt;
  border: none;
  border-top: dashed 3px #c9c8c8;
  border-bottom: dashed 3px #c9c8c8;

  &.default {
    margin-top: -3px;
  }

  &.only-border-bottom {
    border-top: none;
  }
}

.no-interaction button, .hide-buttons .add-itinerary-item {
  display: none !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

</style>