<template>
    <div>
        <div class="button-with-tag-list">
            <TrekioButton v-if="isAuthorizedToEdit && !isManagingLocalizations" secondary class="mr-4" @click="showModal()">{{ tagIds.length ? $t("buttons.changeTags") + ` (${$tc('admin.tagsChosenOutOfInLanguage', chosenTagsInCurrentLocalization.length, {total: tagIds.length, language: primaryLanguage})})` : $t("buttons.chooseTags") }}</TrekioButton>
            <div class="preview-tag-list">
                <p v-for="tag in chosenTagsInCurrentLocalization" :key="tag.id" class="tag">{{ tag.localizations[0].title }}</p>
            </div>
        </div>
        <b-modal class="modal" id="tags-modal" hide-footer hide-header>
          <div class="px-3">
            <button @click="$bvModal.hide('tags-modal')" class="img-button close-modal"></button>
            <div class="tag-category-container" v-for="category in tagCategories" :key="category.id">
              <p class="tag-category-title">{{ category.localizations[0].title }}</p>
              <div class="tag-list">
                <div class="tag" :class="{active: currentTagIds.includes(tag.id)}" v-for="tag in category.tags" :key="tag.id" @click="addOrRemoveTagId(tag.id)">
                  {{tag.localizations[0].title}}
                </div>
              </div>
            </div>
            <TrekioButton class="my-3" @click="applyChanges">{{ $t("buttons.select") }}</TrekioButton>
          </div>
        </b-modal>
    </div>

</template>

<script>
import { TAG_CATEGORIES_ADMIN } from '../../api/graphql/query/TagQuery';

    export default {
        props: {
            isManagingLocalizations: {
                type: Boolean,
                required: true
            },
            tagIds: {
                type: Array
            },
            primaryLanguage: {
                type: String,
                required: true
            },
            isCurrentUserTipAuthor: {
              type: Boolean
            },
            state: {
              type: String
            }
        },
        data() {
            return {
                currentTagIds: [],
            }
        },
        methods: {
            showModal() {
                this.$bvModal.show('tags-modal')
                this.currentTagIds = [...this.tagIds]

            },
            addOrRemoveTagId(tagId) {
                if (this.currentTagIds.includes(tagId)) {
                    this.currentTagIds = this.currentTagIds.filter(formTagId => formTagId != tagId)
                } else {
                    this.currentTagIds.push(tagId)
                }
            },
            applyChanges() {
                const uniqueToTagIds = this.tagIds.filter(id => !this.currentTagIds.includes(id));
                const uniqueToCurrentTagIds = this.currentTagIds.filter(id => !this.tagIds.includes(id));
                const changedIds = [...uniqueToTagIds, ...uniqueToCurrentTagIds]

                changedIds.forEach(id => {
                    this.$emit('addOrRemoveTagId', id)
                })

                this.$bvModal.hide('tags-modal')
            }

        },

        computed: {
            chosenTagsInCurrentLocalization() {
                if (!this.tagCategories) return []
                const currentLocalizationTags = this.tagCategories.flatMap(cat => cat.tags)
                const tagIdsSet = new Set(this.tagIds);
                return currentLocalizationTags.filter(tag => tagIdsSet.has(tag.id));
            },
            tagCategories() {
                if (!this.tagCategoriesAdmin || this.tagCategoriesAdmin.length == 0) return
                return this.tagCategoriesAdmin.map(category => {
                    return {
                    ...category,
                    localizations: category.localizations.filter(loc => loc.language === this.primaryLanguage && loc.title !== ''),
                    tags: category.tags.map(tag => {
                        return {
                        ...tag,
                        localizations: tag.localizations.filter(loc => loc.language === this.primaryLanguage && loc.title !== '')
                        }
                    }).filter(tag => tag.localizations.length > 0)
                    }
                }).filter(category => {
                    return category.localizations.length > 0 && category.tags.length > 0 
                })
            },
            isAuthorizedToEdit() {
                const userRole = this.$store.state.currentUserRole
                const isSuperuser = userRole === "ADMIN_SUPERVISOR"
                const isAuthorCondition = userRole === "AUTHOR" && [null, 'IN_PROGRESS', 'TO_REWORK'].includes(this.state);
                const isAdminCondition = userRole === "ADMIN" && (
                    !['TO_APPROVE', 'DECLINED'].includes(this.state) ||
                    (this.isCurrentUserTipAuthor && [null, 'IN_PROGRESS', 'TO_REWORK'].includes(this.state))
                );

                return isSuperuser || isAuthorCondition || isAdminCondition;
            },
        },

        apollo: {
            tagCategoriesAdmin: {
                query: TAG_CATEGORIES_ADMIN,
            },
        }
    }


</script>

<style lang="scss" scoped>
    @import "@/scss/variables";

    .button-with-tag-list {
        display: flex;
        align-items: center;
        margin-bottom: 30px;

        .preview-tag-list {
            display: flex;
            gap: 5px;
            height: fit-content;
            flex-wrap: wrap;
    
            .tag {
                border: 1px solid $tr-black;
                padding-inline: 4px;
                background-color: $tr-white-alt;
                font-size: 12px;
                margin-bottom: 0;
            }
        }
    }


    #tags-modal {
    
        .close-modal {
            background-image: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(%23a)"><path d="M4.858 19.142 19 5" stroke="%23222"/><path d="M4.858 19.142 19 5M4.858 19.142 19 5M4.858 19.142 19 5M4.858 19.142 19 5" stroke="%23000" stroke-opacity=".2"/><path d="M19.142 19.142 5 5" stroke="%23222"/><path d="M19.142 19.142 5 5m14.142 14.142L5 5m14.142 14.142L5 5m14.142 14.142L5 5" stroke="%23000" stroke-opacity=".2"/></g><defs><clipPath id="a"><path fill="%23fff" d="M0 0h24v24H0z"/></clipPath></defs></svg>');
            background-size: 30px;
            width: 30px;
            height: 30px;
            background-repeat: no-repeat;
            background-position: center;
            margin-left: auto;
        }

        .tag-category-container {
            margin-bottom: 30px;
        
            .tag-category-title {
            font-size: 20px;
            margin-bottom: 10px;
            }
        
            .tag-list {
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
        
            .tag {
                border: 1px solid $tr-gray;
                background-color: $tr-white;
                padding-inline: 10px;
                cursor: pointer;

                &:hover {
                    background-color: $tr-light-gray;
                }
            
                &.active {
                    background-color: $tr-black;
                    color: $tr-white;
                }
            }
            }
        }
    }

</style>