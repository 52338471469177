<template>
    <b-modal class="modal pl-0" id="difficulty-detail-modal" hide-footer hide-header>
    <div class="modal-container px-md-3 d-flex flex-column justify-content-center mx-auto" style="padding-block: 35px">
        <div @click="$bvModal.hide('difficulty-detail-modal')" class="x black" style="position: absolute; right: 0; top: 0;"></div>
        <h3>{{ $t("difficultyModal.difficulties") }}</h3>
        <p>{{ $t("difficultyModal.difficultyText1") }}</p>
        <p>{{ $t("difficultyModal.difficultyText2") }}</p>
        <br>
        <h5>{{ $t("difficultyModal.lowDifficulty") }}</h5>
        <p>{{ $t("difficultyModal.lowDifficultyText") }}</p>
        <br>
        <h5>{{ $t("difficultyModal.mediumDifficulty") }}</h5>
        <p>{{ $t("difficultyModal.mediumDifficultyText") }}</p>
        <br>
        <h5>{{ $t("difficultyModal.highDifficulty") }}</h5>
        <p>{{ $t("difficultyModal.highDifficultyText") }}</p>
    </div>
    </b-modal>
</template>

<script>

    export default {
        name: "ModalDifficultyInfo",
    }
</script>

<style lang="scss">
    .modal#difficulty-detail-modal {
        padding-inline: 15px !important;

        .modal-dialog {
            max-width: 900px;
            margin-top: 25px;

            .modal-container {
            padding-inline: 0;
            }
        }
    }
</style>