<template>
  <section class="comment-section">
    <div class="comments-title">
      <h3>{{ $t("admin.travelTipComments") }}</h3><span class="comments-length">({{comments.length}})</span>
      <div class="info-icon" v-tooltip.right-start="$t('info.travelTipComments')"></div>
    </div>
    <div class="comments-container">
      <div class="comment" :class="{'align-right': comment.user && comment.user.id == currentUserId}" v-for="comment in comments" :key="comment.date">
        <span v-if="comment.user.id == travelTipAuthor.id" class="owner"></span>
        <span class="author">{{comment.user && comment.user.userName}}</span>
        <div v-if="userRole != 'AUTHOR' && comment.user" class="d-inline">
          <span class="email">{{comment.user.userLogin}}</span>
        </div>
        <span class="date">{{formatDate(comment.createdAt)}}</span>
        <p>{{comment.text}}</p>
      </div>
    </div>
    <div class="tr-dashed-line"></div>
    <form @submit.prevent="onSubmit">
      <TrekioTextarea textareaClass="comment-area" style="margin-bottom: 20px" height="120px" padding="20px" class="" id="comment" :placeholder="$t('admin.writeComment')" v-model="form.text" maxlength="1024"/>
      <TrekioButton type="submit" :isLoading="loading != ''" :loadingText="loading" class="ml-auto" primary>{{ $t("buttons.send")}}</TrekioButton>
    </form>
  </section>
</template>

<script>

import { TRAVEL_TIP_COMMENTS } from '../../api/graphql/query/TravelTipCommentQuery'
import TraveLTipCommentService from "@/services/TravelTipCommentService";

export default {
  props: {
    travelTipId: Number,
    travelTipAuthor: Object
  },

  data() {
    return {
      form: {
        id: null,
        text: '',
        user: '',
        travelTip: null,
      },
      loading: '',
      user: null,
      comments: [],
      editComment: false,
      userRole: null,
    }
  },

  created() {
    this.form.user = this.$store.state.currentUser
    this.form.travelTip = this.travelTipId
    this.userRole = this.$store.state.currentUserRole
  },
  

  watch: {
    '$store.state.currentUser' (nV) {
      this.form.user = nV
      this.userRole = this.$store.state.currentUserRole
    },
  },
  
  computed: {
    currentUserId() {
      return this.$store.state.currentUserId
    }
  },

  methods: {
    async deleteAllComments() {
      if (!this.comments || this.comments.length < 1) return
      for (const comment of this.comments) {
        await TraveLTipCommentService.deleteTravelTipComment(comment.id, this.travelTipId)
      }
    },
    formatDate(date) {
      let moment = require('moment')
      return moment(date).format("D. M. HH:mm")
    },

    validateInputs() {
      return !this.form.text ? false : true
    },
    async onSubmit() {
      if (!this.validateInputs()) return
      if (!this.editComment) {
        this.loading = this.$t("loading.commentCreate")
        await TraveLTipCommentService.createTravelTipComment(this.form)
            .then(() => {
              this.showAlert(this.$t('alerts.commentCreated'))
              this.form.text = ''
            })
            .catch((err) => {
              console.log(err)
              this.showAlertDanger(this.$t('alerts.commentCreateError'))
            })
        
      } else {
        await TraveLTipCommentService.updateTravelTipComment(this.form)
            .then()
            .catch(err => console.log(err))
      }
    },
    showAlert(notification) {
      this.loading = ''
      this.$emit('alert', notification)
    },
    showAlertDanger(notification) {
      this.loading = ''
      this.$emit('alertDanger', notification)
    }
  },

  apollo: {
    comments: {
      query: TRAVEL_TIP_COMMENTS,
      variables: function () {
        return {
          travelTipId: this.travelTipId,
        }
      },
      skip: function () {
        return !this.travelTipId
      },
    },
  }
}
</script>

<style scoped lang="scss">
    @import '@/scss/variables';

.comment-section {
  max-width: 700px;
  width: 100%;
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  
  .comments-title {
    position: relative;
    display: flex;
    gap: 10px;
    margin-bottom: 30px;
    width: max-content;

    span {
      font-size: 1.75rem;
      line-height: 1.2;
      color: $tr-gray;
    }
  
    h3 {
      margin: 0;
    }

    .info-icon {
      position: absolute;
      top: 9px;
      right: -26px;
    }
  }
  
  .comments-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
  
    .owner {
      display: inline-block;
      background-image: url("data:image/svg+xml,%3Csvg height='40' width='40' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 964.07 964.07' xml:space='preserve'%3E%3Cpath d='m850.662 877.56-10.226 1.831c-230.868 41.379-273.337 48.484-278.103 49.037-11.37 1.319-19.864.651-25.976-2.042-3.818-1.682-5.886-3.724-6.438-4.623.268-1.597 2.299-5.405 3.539-7.73 1.207-2.263 2.574-4.826 3.772-7.558 7.945-18.13 2.386-36.521-14.51-47.999-12.599-8.557-29.304-12.03-49.666-10.325-12.155 1.019-225.218 36.738-342.253 56.437l-57.445 45.175c133.968-22.612 389.193-65.433 402.622-66.735 11.996-1.007 21.355.517 27.074 4.4 3.321 2.257 2.994 3.003 2.12 4.997-.656 1.497-1.599 3.264-2.596 5.135-3.835 7.189-9.087 17.034-7.348 29.229 1.907 13.374 11.753 24.901 27.014 31.626 8.58 3.78 18.427 5.654 29.846 5.654 4.508 0 9.261-.292 14.276-.874 9.183-1.065 103.471-17.67 280.244-49.354 5.821-1.043 9.403-1.686 10.169-1.821 9.516-1.688 15.861-10.772 14.172-20.289s-10.766-15.861-20.287-14.171zM231.14 707.501 82.479 863.005a133.826 133.826 0 0 0-33.419 61.338l211.087-166.001c66.081 29.303 118.866 38.637 159.32 38.637 71.073 0 104.065-28.826 104.065-28.826-66.164-34.43-75.592-98.686-75.592-98.686 50.675 21.424 156.235 46.678 156.235 46.678 140.186-93.563 213.45-296.138 213.45-296.138-14.515 3.99-28.395 5.652-41.475 5.652-65.795 0-111-42.13-111-42.13l183.144-39.885C909.186 218.71 915.01 0 915.01 0L358.176 495.258A412.49 412.49 0 0 0 231.14 707.501z'/%3E%3C/svg%3E");
      width: 15px;
      height: 15px;
      margin-right: 5px;
      background-size: 15px;
      background-position: center;
      background-repeat: no-repeat;
    }
    .author {
      position: relative;
      font-weight: 600;
    }
  
    .comment {
      width: fit-content;
      padding: 20px 20px 40px;
      background-color: $tr-white-alt;
      max-width: 550px;
      min-width: 400px;
  
      &.align-right {
        align-self: flex-end;
      }

      .date, .email, .phone-number {
        color: $tr-gray;
        margin-left: 10px
      }
  
      p {
        white-space: pre-wrap;
        word-break: break-word;
        margin-block: 10px 0;
      }
    }
  }
  
  .tr-dashed-line {
    border-bottom: 3px dashed $tr-light-gray;
    margin-block: 40px 20px;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}

</style>
