import {CREATE_ITINERARY_ITEM,UPDATE_ITINERARY_ITEM,DELETE_ITINERARY_ITEM} from "@/api/graphql/mutation/ItineraryMutation";
import {GET_ITINERARY_ITEMS_BY_ITINERARY_DAY_ID} from "@/api/graphql/query/ItineraryItemQuery";
import { apolloClient } from "@/api/apolloClient";

export default{
    createItineraryItem: function(itineraryDayId, form) {
        console.log("Creating new itinerary item with itinerary day id : "+itineraryDayId);
        let {itineraryItemType, duration, difficulty, itemNumber, countries, transferTypeValues, location, itineraryItemPictureIds, localizations} = form;
        if (!location.longitude && !location.latitude) location = null
        if (transferTypeValues.length == 1 && transferTypeValues[0].transferType == '') transferTypeValues = null
        else {
            transferTypeValues.forEach((transfer, index) => {
                if (!transfer.length) transferTypeValues[index].length = 0
            })
        }

        return apolloClient.mutate({
            mutation: CREATE_ITINERARY_ITEM,
            variables: {
                itineraryItemType,
                itineraryDayId,
                duration,
                difficulty,
                itemNumber,
                countries,
                transferTypeValues,
                location,
                itineraryItemPictureIds,
                localizations
            },
            update: async (store, { data: { createItineraryItem } }) => {
                let data = await store.readQuery({ query: GET_ITINERARY_ITEMS_BY_ITINERARY_DAY_ID, variables: {itineraryDayId: itineraryDayId} })
                data = {...data, itineraryItems: [...data.itineraryItems, createItineraryItem]}
                store.writeQuery({ query: GET_ITINERARY_ITEMS_BY_ITINERARY_DAY_ID, variables: {itineraryDayId: itineraryDayId} , data })
              },
        }).then((result) => {
            console.log("Itinerary item created: ", result);
            return result;
        }).catch(err => console.log(err))

    },

    updateItineraryItem: function(itineraryDayId, form) {
        console.log("Updating itinerary item for travel tip is :"+ itineraryDayId, form.id);
        let {id, itineraryItemType, duration, difficulty, itemNumber, location, countries, countriesObjects, transferTypeValues, itineraryItemPictureIds, localizations} = form;
        if (!location.longitude || !location.latitude) location = null
        if (transferTypeValues.length == 1 && transferTypeValues[0].transferType == '') transferTypeValues = null
        else {
            transferTypeValues.forEach((transfer, index) => {
                if (!transfer.length) transferTypeValues[index].length = 0
            })
        }
        
        return apolloClient.mutate({
            mutation: UPDATE_ITINERARY_ITEM,
            variables: {
                id,
                itineraryItemType,
                itineraryDayId,
                duration,
                difficulty,
                itemNumber,
                countries,
                transferTypeValues,
                location,
                itineraryItemPictureIds,
                localizations
            },
            update: async (store) => {
                let data = await store.readQuery({ query: GET_ITINERARY_ITEMS_BY_ITINERARY_DAY_ID, variables: {itineraryDayId: itineraryDayId} })
                data = {...data, itineraryItems: data.itineraryItems.map(item => {
                    if (item.id == form.id) {
                        return {
                            ...item,
                            ...form,
                            location: location ? {...location, __typename: "GeoLocation"} : null,
                            countries: countriesObjects,                            
                        }
                    }
                    return item
                })}
                store.writeQuery({ query: GET_ITINERARY_ITEMS_BY_ITINERARY_DAY_ID, variables: {itineraryDayId: itineraryDayId} , data })
            },
        }).then((result) => {
            console.log("Itinerary item updated: ", result);
            return result;
        }).catch(err => console.log(err))
    },

    deleteItineraryItem: function(itineraryItemId, itineraryDayId) {
        console.log("Deleting itinerary item with id : " + itineraryItemId);
        return apolloClient.mutate({
            mutation: DELETE_ITINERARY_ITEM,
            variables: {
                itineraryItemId
            },
            update: async (store) => {
                    let data = await store.readQuery({ query: GET_ITINERARY_ITEMS_BY_ITINERARY_DAY_ID, variables: {itineraryDayId: itineraryDayId} })
                    data = {...data, itineraryItems: data.itineraryItems.filter(item => item.id !== itineraryItemId)}
                    store.writeQuery({ query: GET_ITINERARY_ITEMS_BY_ITINERARY_DAY_ID, variables: {itineraryDayId: itineraryDayId} , data })
            },
            
        }).then((result) => {
            console.log("Itinerary item deleted: ", result);
            return result;
        });
    },
}
