
import { apolloClient } from "@/api/apolloClient";
import {CREATE_TRAVEL_TIP_COMMENT, UPDATE_TRAVEL_TIP_COMMENT, DELETE_TRAVEL_TIP_COMMENT} from "../api/graphql/mutation/TravelTipCommentMutation";
import {TRAVEL_TIP_COMMENTS} from "@/api/graphql/query/TravelTipCommentQuery";

export default {
    createTravelTipComment: function(form){
        console.log("Start to creating comment by user: "+ form.user);
        const {text, travelTip, placeId} = form;
        return apolloClient.mutate({
            mutation: CREATE_TRAVEL_TIP_COMMENT,
            variables: {
                text,
                travelTip,
                placeId,
            },
            update: async (store,{data:{createTravelTipComment}}) => {
                let data = await store.readQuery({ query: TRAVEL_TIP_COMMENTS, variables: {travelTipId: travelTip}})
                const newDate = new Date()
                data = {
                    ...data,
                    comments: [
                      ...data.comments,
                      createTravelTipComment = {...createTravelTipComment, createdAt: newDate }
                    ],
                  }
                store.writeQuery({ query: TRAVEL_TIP_COMMENTS, variables: {travelTipId: travelTip}, data })
            }
        }).then((result) => {
            console.log("Comment created: ", result);
            return result;
        });
    },

    updateTravelTipComment: function(form){
      console.log("Start to creating comment with ID "+ form.id);
      const {id, text, travelTip, placeId} = form;
      return apolloClient.mutate({
          mutation: UPDATE_TRAVEL_TIP_COMMENT,
          variables: {
            id,
            text,
            travelTip,
            placeId
          },
      }).then((result) => {
          console.log("Comment created: ", result);
          return result;
      });
  },

  deleteTravelTipComment: function(id, travelTipId){
    console.log("Start to deleting comment with ID "+ id);
    return apolloClient.mutate({
        mutation: DELETE_TRAVEL_TIP_COMMENT,
        variables: {
          id,
        },
        update: async (store) => {
        let data = await store.readQuery({ query: TRAVEL_TIP_COMMENTS, variables: {travelTipId: travelTipId}})
        data = {...data, comments: [data.comments.filter(comment => comment.id != id)]}
        store.writeQuery({ query: TRAVEL_TIP_COMMENTS, variables: {travelTipId: travelTipId}, data })
        }
    }).then((result) => {
        console.log("Comment deleted: ", result);
        return result;
    });
},
}