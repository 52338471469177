import gql from "graphql-tag";

export const CREATE_TRAVEL_TIP_COMMENT = gql`
    mutation createTravelTipComment($text: String!, $travelTip: ID, $place: ID) {
        createAdminConsoleComment(text: $text, travelTip: $travelTip, place: $place) {
            id
            text
            user {
                id
                userName
            }
            createdAt
        }
    }`
export const UPDATE_TRAVEL_TIP_COMMENT = gql`
    mutation updateTravelTipComment($id: Int!, $text: String!, $travelTip: ID, $place: ID) {
        updateAdminConsoleComment(id: $id, text: $text, travelTip: $travelTip, place: $place)
    }`
export const DELETE_TRAVEL_TIP_COMMENT = gql`
    mutation deleteTravelTipComment($id: Int!) {
        deleteAdminConsoleComment(id: $id)
    }`
