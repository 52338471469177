<template>
    <b-modal class="modal" v-model="isVisible" hide-footer hide-header no-close-on-backdrop>
        <div class="share-modal-container">
            <button class="close-menu" @click="hideModal"><img src="@/assets/svg/icon-close.svg" alt=""></button>
            <h3>{{ $t("public.shareWithYourFriends") }}</h3>
            <div class="copy-link">
                <input type="text" :value="shareTripUrl" disabled>
                <p @click="copyUrl">{{ $t("public.copyLink") }}</p>
            </div>
            <div class="share-over-socials">
                <h5>{{ $t("public.shareVia") }}</h5>
                <div class="socials">
                    <img class="icon-facebook" @click="shareToFacebook" src="@/assets/svg/icon-facebook.svg" alt="facebook">
                    <img @click="shareToTwitter" src="@/assets/svg/icon-twitter.svg" alt="twitter">
                </div>
            </div>
        </div>
    </b-modal>
</template>

<script>
    export default {
        props: {
            travelTipId: {
                required: true
            },
            isVisible: {
                type: Boolean
            },
            tripTitle: {
                type: String
            }
        },

        methods: {
            hideModal() {
                this.$emit('hideModal')
            },
            openShareWindow(url) {
                window.open(url, '_blank');
                this.hideModal()
            },
            
            shareToFacebook() {
                const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(this.shareTripUrl)}`;
                this.openShareWindow(facebookShareUrl);
            },
            
            shareToTwitter() {
                const tweetText = encodeURIComponent(this.$t("public.checkOutTheTrip", this.tripTitle));
                const twitterShareUrl = `https://twitter.com/intent/tweet?text=${tweetText}&url=${encodeURIComponent(this.shareTripUrl)}`;
                this.openShareWindow(twitterShareUrl);
            },
        

            copyUrl() {
                navigator.clipboard.writeText(this.shareTripUrl)
                this.hideModal()
            },
        },

        computed: {
            shareTripUrl() {
                return window.location.origin + "/#/travelTipDetail/" + this.travelTipId
            },
        }
    }

</script>

<style scoped lang="scss">
    @import '@/scss/variables';

    .share-modal-container {
        padding-inline: 30px;
        padding-block: 30px;

        .close-menu {
            position: absolute;
            width: 50px;
            height: 50px;
            top: 0;
            right: 0;
            padding: 0;
            background-color: transparent;
            border: none;
            z-index: 1;
        }

        p {
            margin: 0;
        }

        .copy-link {
            margin-block: 20px;
            padding-block: 30px;
            display: flex;
            flex-wrap: wrap;
            gap: 15px;
            align-items: center;
            border-top: 1px solid $tr-light-gray;
            border-bottom: 1px solid $tr-light-gray;

            input {
                width: 350px;
                padding: 10px;
            }

            p {
                cursor: pointer;

                &:hover {
                    font-weight: 600;
                }
            }
        }

        .share-over-socials {


            h5 {
                margin-bottom: 20px;
            }

            .socials {
                display: flex;
                flex-wrap: wrap;
                gap: 20px;

                .icon-facebook {
                    padding: 2px;
                }
            }

            img {
                cursor: pointer;
                width: 60px;
                height: 60px;
            }
        }

        @media (max-width: 576px) {
            padding-inline: 0;
        }
    }
</style>