<template>
    <div class="img-card" @click="$emit('onPictureClick', picture)">
          <div class="inner-wrap">
            <figure v-lazyload class="position-relative ratio-four-by-three">
              <div class="loading-circle position-absolute"><div></div><div></div><div></div><div></div></div>
              <img class="img-fluid mb-0" :data-url="imgURL + picture.fileName.slice(0,picture.fileName.lastIndexOf('.')) + '-dimensions-640pxX480px' + picture.fileName.slice(picture.fileName.lastIndexOf('.'))">
            </figure>
            <div class="title-wrapper">
              <p :data-title="chosenLocalization.title">{{chosenLocalization.title}}</p>
            </div>
            <div class="localizations">
                <div class="localization" v-for="localization in sortedLocalizations" :key="localization.language"
                    :class="{'active': chosenLocalizationCode == localization.language, 'original-language': localization.language == picture.originalLanguage}"
                    @click.stop="setChosenLocalization(localization.language)">{{ localization.language }}
                </div>
            </div>
          </div>
        </div>
</template>

<script>
import LazyLoadDirective from '@/components/LazyLoadDirective'

    export default {
        props: {
            picture: Object,
            imgURL: String,
        },

        directives: {
            lazyload: LazyLoadDirective
        },

        data() {
            return {
                chosenLocalizationCode: null,
                sortedLocalizations: null,
            }
        },

        created() {
            const pictureLocalizationLanguages = this.picture.localizations.map(loc => loc.language);
            if (pictureLocalizationLanguages.includes(this.preferredLanguage)) {
                this.chosenLocalizationCode = this.preferredLanguage
            } else if (this.preferredLanguage == 'cs' && pictureLocalizationLanguages.includes('sk')) {
                this.chosenLocalizationCode = 'sk'
            } else {
                this.chosenLocalizationCode = this.picture.originalLanguage
            }


            this.sortedLocalizations = [...this.picture.localizations].sort((a, b) => {
                if (a.language == this.chosenLocalizationCode) return -1
                else if (b.language === this.chosenLocalizationCode) return 1
                else return b.language.localeCompare(a.language)
            })
        },

        methods: {
            setChosenLocalization(languageCode) {
                this.chosenLocalizationCode = languageCode
            },
        },

        computed: {
            preferredLanguage() {
                return this.$store.state.preferredLanguage
            },

            chosenLocalization() {
                return this.picture.localizations.find(localization => localization.language === this.chosenLocalizationCode)
            },
        }
    }

</script>

<style scoped lang="scss">
    @import '@/scss/variables';

    .img-card {
        cursor: pointer;

        &:hover img {
            scale: 1.2;
        }

        .inner-wrap{
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
            background-color: $tr-white;
            padding: 20px 20px 13px 20px;

            figure {
                overflow: hidden;
                aspect-ratio: 4/3;
            }

            img{
                object-fit: cover;
                width: 100%;
                height: 100%;
                margin-bottom: 15px;
                transition: scale 200ms ease-in-out;
            }

            .title-wrapper {
                margin-block: 10px;
                height: 52px;

                p {
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    width: 100%;
                    overflow: hidden;
                    margin-bottom: 0;
                    color: $tr-gray;
                    position: relative;

                    &::after {
                    display: none;
                    position: absolute;
                    left: -20px;
                    top: 0;
                    background-color: $tr-white;
                    content: attr(data-title);
                    width: calc(100% + 40px);
                    padding-inline: 20px;
                    height: -webkit-fit-content;
                    height: -moz-fit-content;
                    height: fit-content;
                    z-index: 2;
                    }

                    &:hover {
                    overflow: visible;
                    
                    &::after {
                        display: block;
                    }
                    }
                }
            }
        }
    }

    .localizations {
        display: flex;
        gap: 10px;
        align-items: center;

        .localization {
        padding: 2px 6px;
        border: 2px solid transparent;
        
        &.active {
            border: 2px solid $tr-black;
            background-color: $tr-black;
            color: $tr-white;
        }
        
        &.original-language {
            border: 2px solid $tr-green;
        }

        &:hover {
            border: 2px solid $tr-black;
        }
        }
    }

</style>