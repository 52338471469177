import gql from "graphql-tag";

export const CREATE_ITINERARY_ITEM = gql`
        mutation createItineraryItem(
            $itineraryItemType: ItineraryItemType
            $itineraryDayId:ID!,
            $duration:Float,
            $difficulty:ItineraryItemDifficulty,
            $itemNumber:Int,
            $countries:[ID],
            $transferTypeValues: [TransferTypeValueInput],
            $location: GeoLocationInput
            $itineraryItemPictureIds:[ID],
            $localizations: [ItineraryItemLocalizationInput]
        ){
            createItineraryItem(
                itineraryItemType: $itineraryItemType,
                itineraryDayId:$itineraryDayId,
                duration:$duration,
                difficulty:$difficulty,
                itemNumber:$itemNumber,
                countries:$countries,
                transferTypeValues: $transferTypeValues,
                location:$location
                itineraryItemPictureIds:$itineraryItemPictureIds,
                localizations: $localizations
            ){
                id
                itineraryItemType
                duration
                difficulty
                itemNumber
                transferTypeValues {
                    transferType
                    length
                }
                location {
                    latitude
                    longitude
                }
                countries {
                    id
                    code
                    nameCzech
                }
                itineraryItemPictures {
                    id
                    fileName
                    localizations {
                        title
                    }
                }
                localizations {
                    language
                    title
                    description
                    warning
                    translated
                }
            }
        }`

export const UPDATE_ITINERARY_ITEM = gql`
        mutation updateItineraryItem(
            $id:ID!,
            $itineraryItemType: ItineraryItemType,
            $itineraryDayId:ID!,
            $duration:Float,
            $difficulty:ItineraryItemDifficulty,
            $itemNumber:Int,
            $countries:[ID],
            $transferTypeValues: [TransferTypeValueInput],
            $location: GeoLocationInput,
            $itineraryItemPictureIds:[ID],
            $localizations: [ItineraryItemLocalizationInput]
        ){
            updateItineraryItem(
                id:$id
                itineraryItemType: $itineraryItemType,
                itineraryDayId:$itineraryDayId,
                duration:$duration,
                difficulty:$difficulty,
                itemNumber:$itemNumber,
                countries:$countries,
                transferTypeValues: $transferTypeValues,
                location:$location
                itineraryItemPictureIds:$itineraryItemPictureIds,
                localizations: $localizations
            )
            {
                id
            }
        }`

export const DELETE_ITINERARY_ITEM = gql`
        mutation deleteItineraryItem($itineraryItemId:Int!){
            deleteItineraryItem(itineraryItemId:$itineraryItemId)
        }`


