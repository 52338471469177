<template>
        <section class="map-with-modal-container" :class="{'modal-map': isMapModalVisible}" style="position: relative"  @click.self="toggleMapModal">
            <div class="map-container">
                <button class="change-color-icon" :class="{'black-and-white': isBlackAndWhite}" @click="colorSwitch"></button>
                <input :id="'map-search-input' + itineraryDay.id" type="text" placeholder="Hledáná fráze" style="width: 330px">
                <button type="button" class="expand-map" @click="toggleMapModal"></button>
                <div class="map-loading unexpected-error" v-if="unexpectedError">{{ $t("loadingErrors.mapUnknown") }}</div>
                <div class="map-loading" v-else-if="isMapLoading">{{ $t("loading.map") }}</div>
                <div class="map-loading" v-else-if="isRouteLoading">{{ $t("loading.calculatingRoute") }}</div>
                <div :id="'map' + itineraryDay.id" :class="{crosshair: editLocationItemId, 'black-and-white': isBlackAndWhite}" class="map map--admin smap"></div>
            </div>
        </section>
</template>

<script>
    export default {
        name: "Map",
        props: ['itineraryItems', 'itineraryDay', 'showMap', 'activeItemFormId', 'editLocationItemId', 'itemsLength', 'disableInputs'],

        data() {
            return {
                isBlackAndWhite: false,
                isMapModalVisible: false,
                unexpectedError: false,
                isMapLoading: false,
                isRouteLoading: false,
                redrawMap: true,
                map: null,
                allCoords: [],
                allMarkers: [],
                debounceTimeout: null,
            }
        },

        created() {
            this.$root.$on('reloadMap' + this.itineraryDay.id, this.resetAllToDefault())            
            this.$root.$on('updateMarkerTitle' + this.itineraryDay.id, this.updateMarkerTitle)            
        },
        mounted() {
            /*eslint-disable*/
            Loader.async = true
            Loader.load(null, null);
            Loader.lang = "cs";
            this.resetAllToDefault()
            /*eslint-enable*/
        },

        watch: {
            disableInputs: {
                immediate: true,
                handler(newVal) {
                    if (newVal == true) {
                        this.allMarkers.forEach(marker => {
                            marker.setDrag(false)
                        })
                    }
                }
            },
            itemsLength(nV, oV) {
                if (nV < oV) {
                    if (this.debounceTimeout) {
                        clearTimeout(this.debounceTimeout);
                    }
                    this.debounceTimeout = setTimeout(() => {
                        this.resetAllToDefault()
                    }, 400);
                }
            },
            showMap(nV) {
                if (nV == null) return 
                else {
                    this.resetAllToDefault()
                }
            }
        },

        methods: {
            colorSwitch() {
                this.isBlackAndWhite = !this.isBlackAndWhite 
            },
            updateMarkerTitle(id, title) {
                if (this.allMarkers._markers && this.allMarkers._markers[id]) {
                    this.allMarkers._markers[id].marker.getContainer()['3'].childNodes[1].innerHTML = title
                }
            },
            highlightMarker(id, isVisible, coords = null, mouseout = false) {
                if (!this.map) return
                if (this.allMarkers._markers && this.allMarkers._markers[id]) {
                    if (mouseout && this.activeItemFormId == id) return
                    this.allMarkers._markers[id].marker.getContainer()['3'].classList[isVisible ? 'add' : 'remove']('active')
                }
                if (coords) {
                    this.map.setCenter(coords, true)                        
                }
            },
            toggleMapModal() {
                if (this.isMapModalVisible == true) {
                    document.body.style.overflow = "auto"
                    this.isMapModalVisible = false;
                }
                else {
                    document.body.style.overflow = "hidden"
                    this.isMapModalVisible = true;
                }
                this.$nextTick(() => {
                    this.map.syncPort()
                })
            },
            resetAllToDefault() {
                this.allMarkers = []
                this.unexpectedError = false
                this.redrawMap = true
                const mapEl = document.getElementById("map" + this.itineraryDay.id)
                if (mapEl) mapEl.replaceChildren();
                this.loadMap()
            },

            createMarker(itineraryItem, isFirstItemTypeStart, index) {
                /*eslint-disable*/
                let _this = this
                let interestPointCoords = SMap.Coords.fromWGS84(itineraryItem.location.longitude, itineraryItem.location.latitude);
                let markerElement = JAK.mel("div", {classList: 'marker-container'}, {cursor: "pointer", marginBottom: itineraryItem.itineraryItemType == 'EMPTY' ? '0' : '18px'});
                markerElement.id = itineraryItem.id

                markerElement.addEventListener("click", function(e) {
                    _this.$emit('setActiveItemForm', itineraryItem, true)
                })
            
                let markerIcon = JAK.mel("div", {classList: 'icon'});
                let markerText
                if (itineraryItem.itineraryItemType == "START") markerIcon.classList.add("start-icon")
                else if (itineraryItem.itineraryItemType == "FINISH") markerIcon.classList.add("finish-icon")
                else if (itineraryItem.itineraryItemType == "EMPTY") {
                    markerIcon = JAK.mel("div", {classList: 'icon empty-icon'}, {position: "absolute", top: "-10px", left: "-2px", width: "20px", height: "20px", border: "2px solid white", backgroundColor: "#222", borderRadius: "50%", filter: "drop-shadow(rgba(0, 0, 0, 0.25) 0px 4px 4px)"});
                }
                else {
                    markerIcon = JAK.mel("div", {classList: 'number-icon'});
                    markerText = JAK.mel("div", {}, {position:"absolute", cursor: 'default',  pointerEvents: "none", left:"0", top:"-17px", textAlign:"center", width:"22px", color:"#222"});
                    markerText.innerHTML = isFirstItemTypeStart ? index : index + 1;
                }
                if (this.activeItemFormId == itineraryItem.id) markerElement.classList.add('active')
                
                // Show item title on mouseover
                JAK.Events.addListener(markerIcon, "mouseover", (e) => this.highlightMarker(itineraryItem.id, true))
                JAK.Events.addListener(markerIcon, "mouseout", (e) => this.highlightMarker(itineraryItem.id, false, null, true))
                
                markerElement.appendChild(markerIcon);

                if (itineraryItem.itineraryItemType != 'EMPTY') {
                    let markerTitle = JAK.mel("div", {classList: 'title'});
                    markerTitle.innerHTML = itineraryItem.localizations[0].title
                    markerElement.appendChild(markerTitle)
                }

                if (typeof(markerText) != "undefined") {
                    markerElement.appendChild(markerText);
                }
                return new SMap.Marker(interestPointCoords, itineraryItem.id, {url: markerElement, anchor: { left: 10, bottom: 1 } })  
                /*eslint-enable*/
            },

            loadMap: async function () {
                this.isMapLoading = true
                this.$store.commit('resetRouteLengths', this.itineraryItems)
                try {
                    /*eslint-disable*/
                    const center = SMap.Coords.fromWGS84(15.459594, 49.965356);
                    this.map = new SMap(JAK.gel('map' + this.itineraryDay.id), this.lastMapCenter ? this.lastMapCenter : center, this.lastMapZoom ? this.lastMapZoom : 7);
                    this.map.setZoomRange(5, 18)
                    this.map.addDefaultLayer(SMap.DEF_TURIST).enable();
                    this.map.addControl(new SMap.Control.Mouse(SMap.MOUSE_PAN | SMap.MOUSE_WHEEL | SMap.MOUSE_ZOOM, {minDriftSpeed:1/0}));
                    this.map.addControl(new SMap.Control.Keyboard(SMap.KB_PAN | SMap.KB_ZOOM, {focusedOnly:false}));
                    this.map.addControl(new SMap.Control.Selection(2));
                    this.map.addControl(new SMap.Control.Scale(), {bottom:"32px",left:"8px"});
                    this.map.addControl(new SMap.Control.Zoom({}, { titles: ["Přiblížit", "Oddálit"], showZoomMenu: false }), {right: "47px", top: "10px"});
                    this.isMapLoading = false

                    let inputEl = document.querySelector("#map-search-input" + this.itineraryDay.id);
                    let suggest = new SMap.Suggest(inputEl);

                    let _this = this
                    suggest.addListener("suggest", function(suggestData) {
                        if (_this.activeItemFormId) {
                            const itemId = _this.activeItemFormId
                            const x = suggestData.data.longitude
                            const y = suggestData.data.latitude

                            _this.$root.$emit(`updateLocation${itemId}`, {longitude: x, latitude: y})
                            _this.$store.commit('setCoords', {coords: {x, y}, dayId: _this.itineraryDay.id, itemId})
                            _this.lastMapCenter = _this.map.getCenter()
                            _this.lastMapZoom = _this.map.getZoom()
                            _this.resetAllToDefault()
                        }
                        _this.map.setCenter(SMap.Coords.fromWGS84(suggestData.data.longitude,suggestData.data.latitude))
                        _this.map.setZoom(12)
                    })

                    function setCoords(signal, drag = false) {
                        if (!_this.disableInputs) {
                            // If user clicks on a map without first clicking on 'choose location from map' icon -> return
                            if (!drag && !_this.editLocationItemId) return

                            let coords = null
                            let itemId = null

                            // Dragging
                            if (drag) {
                                coords = signal.target.getCoords()
                                itemId = signal.target.getId()

                            // Clicking
                            } else {
                                var event = signal.data.event;
                                coords = SMap.Coords.fromEvent(event, _this.map);
                                itemId = _this.editLocationItemId
                            }
                            _this.$root.$emit(`updateLocation${itemId}`, {longitude: coords.x, latitude: coords.y})
                            _this.$store.commit('setCoords', {coords: {x: coords.x, y: coords.y}, dayId: _this.itineraryDay.id, itemId})
                            _this.$emit('setEditLocationItemId', null)
                            _this.lastMapCenter = _this.map.getCenter()
                            _this.lastMapZoom = _this.map.getZoom()
                            _this.resetAllToDefault()
                        }
                    }

                    if (!this.disableInputs) {
                        let signals = this.map.getSignals();
                        signals.addListener(window, "marker-drag-stop", (signal) => setCoords(signal, true));
                        signals.addListener(window, "map-click", (signal) => setCoords(signal))
                    }

                    if (this.itineraryItems.length < 1) return 
                    this.isRouteLoading = true
                    this.redrawMap = false
                    this.allCoords = []

                    let coords = [];
                    let itemIdsWithCoords = [];
                    let interestPointMarkers = [];
                    let itineraryItems = [...this.itineraryItems]
                    itineraryItems.sort((a, b) => {
                        if (a.itineraryItemType === "START" || b.itineraryItemType === 'FINISH') return -1
                        else if (b.itineraryItemType === "START" || a.itineraryItemType === 'FINISH') return 1
                        else return a.itemNumber - b.itemNumber;
                    })
                    let hasPrevItemLocation = true
                    let isFirstItemTypeStart = itineraryItems[0]?.itineraryItemType == "START"
                    let realIndexOfItem = -1
                    for (const [index, itineraryItem] of itineraryItems.entries()) {
                        if (itineraryItem.itineraryItemType !== 'EMPTY') realIndexOfItem += 1
                        if (!itineraryItem.location.longitude || !itineraryItem.location.latitude) {
                            hasPrevItemLocation = false
                            continue
                        }
                        if (hasPrevItemLocation) {
                            const isPreviousItemInTheSameLocation = itineraryItems[index-1]?.location.latitude == itineraryItem.location.latitude && itineraryItems[index-1]?.location.longitude == itineraryItem.location.longitude
                            if (!isPreviousItemInTheSameLocation ) {
                                itemIdsWithCoords.push({type: itineraryItem.itineraryItemType, id: itineraryItem.id, coords: SMap.Coords.fromWGS84(itineraryItem.location.longitude, itineraryItem.location.latitude), transferTypes: itineraryItem.transferTypeValues});
                                coords.push(SMap.Coords.fromWGS84(itineraryItem.location.longitude, itineraryItem.location.latitude));
                            }
                        } else {
                            hasPrevItemLocation = true
                            itemIdsWithCoords.push({type: itineraryItem.itineraryItemType, id: itineraryItem.id, coords: SMap.Coords.fromWGS84(itineraryItem.location.longitude, itineraryItem.location.latitude), transferTypes: itineraryItem.transferTypeValues});
                            coords.push(SMap.Coords.fromWGS84(itineraryItem.location.longitude, itineraryItem.location.latitude));
                        }
                        const marker = this.createMarker(itineraryItem, isFirstItemTypeStart, realIndexOfItem)
                        if (!this.disableInputs) {
                            marker.decorate(SMap.Marker.Feature.Draggable);
                        }
                        interestPointMarkers.push(marker);
                    }

                    coords.forEach(coord => this.allCoords.push(coord))

                    this.allMarkers = new SMap.Layer.Marker()
                    this.map.addLayer(this.allMarkers).enable();

                    for (const interestPoint of interestPointMarkers) {
                        this.allMarkers.addMarker(interestPoint);
                    }

                    if (coords.length > 1) {
                        let finalCoordsToCall = []
                        for (let i = 0; i < itemIdsWithCoords.length - 1; i++) {
                            finalCoordsToCall.push(itemIdsWithCoords[i].coords);
                            // If next item is 'empty item' -> skip creating route. 
                            if (itemIdsWithCoords[i + 1].type == "EMPTY") continue
                            else finalCoordsToCall.push(itemIdsWithCoords[i + 1].coords);

                            let criterion = 'turist1'
                            if (itemIdsWithCoords[i + 1].transferTypes) {
                                criterion = this.getRouteCriterion(itemIdsWithCoords, i)
                            }
                            if (criterion == 'vector') {
                                const vectorLength = (finalCoordsToCall[0].distance(finalCoordsToCall[finalCoordsToCall.length - 1]) / 1000).toFixed(3) + "km"
                                this.$store.commit('setItemRouteLength', {dayId: this.itineraryDay.id, itemId: itemIdsWithCoords[i + 1].id, routeLength: vectorLength})
                                this.createGeometry(finalCoordsToCall)
                                finalCoordsToCall = []
                                continue
                            }

                            await SMap.Route.route(finalCoordsToCall, {
                                geometry: true,
                                criterion: criterion
                            }).then((route) => this.mapRouteFound(this.map, route, finalCoordsToCall, itemIdsWithCoords[i + 1].id))
                            finalCoordsToCall = []
                        }
                        this.$root.$emit('setRouteLengths' + this.itineraryDay.id)
                    }
                    
                    if (!this.lastMapCenter) {
                        let centerZoom = this.map.computeCenterZoom(this.allCoords);
                        this.map.setCenterZoom(centerZoom[0], centerZoom[1]);
                    }
                    this.isRouteLoading = false
                } catch (error) {
                    console.log(error)
                    this.unexpectedError = true            
                }
                /*eslint-enable*/
            },

            mapRouteFound: function (map, route, originalCoords, id) {
                /*eslint-disable*/
                let coords = route.getResults().geometry;
                const routeLength = route.getResults().length / 1000 + "km"
                this.$store.commit('setItemRouteLength', {dayId: this.itineraryDay.id, itemId: id, routeLength})
                console.log(route.getResults().length / 1000 + "km")
                this.createGeometry(coords ? coords : originalCoords)
                /*eslint-enable*/
            },

            createGeometry(coords) {
                /*eslint-disable*/
                const geometryLayer = new SMap.Layer.Geometry()
                this.map.addLayer(geometryLayer).enable();

                const options = {
                    color: "#3BEA7E",
                    minDist: 8,
                    opacity: 1,
                    outlineColor: "white",
                    outlineOpacity: 1,
                    outlineWidth: 1,
                    outlineStyle: JAK.Vector.STYLE_SOLID,
                    width: 5,
                    style: JAK.Vector.STYLE_SOLID
                }
                try {
                    let geometry = new SMap.Geometry(SMap.GEOMETRY_POLYLINE, null, coords, options);
                    geometryLayer.addGeometry(geometry)
                } catch (error) {
                    console.log(error)
                }
                /*eslint-enable*/
            },

            getRouteCriterion(items, index) {
                const nextItemHasOnlyOneTransferType = items[index + 1].transferTypes && items[index + 1].transferTypes.length == 1
                if (nextItemHasOnlyOneTransferType) {
                    const transferType = items[index + 1].transferTypes[0].transferType
                    if (transferType == 'WALK') return 'turist1'
                    else if (transferType == 'CAR') return 'fast'
                    else if (['BIKE', 'SCOOTER'].includes(transferType)) return 'bike1'
                    else if (transferType == 'ROLLER_SCATE') return 'bike2'
                    else if (['BUS', 'CABLE_CAR', 'FLIGHT', 'BOAT', 'PUBLIC_TRANSPORT', 'TRAIN'].includes(transferType)) return 'vector'
                }
                else {
                    const nextItemTransferTypes = items[index + 1].transferTypes.map(transfer => transfer.transferType)
                    if (nextItemTransferTypes.every(type => type == 'WALK')) return 'turist1'
                    else if (nextItemTransferTypes.every(type => type == 'CAR')) return 'fast'
                    else if (nextItemTransferTypes.every(type => ['BIKE', 'SCOOTER'].includes(type))) return 'bike1'
                    else if (nextItemTransferTypes.every(type => type == 'ROLLER_SCATE')) return 'bike2'
                    else if (nextItemTransferTypes.every(type => ['BUS', 'CABLE_CAR', 'FLIGHT', 'BOAT', 'PUBLIC_TRANSPORT', 'TRAIN'].includes(type))) return 'vector'
                    else return 'turist1'
                }
            },
        }
    }
</script>

<style scoped lang="scss">
    @import '@/scss/variables';

.no-interaction .map-container input {
    display: none !important;
}

.test-icon {
    position: absolute;
    z-index: 2;
    top: 9.5px;
    right: 156px;
    width: 80px;
    height: 27px;
    background-color: $tr-white;
    padding: 0;
}

.change-color-icon {
    position: absolute;
    z-index: 2;
    top: 9.5px;
    right: 121px;
    width: 27px;
    height: 27px;
    background-position: center;
    background-size: 25px;
    background-color: $tr-white;
    background-repeat: no-repeat;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.65) !important;
    border: none;

    &::before, &::after {
        content: "";
        width: 13px;
        height: 13px;
        border-radius: 50%;
        position: absolute;
    }
    &::after {
        top: 4px;
        left: 4px;
        background-color: gray;
    }
    &::before {
        bottom: 4px;
        right: 4px;
        background-color: rgb(88, 88, 88);
    }

    &.black-and-white::before {
        background-color: #CC0000;
    }
    &.black-and-white::after {
        background-color: rgb(252, 252, 21);
    }
}

.expand-map {
  position: absolute;
  z-index: 2;
  top: 9.5px;
  right: 10px;
  width: 27px;
  height: 27px;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAACXBIWXMAAAsTAAALEwEAmpwYAAABGklEQVR4nO3awWnFQBQEQcXy8s/RjsD4YER5+F2w9x5W0knPkyRJkiRJkvzg7r7ePM/L1vvnB9x4//yAG++fH3Dj/fMDbrx/fsCN988PuPH++QE33v/wAIzv5wEY388DML6fB2B8Pw/A+H4egPH9PADj+3kAxvfzAIzv5wEY388DML6fB2B8Pw/A+H4egPH9PADj+3kA9un7kyRJkiRJkiRJkiRJkrzg039MOr2fB2B8Pw/A+H4egPH9PADj+3kAxvfzAIzv5wEY388DML6fB2B8Pw/A+H4egPH9PADj+3kAxvfzAIzv5wEY3/9bwF/PU38XcP/4AeoNuC7goz+h8wNuvH9+wI33zw+48f75ATfenyRJkiRJkmfWN0ZS+VuC+/Q+AAAAAElFTkSuQmCC');
  background-position: center;
  background-size: 25px;
  background-color: $tr-white;
  background-repeat: no-repeat;
  box-shadow: 0 0 2px 0 rgb(0 0 0 / 65%) !important;
  border: none;

  &:hover {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAACXBIWXMAAAsTAAALEwEAmpwYAAABHklEQVR4nO3awU3EQBREQef1cyNgkoAIEAe0Klr7Spr7a43tk58nSZIkSZIk+cF9fny98jwvtt4/P+DG++cH3Hj//IAb758fcOP98wNuvH9+wI33zw+48f6HB2B8Pw/A+H4egPH9PADj+3kAxvfzAIzv5wEY388DML6fB2B8Pw/A+H4egPH9PADj+3kAxvfzAIzv5wEY388DsHffnyRJkiRJkiRJkiRJkuQF3v3HpNP7eQDG9/MAjO/nARjfzwMwvp8HYHw/D8D4fh6A8f08AOP7eQDG9/MAjO/nARjfzwMwvp8HYHw/D8D4fh6A8f2/Bfz1PPV3AfePH6DegM8u4K0/ofMDbrx/fsCN988PuPH++QE33p8kSZIkSZJn1jdxlSrEApsFfAAAAABJRU5ErkJggg==');
  }
}

.map-with-modal-container {
  position: relative;
  touch-action: none;
  max-width: 1000px;
  width: 100%;
  height: 600px;
  display: flex;
  flex-direction: column;

  .map-container {
    height: 100%;
    width: 100%;

    & .map {
        height: 100%;
        width: 100%;
        background-color: $tr-white;

        &.crosshair {
            cursor: crosshair;
        }
        }
  }

  input {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 2;
    height: 27px;
    box-shadow: 0 0 2px 0 rgb(0 0 0 / 65%) !important;
    border: none;
    padding-inline: 5px;
  }

  &.modal-map {
    padding: 50px;
    position: fixed !important;
    width: 100%;
    max-width: 100%;
    height: 100vh;
    z-index: 1032;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.45);
    display: flex;

    .map-container {
      position: relative;
      max-width: 1500px;
      max-height: 750px;
      max-height: 90%;
      margin: auto;

      .expand-map {
        background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13 5v8H5m30 0h-8V5m0 30v-8h8M5 27h8v8' stroke='%23000' stroke-width='3'/%3E%3C/svg%3E");
        background-size: 27px;

        &:hover {
          background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13 5v8H5m30 0h-8V5m0 30v-8h8M5 27h8v8' stroke='%233BEA7E' stroke-width='3'/%3E%3C/svg%3E");
        }
      }

    }
}

  & .map-loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: $tr-white;
    padding: 5px 10px;
    z-index: 5;
    width: max-content;
    max-width: 100%;
    text-align: center;
  }
}


</style>

<style lang="scss">
    @import '@/scss/variables';

.map--admin.smap {
    .zoom .button-group button {
        color: $tr-black !important;
        border-radius: 0 !important;

        &:hover {
        color: $tr-green !important;
        background-color: $tr-white !important;
        }
    }

    &.black-and-white {
        img[src*="//api.mapy.cz/v"] {
            filter: grayscale(100%)  brightness(1.1) contrast(1.05);
        }
        polyline {
            stroke: $tr-green !important;
        }

        .title {
            background-color: $tr-green !important;
        }
        
        .marker-container {
            .number-icon {
                background-image: url('data:image/svg+xml,<svg width="15" height="20" viewBox="0 0 15 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.5 7.47c0 .906-.42 2.116-1.117 3.47-.687 1.338-1.61 2.753-2.54 4.051A62.771 62.771 0 0 1 7.5 19.239a62.756 62.756 0 0 1-3.344-4.248c-.93-1.298-1.852-2.713-2.54-4.051C.922 9.586.5 8.376.5 7.47.5 3.623 3.632.5 7.5.5s7 3.123 7 6.97Z" fill="%233BEA7E" stroke="%23fff"/></svg>');            }
            .start-icon {
                background-image: url('data:image/svg+xml,<svg width="30" height="40" viewBox="0 0 30 40" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M29 14.94c0 1.813-.841 4.232-2.233 6.94-1.375 2.675-3.22 5.505-5.08 8.102a125.386 125.386 0 0 1-5.115 6.626c-.635.771-1.174 1.407-1.572 1.87-.398-.464-.937-1.099-1.572-1.87a125.4 125.4 0 0 1-5.115-6.626c-1.86-2.597-3.705-5.427-5.08-8.102C1.841 19.172 1 16.753 1 14.94 1 7.245 7.264 1 15 1s14 6.245 14 13.94Z" fill="%233BEA7E" stroke="%23fff" stroke-width="2"/><path d="m11 16.5 10.292-3.958L11 8.583V16.5ZM11 7v19" stroke="%23222"/></svg>');
            }
            .finish-icon {
                background-image: url('data:image/svg+xml,<svg width="30" height="40" viewBox="0 0 30 40" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M29 14.94c0 1.813-.841 4.232-2.233 6.94-1.375 2.675-3.22 5.505-5.08 8.102a125.386 125.386 0 0 1-5.115 6.626c-.635.771-1.174 1.407-1.572 1.87-.398-.464-.937-1.099-1.572-1.87a125.4 125.4 0 0 1-5.115-6.626c-1.86-2.597-3.705-5.427-5.08-8.102C1.841 19.172 1 16.753 1 14.94 1 7.245 7.264 1 15 1s14 6.245 14 13.94Z" fill="%233BEA7E" stroke="%23fff" stroke-width="2"/><path d="M23.25 8.583V16.5H9V8.583h14.25ZM9 7v19" stroke="%23222"/><path fill="%23222" d="M9 8.583h2.375v1.583H9zm2.375 1.584h2.375v1.583h-2.375zM9 11.75h2.375v1.583H9zm2.375 1.583h2.375v1.583h-2.375zM9 14.917h2.375V16.5H9zm4.75-6.334h2.375v1.583H13.75zm2.375 1.584H18.5v1.583h-2.375zM13.75 11.75h2.375v1.583H13.75zm2.375 1.583H18.5v1.583h-2.375zm-2.375 1.584h2.375V16.5H13.75zm4.75-6.334h2.375v1.583H18.5zm2.375 1.584h2.375v1.583h-2.375zM18.5 11.75h2.375v1.583H18.5zm2.375 1.583h2.375v1.583h-2.375zM18.5 14.917h2.375V16.5H18.5z"/></svg>');
            }
        }

        .marker-container.active {
            .number-icon {
                background-image: url('data:image/svg+xml,<svg width="15" height="20" viewBox="0 0 15 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.5 7.47c0 .906-.42 2.116-1.117 3.47-.687 1.338-1.61 2.753-2.54 4.051A62.771 62.771 0 0 1 7.5 19.239a62.756 62.756 0 0 1-3.344-4.248c-.93-1.298-1.852-2.713-2.54-4.051C.922 9.586.5 8.376.5 7.47.5 3.623 3.632.5 7.5.5s7 3.123 7 6.97Z" fill="%233BEA7E" stroke="%23222"/></svg>');
            }
            .start-icon {
                background-image: url('data:image/svg+xml,<svg width="30" height="40" viewBox="0 0 30 40" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M29 14.94c0 1.813-.841 4.232-2.233 6.94-1.375 2.675-3.22 5.505-5.08 8.102a125.386 125.386 0 0 1-5.115 6.626c-.635.771-1.174 1.407-1.572 1.87-.398-.464-.937-1.099-1.572-1.87a125.4 125.4 0 0 1-5.115-6.626c-1.86-2.597-3.705-5.427-5.08-8.102C1.841 19.172 1 16.753 1 14.94 1 7.245 7.264 1 15 1s14 6.245 14 13.94Z" fill="%233BEA7E" stroke="%23222" stroke-width="2"/><path d="m11 16.5 10.292-3.958L11 8.583V16.5ZM11 7v19" stroke="%23222"/></svg>');
            }
            .finish-icon {
                background-image: url('data:image/svg+xml,<svg width="30" height="40" viewBox="0 0 30 40" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M29 14.94c0 1.813-.841 4.232-2.233 6.94-1.375 2.675-3.22 5.505-5.08 8.102a125.386 125.386 0 0 1-5.115 6.626c-.635.771-1.174 1.407-1.572 1.87-.398-.464-.937-1.099-1.572-1.87a125.4 125.4 0 0 1-5.115-6.626c-1.86-2.597-3.705-5.427-5.08-8.102C1.841 19.172 1 16.753 1 14.94 1 7.245 7.264 1 15 1s14 6.245 14 13.94Z" fill="%233BEA7E" stroke="%23222" stroke-width="2"/><path d="M23.25 8.583V16.5H9V8.583h14.25ZM9 7v19" stroke="%23222"/><path fill="%23222" d="M9 8.583h2.375v1.583H9zm2.375 1.584h2.375v1.583h-2.375zM9 11.75h2.375v1.583H9zm2.375 1.583h2.375v1.583h-2.375zM9 14.917h2.375V16.5H9zm4.75-6.334h2.375v1.583H13.75zm2.375 1.584H18.5v1.583h-2.375zM13.75 11.75h2.375v1.583H13.75zm2.375 1.583H18.5v1.583h-2.375zm-2.375 1.584h2.375V16.5H13.75zm4.75-6.334h2.375v1.583H18.5zm2.375 1.584h2.375v1.583h-2.375zM18.5 11.75h2.375v1.583H18.5zm2.375 1.583h2.375v1.583h-2.375zM18.5 14.917h2.375V16.5H18.5z"/></svg>');
            }
        }
    }

    .marker-container {
        & .title {
            position: absolute;
            z-index: 1;
            display: none;
            top: -17px;
            left: 32px;
            width: max-content;
            line-height: 20px;
            padding: 4px 10px;
            height: 28px;
            background-color: #ff4b4b;
            filter: drop-shadow(rgba(0, 0, 0, 0.25) 0px 4px 4px);
    
            &:empty {
                padding: 0;
            }
        }
    
        & .number-icon, & .start-icon, & .finish-icon {
            position: absolute;
            width: 30px;
            height: 40px;
            top: -22px;
            left: -4px;
            filter: drop-shadow(rgba(0, 0, 0, 0.25) 0px 4px 4px);
            background-repeat: no-repeat;
            background-size: 30px 40px;
        }
        & .number-icon {
            background-image: url('data:image/svg+xml,<svg width="15" height="20" viewBox="0 0 15 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.5 7.47c0 .906-.42 2.116-1.117 3.47-.687 1.338-1.61 2.753-2.54 4.051A62.771 62.771 0 0 1 7.5 19.239a62.756 62.756 0 0 1-3.344-4.248c-.93-1.298-1.852-2.713-2.54-4.051C.922 9.586.5 8.376.5 7.47.5 3.623 3.632.5 7.5.5s7 3.123 7 6.97Z" fill="%23FF4B4B" stroke="%23fff"/></svg>')
        }
        & .start-icon {
            background-image: url('data:image/svg+xml,<svg width="30" height="40" viewBox="0 0 30 40" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M29 14.94c0 1.813-.841 4.232-2.233 6.94-1.375 2.675-3.22 5.505-5.08 8.102a125.386 125.386 0 0 1-5.115 6.626c-.635.771-1.174 1.407-1.572 1.87-.398-.464-.937-1.099-1.572-1.87a125.4 125.4 0 0 1-5.115-6.626c-1.86-2.597-3.705-5.427-5.08-8.102C1.841 19.172 1 16.753 1 14.94 1 7.245 7.264 1 15 1s14 6.245 14 13.94Z" fill="%23FF4B4B" stroke="%23fff" stroke-width="2"/><path d="m11 16.5 10.292-3.958L11 8.583V16.5ZM11 7v19" stroke="%23222"/></svg>');
        }
        & .finish-icon {
            background-image: url('data:image/svg+xml,<svg width="30" height="40" viewBox="0 0 30 40" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M29 14.94c0 1.813-.841 4.232-2.233 6.94-1.375 2.675-3.22 5.505-5.08 8.102a125.386 125.386 0 0 1-5.115 6.626c-.635.771-1.174 1.407-1.572 1.87-.398-.464-.937-1.099-1.572-1.87a125.4 125.4 0 0 1-5.115-6.626c-1.86-2.597-3.705-5.427-5.08-8.102C1.841 19.172 1 16.753 1 14.94 1 7.245 7.264 1 15 1s14 6.245 14 13.94Z" fill="%23FF4B4B" stroke="%23fff" stroke-width="2"/><path d="M23.25 8.583V16.5H9V8.583h14.25ZM9 7v19" stroke="%23222"/><path fill="%23222" d="M9 8.583h2.375v1.583H9zm2.375 1.584h2.375v1.583h-2.375zM9 11.75h2.375v1.583H9zm2.375 1.583h2.375v1.583h-2.375zM9 14.917h2.375V16.5H9zm4.75-6.334h2.375v1.583H13.75zm2.375 1.584H18.5v1.583h-2.375zM13.75 11.75h2.375v1.583H13.75zm2.375 1.583H18.5v1.583h-2.375zm-2.375 1.584h2.375V16.5H13.75zm4.75-6.334h2.375v1.583H18.5zm2.375 1.584h2.375v1.583h-2.375zM18.5 11.75h2.375v1.583H18.5zm2.375 1.583h2.375v1.583h-2.375zM18.5 14.917h2.375V16.5H18.5z"/></svg>');
        }
        
        &.active {
            .number-icon {
                background-image: url('data:image/svg+xml,<svg width="15" height="20" viewBox="0 0 15 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.5 7.47c0 .906-.42 2.116-1.117 3.47-.687 1.338-1.61 2.753-2.54 4.051A62.771 62.771 0 0 1 7.5 19.239a62.756 62.756 0 0 1-3.344-4.248c-.93-1.298-1.852-2.713-2.54-4.051C.922 9.586.5 8.376.5 7.47.5 3.623 3.632.5 7.5.5s7 3.123 7 6.97Z" fill="%23FF4B4B" stroke="%23222"/></svg>')
            }
            .start-icon {
                background-image: url('data:image/svg+xml,<svg width="30" height="40" viewBox="0 0 30 40" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M29 14.94c0 1.813-.841 4.232-2.233 6.94-1.375 2.675-3.22 5.505-5.08 8.102a125.386 125.386 0 0 1-5.115 6.626c-.635.771-1.174 1.407-1.572 1.87-.398-.464-.937-1.099-1.572-1.87a125.4 125.4 0 0 1-5.115-6.626c-1.86-2.597-3.705-5.427-5.08-8.102C1.841 19.172 1 16.753 1 14.94 1 7.245 7.264 1 15 1s14 6.245 14 13.94Z" fill="%23FF4B4B" stroke="%23222" stroke-width="2"/><path d="m11 16.5 10.292-3.958L11 8.583V16.5ZM11 7v19" stroke="%23222"/></svg>');
            }
            .finish-icon {
                background-image: url('data:image/svg+xml,<svg width="30" height="40" viewBox="0 0 30 40" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M29 14.94c0 1.813-.841 4.232-2.233 6.94-1.375 2.675-3.22 5.505-5.08 8.102a125.386 125.386 0 0 1-5.115 6.626c-.635.771-1.174 1.407-1.572 1.87-.398-.464-.937-1.099-1.572-1.87a125.4 125.4 0 0 1-5.115-6.626c-1.86-2.597-3.705-5.427-5.08-8.102C1.841 19.172 1 16.753 1 14.94 1 7.245 7.264 1 15 1s14 6.245 14 13.94Z" fill="%23FF4B4B" stroke="%23222" stroke-width="2"/><path d="M23.25 8.583V16.5H9V8.583h14.25ZM9 7v19" stroke="%23222"/><path fill="%23222" d="M9 8.583h2.375v1.583H9zm2.375 1.584h2.375v1.583h-2.375zM9 11.75h2.375v1.583H9zm2.375 1.583h2.375v1.583h-2.375zM9 14.917h2.375V16.5H9zm4.75-6.334h2.375v1.583H13.75zm2.375 1.584H18.5v1.583h-2.375zM13.75 11.75h2.375v1.583H13.75zm2.375 1.583H18.5v1.583h-2.375zm-2.375 1.584h2.375V16.5H13.75zm4.75-6.334h2.375v1.583H18.5zm2.375 1.584h2.375v1.583h-2.375zM18.5 11.75h2.375v1.583H18.5zm2.375 1.583h2.375v1.583h-2.375zM18.5 14.917h2.375V16.5H18.5z"/></svg>');
            }
        }
    }


    & polyline {
        stroke: #ff4b4b;
    }

    & polyline:first-child {
        stroke: white !important;
    }
    & .start-icon {
        background-image: url("data:image/svg+xml,%3Csvg width='34' height='34' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23a)'%3E%3Cpath d='m16 22 13-5-13-5v10Zm0-12v24' stroke='%23222'/%3E%3Ccircle cx='25' cy='33' r='1' fill='%23222'/%3E%3Ccircle cx='22' cy='33' r='1' fill='%23222'/%3E%3Ccircle cx='19' cy='33' r='1' fill='%23222'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath fill='%23fff' d='M0 0h40v40H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");}
    & .finish-icon {
        background-image: url("data:image/svg+xml,%3Csvg width='34' height='34' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23a)'%3E%3Cpath d='M34 12v10H16V12h18Zm-18-2v24' stroke='%23222'/%3E%3Ccircle cx='13' cy='33' r='1' fill='%23222'/%3E%3Ccircle cx='10' cy='33' r='1' fill='%23222'/%3E%3Ccircle cx='7' cy='33' r='1' fill='%23222'/%3E%3Cpath fill='%23222' d='M16 12h3v2h-3zm3 2h3v2h-3zm-3 2h3v2h-3zm3 2h3v2h-3zm-3 2h3v2h-3zm6-8h3v2h-3zm3 2h3v2h-3zm-3 2h3v2h-3zm3 2h3v2h-3zm-3 2h3v2h-3zm6-8h3v2h-3zm3 2h3v2h-3zm-3 2h3v2h-3zm3 2h3v2h-3zm-3 2h3v2h-3z'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath fill='%23fff' d='M0 0h40v40H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
    }

    & svg {
        filter: drop-shadow(rgba(0, 0, 0, 0.25) 0px 4px 4px);
    }

    
}

.smap-suggest {
    position: absolute;
    max-width: 330px;
    top: 37px;
    left: 10px;
  }

.smap .noprint a, .smap .noprint div {
    z-index: 50;
}
.smap div.active {
    z-index: 20;

    .empty-icon {
        border: 2px solid #222 !important;
    }

    .title {
        display: block !important;
    }
}
</style>
