<template>
    <div class="item" :id="'item-header' + item.id" :class="{'m-block-15': type != 'EMPTY' && chosenItemsAction == 'EMPTY_ITEMS'}">
      <div v-if="type != 'EMPTY'" class="item-header" :class="{error: $store.state.admin.errorIds.includes(item.id), active: activeItemFormId == item.id , 'border-top-dashed': chosenItemsAction == 'EMPTY_ITEMS'}" :key="item.id" @dragover.prevent="$emit('dragOver', $event, item, index)">
        <div class="row align-items-center w-100 m-0" @click="$emit('setActiveItemForm', item)">
            <div class="col-2 p-0 text-center" :draggable="['START', 'FINISH'].includes(type) || chosenItemsAction !== 'ITEMS' ? false : true" @dragstart="$emit('dragStart', $event, index, item.id)" @dragend="$emit('dragEnd', $event, index)">
              <div v-if="!isManagingLocalizations && chosenItemsAction == 'ITEMS'" class="mx-auto" :class="{'sort-icon':  !['START', 'FINISH'].includes(type)}" ></div>
            </div>
            <div class="col-8 p-0 d-flex align-items-center" >
              <div v-if="type == 'START'" class="itinerary-item-icon start-icon"></div>
              <div v-else-if="type == 'FINISH'" class="itinerary-item-icon finish-icon"></div>
              <div v-else class="itinerary-item-icon"></div>
              <p class="item-number">{{ item.itemNumber == 0 || item.itemNumber == 1000 ? '' : item.itemNumber / 10 }}</p>
            <span style="white-space: nowrap;" class="title">{{ primaryLocalization && primaryLocalization.title }}</span>
            </div>
            <div class="col-1 p-0"><b-button v-if="!isManagingLocalizations && item.id" @click.stop="" v-b-modal="'modal-delete-itineraryItem'+ item.id" class="btn-delete"></b-button></div>
        </div>
      </div>
      <div v-if="!isManagingLocalizations && type != 'EMPTY'" v-show="activeItemFormId == item.id" class="item-location-container">
        <TrekioInput height="50px" :id="'GPSlat' + itineraryDay.id + item.id" type="number" min="0" label="Latitude" v-model="location.latitude" @input="onInputChange" required @focus="$emit('setEditLocationItemId', item.id, item, true)"/>
        <TrekioInput height="50px" :id="'GPSlong' + itineraryDay.id + item.id" type="number" min="0" label="Longitude" v-model="location.longitude" @input="onInputChange" required @focus="$emit('setEditLocationItemId', item.id, item, true)"/>
        <button class="pick-from-map-button" :class="{active: editLocationItemId == item.id}" @click="$emit('setEditLocationItemId', item.id)"></button>
      </div>
      <div v-else-if="!isManagingLocalizations" class="item-location-container empty-item" v-show="chosenItemsAction == 'EMPTY_ITEMS'"
      :class="{active: activeItemFormId == item.id}" @click="$emit('setActiveItemForm', item)" @dragover.prevent="$emit('dragOver', $event, item, index)">
      
      <div class="sort-icon-container" :draggable="true" @dragstart="$emit('dragStart', $event, index, item.id)" @dragend="$emit('dragEnd', $event, index)">
        <div :class="{'sort-icon':  !['START', 'FINISH'].includes(type)}" ></div>
      </div>
      <TrekioInput height="50px" :id="'GPSlat' + itineraryDay.id + item.id" type="number" min="0" label="Latitude" v-model="location.latitude" @input="onInputChange" required @focus="$emit('setEditLocationItemId', item.id)"/>
      <TrekioInput height="50px" :id="'GPSlong' + itineraryDay.id + item.id" type="number" min="0" label="Longitude" v-model="location.longitude" @input="onInputChange" required @focus="$emit('setEditLocationItemId', item.id)"/>
        <button class="pick-from-map-button" :class="{active: editLocationItemId == item.id}" @click.stop="$emit('setEditLocationItemId', item.id, item)"></button>
        <b-button v-if="!isManagingLocalizations && item.id" @click.stop="" @click=showDeleteModal v-b-modal="'modal-delete-itineraryItem'+ item.id" class="btn btn-delete"></b-button>
      </div>
    </div>
</template>

<script>

    export default {
        props: {
            item: Object,
            index: Number,
            itineraryDay: Object,
            activeItemFormId: undefined,
            chosenItemsAction: String,
            editLocationItemId: undefined,
            primaryLanguage: String,
            isManagingLocalizations: Boolean
        },
        data() {
            return {
              location: {
                  longitude: null,
                  latitude: null
              },
              errorIds: this.$store.state.admin.errorIds,
              debounceTimeoutId: null,
            }
            
        },
        computed: {
          primaryLocalization() {
            return this.item.localizations.find(localization => localization.language == this.primaryLanguage)
          },
          hasError() {
            return this.$store.state.admin.errorIds.includes(this.item.id)
          },
          type() {
            return this.item.itineraryItemType
          },
        },

        created() {
          this.$root.$on(`updateLocation${this.item.id}`, this.setLocation)
        },
        mounted() {
            this.resetToDefault()
        },
        
        watch: {
          item(nV) {
              this.resetToDefault()
          }
        },

        methods: {
          showDeleteModal() {
            this.$root.$emit('bv::show::modal', 'modal-delete-itineraryItem'+ this.item.id)
          },
          onInputChange() {
            if (this.debounceTimeoutId) {
              clearTimeout(this.debounceTimeoutId);
            }
            this.debounceTimeoutId = setTimeout(() => {
              this.$root.$emit(`updateLocation${this.item.id}`, {longitude: this.location.longitude ? this.location.longitude : null, latitude: this.location.latitude ? this.location.latitude : null})
              this.$store.commit('setCoords', {coords: {x: this.location.longitude, y: this.location.latitude}, dayId: this.itineraryDay.id, itemId: this.item.id})
              this.$emit('reloadMap')
            }, 500);
          },
          setLocation(location) {
            this.location = location
          },
          resetToDefault() {
            if (this.item && this.item.location) {
              this.location.longitude = this.item.location.longitude
              this.location.latitude = this.item.location.latitude
            }
            this.itemNumber = this.item.itemNumber
          },
        }
    }


</script>

<style scoped lang="scss">
    @import '@/scss/variables';


.no-interaction button, .no-interaction .sort-icon {
  display: none !important;
}

.item.m-block-15 {
  margin-block: 15px;

  div:first-child > & {
    margin-top: 0;

    .item-header {
      border-top: none;
    }
  }
}

.item-location-container .input-container {
  & label {
    left: 10px !important;
  }

  & input {
    padding-left: 9px !important;
  }
}

.btn-delete {
  padding: 0;
  min-width: 50px;
}

.dragging {
  cursor: pointer;
  opacity: 0.5;

  &:active {
    cursor: pointer;
  }
}

.sort-icon {
  background-image: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(%23a)" fill="%23222"><circle cx="9" cy="6" r="1"/><circle cx="16" cy="6" r="1"/><circle cx="9" cy="12" r="1"/><circle cx="16" cy="12" r="1"/><circle cx="9" cy="18" r="1"/><circle cx="16" cy="18" r="1"/></g><defs><clipPath id="a"><path fill="%23fff" d="M0 0h24v24H0z"/></clipPath></defs></svg>');
  background-size: 40px;
  background-repeat: no-repeat;
  background-position: center;
  height: 50px;
  width: 50px;
  cursor: move;
}

.item-header, .add-itinerary-item {
  height: 50px;

  &::before {
    content: "";
    left: 0;
    height: 50px;
    position: absolute;
    width: 5px;
    background-color: $tr-green;
    display: none;
  }

  &.active::before {
    display: block;
  }


  & span.title {
    font-size: 20px;
    max-width: 400px;
  }

  .itinerary-item-icon {
    background-image: url("data:image/svg+xml,%3Csvg fill='none' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23a)'%3E%3Cpath d='m17 26v8h-10v-8h2v1.5h2v-1.5h2v1.5h2v-1.5h2z' stroke='%23222'/%3E%3Cpath d='m32.5 11.482c0 0.4937-0.2332 1.1836-0.6478 1.9904-0.4063 0.7904-0.9534 1.6307-1.5086 2.4056-0.554 0.7731-1.1091 1.4715-1.5262 1.9772-0.1181 0.1431-0.2249 0.2706-0.3174 0.3798-0.0925-0.1092-0.1993-0.2367-0.3174-0.3798-0.4171-0.5057-0.9722-1.2041-1.5262-1.9772-0.5552-0.7749-1.1023-1.6152-1.5086-2.4056-0.4146-0.8068-0.6478-1.4967-0.6478-1.9904 0-2.1974 1.789-3.9821 4-3.9821s4 1.7847 4 3.9821z' stroke='%23222'/%3E%3Ccircle cx='29' cy='21' r='1' fill='%23222'/%3E%3Ccircle cx='29' cy='24' r='1' fill='%23222'/%3E%3Ccircle cx='29' cy='30' r='1' fill='%23222'/%3E%3Ccircle cx='29' cy='33' r='1' fill='%23222'/%3E%3Ccircle cx='21' cy='12' r='1' fill='%23222'/%3E%3Ccircle cx='26' cy='33' r='1' fill='%23222'/%3E%3Ccircle cx='18' cy='12' r='1' fill='%23222'/%3E%3Ccircle cx='29' cy='27' r='1' fill='%23222'/%3E%3Ccircle cx='23' cy='33' r='1' fill='%23222'/%3E%3Ccircle cx='15' cy='12' r='1' fill='%23222'/%3E%3Ccircle cx='20' cy='33' r='1' fill='%23222'/%3E%3Cpath d='m9.5 9.6009 2 1.3334v2.5657h-4v-2.5657l2-1.3334z' stroke='%23222'/%3E%3Ccircle cx='28.5' cy='11.5' r='1.5' stroke='%23222'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Crect width='40' height='40' fill='%23fff'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
    background-size: 40px 40px;
    background-repeat: no-repeat;
    background-position: center center;
    width: 50px;
    height: 50px;

    &.start-icon {
      background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23a)'%3E%3Cpath d='m16 22 13-5-13-5v10Zm0-12v24' stroke='%23222'/%3E%3Ccircle cx='25' cy='33' r='1' fill='%23222'/%3E%3Ccircle cx='22' cy='33' r='1' fill='%23222'/%3E%3Ccircle cx='19' cy='33' r='1' fill='%23222'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath fill='%23fff' d='M0 0h40v40H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
    }
    &.finish-icon {
      background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23a)'%3E%3Cpath d='M34 12v10H16V12h18Zm-18-2v24' stroke='%23222'/%3E%3Ccircle cx='13' cy='33' r='1' fill='%23222'/%3E%3Ccircle cx='10' cy='33' r='1' fill='%23222'/%3E%3Ccircle cx='7' cy='33' r='1' fill='%23222'/%3E%3Cpath fill='%23222' d='M16 12h3v2h-3zm3 2h3v2h-3zm-3 2h3v2h-3zm3 2h3v2h-3zm-3 2h3v2h-3zm6-8h3v2h-3zm3 2h3v2h-3zm-3 2h3v2h-3zm3 2h3v2h-3zm-3 2h3v2h-3zm6-8h3v2h-3zm3 2h3v2h-3zm-3 2h3v2h-3zm3 2h3v2h-3zm-3 2h3v2h-3z'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath fill='%23fff' d='M0 0h40v40H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
    }
  }
  & .btn-delete {
    background-size: 34px;
  }
}
.btn-delete{
  appearance: none;
  border-color: transparent !important;
  background-color: transparent !important;
  background-image: url("data:image/svg+xml,%3Csvg width='50' height='50' viewBox='0 0 50 50' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='25' cy='25' r='25' fill='%23222222'/%3E%3Cg clip-path='url(%23clip0_1668_4909)'%3E%3Cpath d='M17.8579 32.1421L32 18' stroke='white'/%3E%3Cpath d='M32.1421 32.1421L18 18' stroke='white'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_1668_4909'%3E%3Crect width='24' height='24' fill='white' transform='translate(13 13)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
  background-size: 34px;
  background-position: center center;
  background-repeat: no-repeat;
  width: 50px;
  height: 50px;

  &:focus, &:active, &:hover {
    background-color: transparent !important;
  }
}
.item-header{
  cursor: pointer;
  position: relative;
  align-items: center;
  background-color: $tr-white-alt;
  border-bottom: dashed 3px $tr-light-gray;
  display: flex;

  .itinerary-item-icon, .item-number {
    flex-shrink: 0;
  }

  &.border-top-dashed {
    border-top: dashed 3px $tr-light-gray;
  }

  &.error::after {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    height: 50px;
    width: 5px;
    background-color: $tr-pink;
  }


  .item-number {
    display: flex;
    align-items: center;
    width: 30px;
    margin: 0;

    & div {
      margin: auto;
    }
  }

  .dayNumber{
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 25px;
    color: $tr-gray;
    padding-right: 20px;
  }

  .title{
    overflow-x: clip;
    text-overflow: ellipsis;
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 25px;
    color: $tr-black;
    width: 100%;
    overflow: hidden;
  }
}

  .item-location-container {
    display: flex;
    justify-content: center;
    padding-inline: 25px;
    // border-bottom: dashed 3px $tr-light-gray;
    gap: 10px;
    margin-bottom: 10px;
    position: relative;

    &::before {
      content: "";
      top: 0;
      left: -5px;
      height: 50px;
      position: absolute;
      width: 5px;
      background-color: $tr-green;
      display: none;
    }

    &.active::before {
      display: block;
    }

    input {
        border: 3px dashed $tr-light-gray;
        border-top: none;
        min-height: 50px;
        height: 100%;
        padding: 16px 17px 0 17px !important;
        -moz-appearance: textfield;
        appearance: textfield;
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
    }

    &.empty-item {
      background-color: $tr-white-alt;
      gap: 0;
      margin-inline: 20px;
      padding-inline: 0;

      .sort-icon, input, .pick-from-map-button {
        border-right: 0 !important;
      }

      & input {
        border-left: 3px dashed $tr-light-gray;
        border-right: 3px dashed $tr-light-gray; 
      }

      .sort-icon {
        border: 3px dashed $tr-light-gray;
      }

      & .pick-from-map-button {
        border: 3px dashed $tr-light-gray;

        &:hover, &.active {
          margin-right: -3px;
          z-index: 1;
          min-width: 53px;
        }
      }

      & .btn-delete {
        border: 3px dashed $tr-light-gray !important;
        border-radius: 0;
      }
    }

    .pick-from-map-button {
        min-width: 50px;
        outline: none;
        height: 50px;
        background-size: 35px;
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_245_1026)'%3E%3Ccircle cx='8.5' cy='9.5' r='2.5' stroke='%23222222'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M15 9.47019C15 10.2513 14.6274 11.3713 13.9387 12.7114C13.8575 12.8695 13.7728 13.0288 13.685 13.1891L14.4218 13.9222C15.3448 12.2827 16 10.6896 16 9.47019C16 5.34452 12.6421 2 8.5 2C4.35786 2 1 5.34452 1 9.47019C1 12.8422 6.01009 19.0722 7.84058 21.2359C8.24971 21.7195 8.5 22 8.5 22C8.5 22 8.75029 21.7195 9.15942 21.2359C9.68285 20.6172 10.4663 19.666 11.3182 18.5442L10.6028 17.8325C9.97642 18.6612 9.38302 19.4011 8.90047 19.986C8.75619 20.1609 8.622 20.3218 8.5 20.4668C8.378 20.3218 8.24381 20.1609 8.09953 19.986C7.40593 19.1452 6.48333 17.9844 5.56287 16.6998C4.6398 15.4115 3.73313 14.0186 3.06128 12.7114C2.37256 11.3713 2 10.2513 2 9.47019C2 5.90055 4.90639 3 8.5 3C12.0936 3 15 5.90055 15 9.47019Z' fill='%23222222'/%3E%3Cpath d='M14.6397 18.6248C14.6397 18.6248 11.7374 15.7226 10.9395 14.9247M14.6397 18.6248L16.5 20.5C16.5 20.5 11.7374 15.7226 10.9395 14.9247M14.6397 18.6248L14.1295 19.0903C14.0223 19.1882 13.9569 19.3237 13.9471 19.4686V19.4686C13.9017 20.1413 14.1847 20.7967 14.7512 21.1623C16.0546 22.0035 18.5098 23.264 20 22C20 22 24.5 18.5 22.342 16.2891C22.342 16.2891 19.9482 13.8953 19.6587 13.6058C19.3692 13.3163 18.8288 13.0997 18.3431 13.5854C17.8574 14.0711 18.3361 14.5499 18.1742 14.7118C18.0123 14.8737 17.5336 14.3949 17.0478 14.8807C16.5621 15.3664 17.0409 15.8451 16.879 16.007C16.7171 16.1689 16.2383 15.6902 15.7526 16.1759C15.2669 16.6616 15.7433 17.4619 15.5837 17.3023C15.4242 17.1427 12.8708 14.5893 12.0728 13.7914C11.2749 12.9934 10.1416 14.1268 10.9395 14.9247' stroke='%23222222'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_245_1026'%3E%3Crect width='24' height='24' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
        background-position: center;
        background-repeat: no-repeat;
        background-color: $tr-white-alt;
        border: 3px dashed $tr-light-gray;
        border-top: none;

        &:hover, &.active {
          border: 3px dashed $tr-black !important;
          margin-top: -1px;
        }

        &.active {
          background-color: $tr-green;
        }
    }

    &.empty-item {
      margin-block: 8px;

      & input, & .pick-from-map-button {
        border-top: 3px dashed $tr-light-gray;
        margin-top: 0 !important;
      }
    }
  }
</style>