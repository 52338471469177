<template>
  <div>    
      <b-alert :show="alert.dismissCountDown" fade variant="success" id="showAlert"
               @dismiss-count-down="countDownChanged" class="alert-success">{{ alert.notification }}
      </b-alert>
      <b-alert :show="alertDanger.dismissCountDown" fade variant="danger" id="showAlertDanger"
               @dismiss-count-down="countDownChangedDanger" class="alert-danger multi-line pre-formatted">
        {{ alertDanger.notification }}
      </b-alert>
          <b-modal class="modal" :id="'modal-delete-itineraryItem' + itineraryItem.id" hide-footer hide-header>
            <div class="modal-container py-5 d-flex flex-column justify-content-center mx-auto">
                      <h3 class="moda-title pb-3">{{itineraryItem.itineraryItemType == 'EMPTY' ? $t("modals.emptyItemDelete") : $t("modals.itemDelete", [itineraryItem && itineraryItem.title])}}</h3>
                      <div class="pb-5">
                        <p class="moda-text">{{ $t("modals.changesWillBeIrreversible")}}</p>
                        <div v-if="ammountOfEmptyPointsAfterDeletingItem() > 9 || loadingDelete == 'Odstraňování slepých bodů...'">
                          <p class="mb-0"><b>{{ $t("modals.warning") }}</b></p>
                          <p>{{ $t("admin.deleteItemTooManyEmptyItems", {ammountOfEmptyPoints: ammountOfEmptyPointsAfterDeletingItem(), previousItem: prevItem ? $t("admin.betweenItem", [prevItem.title]) : $t("admin.currentStartOfRoute"), nextItem: nextItem ? nextItem.title : $t("admin.byTheCurrentEndOfTheRoute")}) }}</p>
                          <p>{{ $t("modals.emptyItemLimitText2") }}</p>
                          <p>{{ $t("modals.emptyItemLimitText3", [ammountOfEmptyPointsAfterDeletingItem()])}}</p>
                        </div>
                      </div>
                      <div class="pb-5 d-flex" style="gap: 40px">
                          <TrekioButton secondary  @click="$bvModal.hide('modal-delete-itineraryItem' + itineraryItem.id)">{{$t("general.back")}}</TrekioButton>
                          <TrekioButton primary :isLoading="loadingDelete != ''" :loadingText="loadingDelete" @click="onDeleteConfirmed">{{ $t("buttons.delete") }}</TrekioButton>
                      </div>
                  </div>
          </b-modal>
          <div class="forms-container" :class="{'visible-localizations': isManagingLocalizations}" :id="'itineraryItem' + itineraryItem.id">
            <form v-for="(localization, localizationIndex) in form.localizations" :key="localization.language" class="form d-flex flex-column" style="gap: 40px;" @submit.prevent="onSubmit" novalidate>
              <div class="form-content-container" v-if="primaryLanguage == localization.language || isManagingLocalizations && editedLanguage == localization.language">
                <div v-if="form.itineraryItemType !== 'START' && !isManagingLocalizations" class="d-flex flex-column" style="gap: 30px">
                  <div v-if="routeLength">{{ $t("admin.routeLengthHint")}} <b>{{routeLength}}</b></div>
                  <div class="transport-container" v-for="(transferType, index) in form.transferTypeValues" :key="index">

                    <TrekioSelect v-model="form.transferTypeValues[index].transferType" :label="$t('inputLabels.transport')" :error="error.transfers[index].transferType"
                      :text="transferType.transferType !== '' ? itineraryItemTransferTypes.find(value => value.code === transferType.transferType).name : $t('admin.notSelected')"
                      :info="isInfoIconVisible(localization.language) ? $t('info.itemTransferType') : null"
                    >

                      <TrekioSelectOption v-for="transferType in itineraryItemTransferTypes" :key="transferType.code" :value="transferType.code">{{ transferType.name }}</TrekioSelectOption>
                    </TrekioSelect>                    

                    <TrekioInput v-if="transferType.transferType" type="number" width="150px" id="itemLength" trim v-model="form.transferTypeValues[index].length" :label="$t('inputLabels.itemRouteLength')"
                      :error="error.transfers[index] && error.transfers[index].transferLength" required @input="onInputChange" @change="onInputChange" :info="isInfoIconVisible(localization.language) ? $t('info.itemTransferLength') : null" min="0"
                    />

                    <button type="button" v-if="form.transferTypeValues.length !== 1" class="remove-transfer-button" @click="removeTransferType(index)"><div>+</div></button>
                  </div>
                  <button type="button" v-if="form.itineraryItemType !=='START' && form.transferTypeValues.length < 5" class="add-transfer" @click="addTransferType"><div>+</div>{{ $t("buttons.addTransfer")}}</button>
                </div>
  
                <div class="d-flex" style="gap: 30px">
                  <TrekioInput id="title" trim v-model="localization.title" :label="$t('inputLabels.itemTitle')" maxLength="255" :disabled="isManagingLocalizations && primaryLanguage == localization.language"
                      :error="error.title" required @input="onInputChange" @change="onInputChange" :info="isInfoIconVisible(localization.language) ? $t('info.itemTitleDefault') : null" 
                    />

                  <TrekioSelect v-if="!isManagingLocalizations" multiple :label="$t('inputLabels.countries')" v-model="form.countries" :placeholder="$t('admin.notSelected')" :error="error.countries" required
                    :text="form.countries && form.countries.length > 0 && countries ? countries.filter(c => form.countries.includes(c.id)).map(c => c[countryLocalization]).join(', ') : $t('admin.notSelected')"
                    :info="isInfoIconVisible(localization.language) ? (form.itineraryItemType == 'DEFAULT' ? $t('info.itemCountriesDefault') : form.itineraryItemType == 'START' ? $t('info.itemCountriesStart') : $t('info.itemCountriesFinish')) : null"
                    searchInput @searchInput="(value) => countriesSearchString = value" :searchString="countriesSearchString"
                  >
                    <TrekioSelectOption :value="country.id" v-for="country in filteredCountries" :key="country.id">{{ country[countryLocalization] }}</TrekioSelectOption>
                  </TrekioSelect>
                  
                  <button v-if="!isManagingLocalizations && isCopyDataButtonVisible" type="button" class="img-button copy-icon" v-tooltip.right-start="copyDataInfo" @click="copyData"></button>
                </div>
                <TrekioTextarea id="description" trim v-model="localization.description" :label="$t('inputLabels.itemDescription')" :required="form.itineraryItemType == 'DEFAULT'"
                  :info="isInfoIconVisible(localization.language) ? (form.itineraryItemType == 'DEFAULT' ? $t('info.itemDescriptionDefault') : form.itineraryItemType == 'START' ? $t('info.itemDescriptionStart') : $t('info.itemDescriptionFinish')) : null"
                  :hint="$t('inputHints.maxLength1024')" :error="error.description" maxlength="1024" :disabled="isManagingLocalizations && primaryLanguage == localization.language" :height="textareaHeights.description"
                  @resizeTextarea="setTextareaHeight"
                />
                <TrekioTextarea id="practicalInfo" trim v-model="localization.warning" :label="$t('inputLabels.practicalInfo')"
                  :info="isInfoIconVisible(localization.language) ? (form.itineraryItemType == 'START' ? $t('info.itemPracticalInfoStart') : $t('info.itemPracticalInfoDefault')) : null"
                  :hint="$t('inputHints.maxLength1024')" maxlength="1024" :disabled="isManagingLocalizations && primaryLanguage == localization.language" :height="textareaHeights.practicalInfo"
                  @resizeTextarea="setTextareaHeight"
                />
  
                <div class="position-relative" style="max-width: 330px;" v-if="!isManagingLocalizations">
                  <v-popover
                    offset="10"
                    placement="right-start"
                    style="position: absolute; z-index: 1024; right: 20px; top: 22px; color: white !important"
                  >
                    <div class="tooltip-target info-icon"></div>

                    <template slot="popover">
                      <i18n :path="form.itineraryItemType == 'DEFAULT' ? 'info.defaultItemDifficulty' : form.itineraryItemType == 'START' ? 'info.startItemDifficulty' : 'info.finishItemDifficulty'" :tag="false">
                        <template v-slot:explanationLink>
                            <span class="link" @click="$bvModal.show('difficulty-detail-modal')">{{ $tc('info.itemDifficultyLinkText') }}</span>
                          </template>
                        </i18n>
                    </template>
                  </v-popover>

                  <TrekioSelect class="difficulty-select" :label="$t('inputLabels.difficulty')" v-model="form.difficulty" :placeholder="$t('admin.notSelected')" :error="error.difficulty" :required="form.itineraryItemType !== 'START'"
                    :text="form.difficulty ? itineraryItemDifficulties.find(value => value.code === form.difficulty).name : $t('admin.notSelected')"
                  >
                    <TrekioSelectOption :value="difficulty.code" v-for="difficulty in itineraryItemDifficulties" :key="difficulty.code">{{ difficulty.name }}</TrekioSelectOption>
                  </TrekioSelect>
                </div>
  
                <TrekioInput v-if="!isManagingLocalizations" type="number" min="1" max="999" id="itemDuration" :label="$t('inputLabels.itemDuration')" v-model="form.duration"
                  :info="isInfoIconVisible(localization.language) ?  $t('info.itemDuration') : null" @keypress="blockInvalidChar($event, true, 3)"
                  />
                <Checkbox v-if="!isUserAuthor && (!isManagingLocalizations || localization.language != primaryLanguage)" v-model="localization.translated" @change="translatedChanged({isTranslated: localization.translated, localizationIndexes: [localizationIndex]})">
                  {{ $t("admin.approveItemTranslation") }} ({{ localization.language }})
                </Checkbox >
  
                <div v-if="!isManagingLocalizations">
                  <div class="py-3 d-flex align-items-center">{{ $t("general.pictures")}}
                    <div v-if="isInfoIconVisible(localization.language)" class="info-icon ml-2" v-tooltip.right-start="$t('info.itemPictures')"></div>
                  </div>
                  <div class="pictures-container">
                    <div v-for="(picture, index) in form.itineraryItemPictures" :key="picture.id" class="picture-container" @dragover.prevent="dragOver($event, index)">
                      <div v-if="!disableInputs" class="icon-with-tooltip-container order-picture" :draggable="true" @dragstart="dragStart($event, index, picture)" @dragend="dragEnd($event)">
                        <div class="sort-icon" v-tooltip.top="$t('info.itemChangeOrder')"></div>
                      </div>
                      <div v-if="!disableInputs" class="icon-with-tooltip-container">
                          <img class="picture-of-the-day-icon" v-tooltip.top="dayPictureId == picture.id? $t('inputLabels.dayPicture') : $t('info.chooseAsDayPicture')"
                          :class="{active: dayPictureId == picture.id}" @click="chooseAsDayPicture(picture)" src="../../assets/svg/icon-choose-as-day-picture.svg" alt="">
                      </div>
  
                      <div class="x position-absolute" @click="removePicture(picture)"></div>
                      <figure v-lazyload class="position-relative">
                        <div class="loading-circle position-absolute"><div></div><div></div><div></div><div></div></div>
                        <img class="picture w-100 h-100" style="cursor: pointer" @click="showFullscreenItemPicture(picture)" :data-url="imgUrl + picture.fileName.slice(0,picture.fileName.lastIndexOf('.')) + '-dimensions-640pxX480px' + picture.fileName.slice(picture.fileName.lastIndexOf('.'))">
                      </figure>
                    </div>
                    <div v-b-modal="`modal-item-picture-select${itineraryDay.id + itineraryItem.id}`" class="d-flex add-item-picture flex-column align-items-center justify-content-center">
                      <div class="plus mx-auto"></div>
                      <p class="mt-4 text-center">{{ $t("admin.addPicturesToItem")}}</p>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
            <b-modal size="fit-content" class="fullsize-picture modal pl-0" :id="'fullscreen-item-picture' + itineraryDay.id + itineraryItem.id" hide-footer hide-header>
              <div @click="$bvModal.hide('fullscreen-item-picture' + itineraryDay.id + itineraryItem.id)" class="x"></div>
              <figure v-lazyload>
                <div class="loading-circle position-absolute"><div></div><div></div><div></div><div></div></div>
                <img class="picture" :data-url="imgUrl + fullscreenItemPicFileName">
                <p class="fullscreen-picture-description">{{fullscreenItemTitle}}</p>
              </figure>
            </b-modal>

            <!-- MODAL WITH PICTURES -->
            <b-modal class="modal" :id="'modal-item-picture-select' + itineraryDay.id + itineraryItem.id" hide-footer hide-header>
              <div class="modal-container py-5 d-flex flex-column justify-content-center mx-auto"  style="max-width: none">
                <b-tabs card v-model="tabIndex">
                  <b-tab :title="$t('admin.choosePicture')" active>
                    <b-card-text>

                      <!-- Temporary filter -->
                      <div class="filters-container text-left d-flex align-items-end">
                        <TrekioInput id="pictures-search" whiteBg v-model="filter.searchString" :placeholder="$t('general.search')" searchInput/>

                        <TrekioSelect whiteBg :label="$t('inputLabels.countries')" v-model="filter.country" :searchInput="allCountriesInPictures.length > 10" :placeholder="$t('general.allFemAndLifeless')"
                          @searchInput="value => pictureCountriesSearchString = value" :searchString="pictureCountriesSearchString"
                          :text="filter.country ? filter.country[countryLocalization] : $t('general.allFemAndLifeless')"
                        >
                          <TrekioSelectOption :value="null">{{ $t("general.allFemAndLifeless") }}</TrekioSelectOption>
                          <TrekioSelectOption v-for="country in filteredCountriesInPictures" :key="country.id" :value="country">{{ country[countryLocalization] }}</TrekioSelectOption>
                        </TrekioSelect>

                        <TrekioSelect v-if="!isUserAuthor" whiteBg :label="$t('inputLabels.author')" v-model="filter.authorId" :searchInput="allCountriesInPictures.length > 10" :placeholder="$t('general.allMascLiving')"
                          :text="filter.authorId ? authorsList.find(author => author.id === filter.authorId).userName : $t('general.allMascLiving')"
                        >
                          <TrekioSelectOption :value="null">{{ $t("general.allMascLiving") }}</TrekioSelectOption>
                          <TrekioSelectOption v-for="author in authorsList" :key="author.id" :value="author.id">{{ author.userName }}</TrekioSelectOption>
                        </TrekioSelect>
                      </div>
                      <!--  -->
                      <div v-if="filteredPictures.length < 1">{{ $t("filter.noResults")}}</div>
                      <PictureList :pictures="filteredPictures" :travelTipPictures="true" @onPictureClick="onPictureClick"/>
                    </b-card-text>
                  </b-tab>
                  <b-tab :title="$t('admin.addNewPictures')">
                    <b-card-text>
                      <div class="add-new-picture-container" :key="imageComponentKey">
                        <AddPhotos @weHaveNewFoto="newPictureAdded" :whiteInputs="true" :preselectedCountry="preselectedCountry"></AddPhotos>
                      </div>
                    </b-card-text>
                  </b-tab>
                </b-tabs>
              </div>
            </b-modal>
  </div>
</template>

<script>
import ItineraryService from "../../services/ItineraryService";
import {CLEVERTRIP_IMG_URL} from "@/definitions";
import {COUNTRIES} from "@/api/graphql/query/CountryQuery";
import LazyLoadDirective from "@/components/LazyLoadDirective.js";
import AddPhotos from "@/pages/admin/ManagePicturePage";
import PictureList from "../../components/admin/PictureList.vue";
import { travelTipInfo } from "../../components/InfoIconTexts.js";
import sharedUtils from "../../utils/sharedUtils.js"
import Checkbox from "../Checkbox.vue";

export default {
  directives: {
        lazyload: LazyLoadDirective
    },
  name: "itineraryItem",

  components: {
    AddPhotos,
    PictureList,
    Checkbox
  },

  props: {
    index: Number,
    itineraryDay: Object,
    itineraryItem: Object,
    travelTipId: Number,
    pictures: Array,
    allItineraryItems: Array,
    disableInputs: Boolean,
    dayPictureId: String,
    previousDayFinish: Object,
    previousDayId: {
      required: false
    },
    isFirstItemStart: Boolean,
    isManagingLocalizations: Boolean,
    originalLanguage: String,
    primaryLanguage: String,
    editedLanguage: String,
    localizationCodes: Array
  },

  data: function () {
    return {
      ammountOfEmptyPointsBeingDeleted: null,
      prevItem: undefined,
      nextItem: undefined,
      infoTexts: travelTipInfo,
      filter: {
        searchString: '',
        country: {},
        authorId: null,
      },
      textareaHeights: {
        description: '100px',
        practicalInfo: '100px'
      },
      preselectedCountry: null,
      isMapModalVisible: false,
      loading: '',
      loadingDelete: '',
      tabIndex: 0,
      countriesSearchString: '',
      pictureCountriesSearchString: '',
      fullscreenItemPicFileName: '',
      fullscreenItemTitle: '',
      form: {
        id: null,
        itineraryItemType: 'DEFAULT',
        title: null,
        duration: null,
        difficulty: null,
        warning:null,
        itemNumber: null,
        description:null,
        countries: [],
        transferTypeValues: [],
        location: {
          latitude: null,
          longitude: null
        },
        lastSavedForm: {},
        itineraryItemPictureIds: [],
        itineraryItemPictures: []
      },
      error: {
        transfers: [
          {
            transferType: "",
            transferLength: "",
          }
        ],
        title: '',
        description: '',
        difficulty: '',
        countries: '',
      },
      imgUrl: CLEVERTRIP_IMG_URL,
      itemNumberError: this.$t("inputErrors.required"),
      arrivalDateError: this.$t("inputErrors.required"),
      GPSErrors: {
        GPSlat: this.$t("inputErrors.required"),
        GPSlong: this.$t("inputErrors.required"),
      },
      alert: {
        dismissSecs: 6,
        dismissCountDown: 0,
        showDismissibleAlert: false,
        notification: ''
      },
      alertDanger: {
        dismissSecs: 999,
        dismissCountDown: 0,
        showDismissibleAlert: false,
        notification: ''
      },
      routes: [],
      routesWithTransfers: [],
      selectedRouteId: null,
      travelTip: null,
      imageComponentKey: 0,
      m: null,
      routeLength: undefined,
      draggedIndex: null,
      draggedPicture: null,
    }
  },

  created() {
    this.$root.$on(`updateLocation${this.itineraryItem.id}`, this.setLocation)
    this.$root.$on('setRouteLengths' + this.itineraryDay.id, this.setRouteLength)
    this.$root.$on('createLocalization', this.createLocalization)
    this.$root.$on('discardChanges', this.discardChanges)
    this.resetToDefault()
  },

  mounted: function () {
    this.checkState()
  },

  computed: {
    itineraryItemDifficulties() {
      return [
        {code: 'LOW', name: this.$t("general.low")},
        {code: 'MEDIUM', name: this.$t("general.medium")},
        {code: 'HIGH', name: this.$t("general.high")}
      ]
    },
    itineraryItemTransferTypes() {
      return [
        {code: 'CAR', name: this.$t("general.car")},
        {code: 'BUS', name: this.$t("general.bus")},
        {code: 'WALK', name: this.$t("general.walk")},
        {code: 'ROLLER_SCATE', name: this.$t("general.rollerScate")},
        {code: 'BIKE', name: this.$t("general.bike")},
        {code: 'SCOOTER', name: this.$t("general.scooter")},
        {code: 'CABLE_CAR', name: this.$t("general.cableCar")},
        {code: 'FLIGHT', name: this.$t("general.flight")},
        {code: 'BOAT', name: this.$t("general.boat")},
        {code: 'PUBLIC_TRANSPORT', name: this.$t("general.publicTransport")},
        {code: 'TRAIN', name: this.$t("general.train")},
      ]
    },
    copyDataInfo() {
      if (this.form.itineraryItemType === 'START') {
        return this.$t("info.itemCopyDataInfoStart")
      } else if (this.form.itineraryItemType === 'FINISH') {
        return this.$t("info.itemCopyDataInfoFinish")
      }
      return ''
    },
    isCopyDataButtonVisible() {
      return (this.form.itineraryItemType === 'START' && this.previousDayFinish != null) || (this.form.itineraryItemType === 'FINISH' && this.isFirstItemStart)
    },
    isUserAuthor() {
      return this.$store.state.currentUserRole === "AUTHOR"
    },
    allCountriesInPictures() {
      if (!this.pictures) return []
      let allCountries = [];
          this.pictures.forEach(pic => {
              if (!allCountries.some(value => value.code === pic.country.code)) {
                  allCountries.push(pic.country);
              }
          });
          return allCountries.sort((a, b) => {
            return sharedUtils.removeAccents(a[this.countryLocalization]).localeCompare(sharedUtils.removeAccents(b[this.countryLocalization]), this.$store.state.preferredLanguage)
          });
    },
    filteredCountriesInPictures() {
      if (!this.allCountriesInPictures) return []
      return this.allCountriesInPictures.filter(pic => {
        return pic[this.countryLocalization].toLowerCase().includes(this.pictureCountriesSearchString.toLowerCase())
      })
    },
    authorsList() {
      if (!this.pictures) return
      let authors = [];
      this.pictures.forEach((picture) => {
        if (!authors.some(author => author.userName == picture.author.userName)) {
          authors.push(picture.author)
        }
      });
      return authors;
    },
    filteredPictures() {
      if (!this.pictures) return []
      let filter = {...this.filter}
      return this.pictures.filter(picture => {
        if (filter.searchString && !picture.title.toLowerCase().includes(filter.searchString.toLowerCase())) return false
        if (filter.country?.code && picture.country.code != filter.country.code) return false
        if (filter.authorId && picture.author.id != filter.authorId) return false
        return true
      })
    },
    itemPictures() {
      if (!this.pictures) return
      return this.pictures.filter(pic => this.form.itineraryItemPictureIds.includes(pic.id))
    },
    filteredCountries() {
      if(this.countries) {
        const searchString = sharedUtils.removeAccents(this.countriesSearchString.toUpperCase())
          return this.countries.filter(c => {
            if (sharedUtils.removeAccents(c[this.countryLocalization]).toUpperCase().includes(searchString)) return true
            return false
          })
          .sort((a, b) => {
            // Move selected countries to top
            if (this.form.countries.includes(a.id.toString()) || this.form.countries.includes(b.id.toString())) {
              if (this.form.countries.includes(a.id.toString()) && this.form.countries.includes(b.id.toString())) {
                return a[this.countryLocalization].localeCompare(b[this.countryLocalization], this.$store.state.preferredLanguage)
              }
              else if (this.form.countries.includes(a.id.toString()) && !this.form.countries.includes(b.id.toString())) {
                return -1
              }
              return 1
            }
            return a[this.countryLocalization].localeCompare(b[this.countryLocalization], this.$store.state.preferredLanguage)
          })
        }
      return []
    },
    countryLocalization() {
      return this.preferredLanguage == "en" ? "nameEnglish" : "nameCzech"
    },
    preferredLanguage() {
      return this.$store.getters.preferredLanguage
    }
  },

  methods: {
    setTextareaHeight({id, height}) {
      this.textareaHeights[id] = height + "px"
    },
    isChangeInLocalizationInputs() {
      if (JSON.stringify(this.form.localizations[0]) != JSON.stringify(this.lastSavedForm.localizations[0])) {
        return true
      }
      return false
      
    },
    translatedChanged({isTranslated, localizationIndexes}) {
      this.$emit('translatedChanged', {isTranslated, localizationIndexes})
    },
    discardChanges() {
      this.form = this.createCopyOfForm(this.lastSavedForm) 
    },
    isInfoIconVisible(language) {
      // Show info icons only for the main form on the left
      return this.form.localizations[0].language === language
    },
    sortLocalizations(){
      sharedUtils.sortLocalizations(this.form.localizations, this.primaryLanguage, this.editedLanguage)
      if (this.lastSavedForm) {
        sharedUtils.sortLocalizations(this.lastSavedForm.localizations, this.primaryLanguage, this.editedLanguage)
      }
      this.form.itineraryItemPictures?.forEach(picture => {
        sharedUtils.sortLocalizations(picture.localizations, this.primaryLanguage, this.editedLanguage)
      })
    },
    createLocalization(languageCode) {
      this.form.localizations.push({
        language: languageCode,
        title: '',
        description: '',
        warning: '',
        translated: false,
      })
    },
    copyData() {
      const itemType = this.form.itineraryItemType
      const dayId = itemType === 'START' ? this.previousDayId : this.itineraryDay.id
      const items = this.$store.state.admin.itemForms['day' + dayId]

      const findItem = items.find(item => item.itineraryItemType === (itemType === 'START' ? 'FINISH' : 'START'))
      const {latitude, longitude} = findItem.location
      this.form.localizations[0].title = findItem.localizations[0].title
      this.form.countries = findItem.countries.length ? findItem.countries.map(c => c.id) : []
      this.$root.$emit(`updateLocation${this.form.id}`, {longitude: longitude ? longitude : null, latitude: latitude ? latitude : null})
      this.$root.$emit(`updateLocation${this.form.id}`, {longitude: longitude ? longitude : null, latitude: latitude ? latitude : null})
      this.$store.commit('setCoords', {coords: {x: longitude, y: latitude}, dayId: this.itineraryDay.id, itemId: this.form.id})
      this.$store.commit('setTitle', {dayId: this.itineraryDay.id, index: this.index, title: this.form.localizations[0].title})
      this.$emit('reloadMap')
    },

    chooseAsDayPicture(picture) {
      this.$emit("setDayPicture", picture)
    },

    dragStart(event, index, picture) {
      this.draggedIndex = index;
      this.draggedPicture = picture
      event.target.parentElement.lastChild.classList.add("dragging")
      const ghostElement = document.createElement("div")
      ghostElement.style.opacity = "0"
      event.dataTransfer.setDragImage(ghostElement, 0, 0);
    },
    dragEnd(event) {
      event.target.parentElement.lastChild.classList.remove("dragging")
      this.draggedIndex = null;
    },
    dragOver(event, index) {
      event.dataTransfer.effectAllowed = "move"
      event.preventDefault()
      if (this.draggedIndex == undefined || this.draggedIndex == index) return

      this.form.itineraryItemPictures.splice(this.draggedIndex, 1);
      this.form.itineraryItemPictureIds.splice(this.draggedIndex, 1);
      this.form.itineraryItemPictures.splice(index, 0, this.draggedPicture);
      this.form.itineraryItemPictureIds.splice(index, 0, this.draggedPicture.id);
      this.draggedIndex = index;
    },
    ammountOfEmptyPointsAfterDeletingItem() {
      if (this.ammountOfEmptyPointsBeingDeleted) return this.ammountOfEmptyPointsBeingDeleted
      let emptyItemsBeforeItem = 0
      let emptyItemsAfterItem = 0
  
      let prevItem = undefined
      let nextItem = undefined
  
      // empty items(points) before the item
      for (let i = this.index -1; i >= 0; i--) {
        if (i < 0) break
        if (this.allItineraryItems[i].itineraryItemType != 'EMPTY') {
          prevItem = this.allItineraryItems[i]
          break
        }
        emptyItemsBeforeItem += 1
      }
      // empty items(points) after the item
      for (let i = this.index + 1; i < this.allItineraryItems.length; i++) {
        if (this.allItineraryItems[i].itineraryItemType != 'EMPTY') {
          nextItem = this.allItineraryItems[i]
          break
        }
        emptyItemsAfterItem += 1
      }
      this.prevItem = prevItem
      this.nextItem = nextItem
      return emptyItemsBeforeItem + emptyItemsAfterItem
    },
    onInputChange(hasCountriesChanged = false) {
      if (this.debounceTimeoutId) {
        clearTimeout(this.debounceTimeoutId);
      }
      this.debounceTimeoutId = setTimeout(() => {
        if (hasCountriesChanged === true) {
          this.$store.commit('setCountries', {dayId: this.itineraryDay.id, index: this.index, countries: this.form.countries.map(c => {return {id: c}})})
        } else {
          this.$store.commit('setTitle', {dayId: this.itineraryDay.id, index: this.index, title: this.form.localizations[0].title})
          this.$root.$emit('updateMarkerTitle' + this.itineraryDay.id, this.itineraryItem.id, this.form.localizations[0].title)  
        }
      }, 1000);
    },
    setRouteLength() {
      this.routeLength = this.$store.state.admin.itemRouteLengths['item' + this.itineraryItem.id]
    },
    setLocation(location) {
      this.form.location = location
    },
    showFullscreenItemPicture(picture) {
      const fileName = picture.fileName
      this.fullscreenItemPicFileName = fileName.slice(0, fileName.lastIndexOf('.')) + '-dimensions-1600pxX1200px' + fileName.slice(fileName.lastIndexOf('.'))
      this.fullscreenItemTitle = picture.localizations[0]?.title 
      this.$bvModal.show('fullscreen-item-picture' + this.itineraryDay.id + this.itineraryItem.id)
    },
    setCountry(country) {
        this.$refs["countries-dropdown"].hide(true);
        this.filter.country = country;
      },
    setAuthor(userName) {
      this.filter.author = userName;
      this.$refs['author-dropdown'].hide(true);
    },
    toggleMapModal() {
      if (this.isMapModalVisible == true) {
        document.body.style.overflow = "auto"
        this.isMapModalVisible = false;
      }
      else {
        document.body.style.overflow = "hidden"
        this.isMapModalVisible = true;
      }
      setTimeout(() => {
        this.map.syncPort()
      }, 20)
    },
    changeInForm() {
      return JSON.stringify(this.lastSavedForm) != JSON.stringify(this.form) ? true : false
    },
    removeErrors() {
      this.itemNumberError = ''
      this.arrivalDateError = ''
      this.GPSErrors = {
        GPSlat: '',
        GPSlong: '',
      }
      this.error.transfers.forEach(errorGroup => {
        errorGroup.transferType = '',
        errorGroup.transferLength = ''
      })

      if (!this.isManagingLocalizations) {
        const GPSInputList = ['GPSlat' + this.itineraryDay.id + this.itineraryItem.id, 'GPSlong' + this.itineraryDay.id + this.itineraryItem.id] 
        GPSInputList.forEach(el => {
          document.getElementById(el).classList.remove('error')
        })
      }

      this.removeLocalizationInputErrors()
    },
    checkState() {
      if (this.disableInputs) {
        if (this.map) {
          this.map.getControls().forEach(control => this.map.removeControl(control))
        }
        const allInputContainers = document.querySelectorAll(".input-container, .select-container")
        const allInputs = document.querySelectorAll("input, textarea:not(.comment-area), button.dropdown-toggle")
        allInputs.forEach(input => {
          input.setAttribute("disabled", true)
        })
        allInputContainers.forEach(input => {
          input.classList.add("disabled")
        })
      }
    },

    swapValues(location) {
      [this.form[location].longitude, this.form[location].latitude] = [this.form[location].latitude, this.form[location].longitude]
    },

    blockInvalidChar(e, blockDot = false, maxLength = 50) {
      let symbolsToBlock = ['e', 'E', '+']
      blockDot && symbolsToBlock.push('.', '-')
      if (e.target.value.length == maxLength || symbolsToBlock.includes(e.key)) e.preventDefault()
    },

    async newPictureAdded(newPictures){
      await this.$root.$emit('refetchPictures')

      newPictures.forEach(picture => {
        this.onPictureClick(picture)
      })
      this.imageComponentKey += 1
    },

    removePicture(chosenPicture) {
      if (this.form.itineraryItemPictureIds.length < 1) return
      this.form.itineraryItemPictureIds = this.form.itineraryItemPictureIds.filter(id => id != chosenPicture.id)
      this.form.itineraryItemPictures = this.form.itineraryItemPictures.filter(picture => picture.id != chosenPicture.id)
      this.$emit("updateAllPicturesInDay", null, null, chosenPicture)
    },
    onPictureClick(picture) {
      const pictureCopy = {...picture, localizations: [...picture.localizations]} 
      sharedUtils.sortLocalizations(pictureCopy.localizations, this.primaryLanguage, this.editedLanguage)
      if (!this.form.itineraryItemPictureIds.includes(pictureCopy.id)) {
        this.form.itineraryItemPictureIds.push(pictureCopy.id)
        this.form.itineraryItemPictures.push(pictureCopy)
        this.$emit("updateAllPicturesInDay", null, pictureCopy, null )
      }
      this.$bvModal.hide('modal-item-picture-select' + this.itineraryDay.id + this.itineraryItem.id)
    },
    resetToDefault() {
      if (this.itineraryItem.id) {
        if (this.itineraryItem.transferTypeValues?.length > 1) {
          this.error.transfers = this.itineraryItem.transferTypeValues.map(transferTypeValue => ({transferType: '', length: ''}))
        }
        this.form = {
          id: this.itineraryItem.id,
          itineraryItemType: this.itineraryItem.itineraryItemType,
          title: this.itineraryItem.title,
          duration: this.itineraryItem.duration,
          difficulty: this.itineraryItem.difficulty,
          warning: this.itineraryItem.warning ? this.itineraryItem.warning : null,
          itemNumber: this.itineraryItem.itemNumber,
          description: this.itineraryItem.description,
          countries: this.itineraryItem.countries.map(country => country.id),
          countriesObjects: this.itineraryItem.countries,
          transferTypeValues: !this.itineraryItem.transferTypeValues || this.itineraryItem.transferTypeValues.length < 1 ? [{transferType: '', length: "0"}] :this.itineraryItem.transferTypeValues.map(transfer => {return {transferType: transfer.transferType, length: transfer.length.toString()}}),
          location: {
            latitude: this.itineraryItem.location ? this.itineraryItem.location.latitude : null,
            longitude: this.itineraryItem.location ? this.itineraryItem.location.longitude: null
          },
          itineraryItemPictureIds: this.itineraryItem.itineraryItemPictures.map(p => p.id),
          itineraryItemPictures: this.itineraryItem.itineraryItemPictures.map(picture => ({...picture, localizations: [...picture.localizations]})),
          localizations: this.itineraryItem.localizations.map(localization => {
            const localizationCopy = {...localization, language: localization.language ? localization.language : 'cs' }
            delete localizationCopy['__typename'];
            return localizationCopy
          })
        }

        // TODO is this neccessary??
        if (this.localizationCodes?.length > this.form.localizations?.length) {
          const formLocalizationCodes = this.form.localizations.map(localization => localization.language)
          this.localizationCodes.forEach(code => {
            if (!formLocalizationCodes.includes(code)) {
              this.form.localizations.push({
                language: code,
                title: '',
                description: '',
                warning: ''
              })
            }
          })
        }
        this.sortLocalizations()
        this.$emit("updateAllPicturesInDay", this.itineraryItem.itineraryItemPictures)
      }
      this.lastSavedForm = this.createCopyOfForm(this.form)
        // if (this.index != null) this.$store.commit('setItemForms', {form: this.form, index: this.index, dayId: this.itineraryDay.id})
    },
    createCopyOfForm(form) {
      return {
        ...form, 
        itineraryItemPictureIds: [...form.itineraryItemPictureIds],
        location: {...form.location},
        transferTypeValues: Array.from(form.transferTypeValues, transferType => ({...transferType, length: transferType.length.toString()})),
        localizations: form.localizations.map(localization => {return {...localization}})
      }
    },
    setTransferType(transferType, index) {
      this.form.transferTypeValues[index].transferType = transferType
      this.$store.commit('setTransferTypeValues', {transferTypeValues: this.form.transferTypeValues.slice(), dayId: this.itineraryDay.id, itemIndex: this.index})
      const isLocationSet = this.form.location.longitude != null && this.form.location.latitude != null
      if (isLocationSet) {
        this.$emit('reloadMap')
      }
      this.$refs['transfers-dropdown'][index].hide(true)
    },
    addTransferType() {
      this.form.transferTypeValues.push({transferType: "", length: 0})
      this.error.transfers.push({transferType: '', length: ''})
    },
    removeTransferType(index) {
      const isTypeOfTransferChosen = this.form.transferTypeValues[index].transferType != ''
      this.form.transferTypeValues.splice(index, 1)
      if (isTypeOfTransferChosen) {
        this.$store.commit('setTransferTypeValues', {transferTypeValues: this.form.transferTypeValues.slice(), dayId: this.itineraryDay.id, itemIndex: this.index})
        this.$emit('reloadMap')
      }
    },

    removeLocalizationInputErrors() {
      for (let key in this.error) {
        if (key != 'transfers') {
          this.error[key] = ''
        }
      }
    },
    
    validateInputs() {
      let errorCount = 0
      this.removeLocalizationInputErrors()
      const GPSInputList = ['GPSlat' + this.itineraryDay.id + this.itineraryItem.id, 'GPSlong' + this.itineraryDay.id + this.itineraryItem.id] 
      GPSInputList.forEach(v => {if (this.validateGPS(v)) errorCount += 1})

      if (this.itineraryItem.itineraryItemType !== 'EMPTY') {
        if (this.form.itineraryItemType !== 'START') {
          errorCount += this.validateTransfers()
          if (!this.form.difficulty) {
            errorCount += 1
            this.error.difficulty = this.$t('inputErrors.required')
          }
        }
        if (this.form.countries.length < 1) {
          errorCount += 1
          this.error.countries = this.$t('inputErrors.required')
        }

        const localizations = this.isManagingLocalizations ? this.form.localizations[1] : this.form.localizations[0]
        if (localizations) {
          const { title, description } = localizations
          if (!title) {
            errorCount += 1
            this.error.title = this.$t("inputErrors.required")
          }
          if (!description && this.form.itineraryItemType === "DEFAULT") {
            errorCount += 1
            this.error.description = this.$t("inputErrors.required")
          }

          if (errorCount > 0) {
            this.form.localizations[this.isManagingLocalizations ? 1 : 0].translated = false
          }
        }
        if (!['START', 'FINISH'].includes(this.form.itineraryItemType) && this.isInCurrentItineraryItemList()) errorCount += 1
      }
      
      this.$root.$emit('addErrorsInForm', errorCount)
      if (errorCount > 0) {
        this.$store.commit('addErrorId', this.itineraryItem.id)
        const collapseDayElement = document.querySelector('#collapseDayInfo' + this.itineraryDay.id)
        const collapseItemsElement = document.querySelector('#collapseItemsList' + this.itineraryDay.id)
        
        if (!collapseDayElement.classList.contains('show')) {this.$root.$emit('bv::toggle::collapse', 'collapseDayInfo'+this.itineraryDay.id)}
        if (!collapseItemsElement.classList.contains('show')) {this.$root.$emit('bv::toggle::collapse', 'collapseItemsList' + this.itineraryDay.id)}
      }

      return errorCount > 0 ? false : true
  },
    onSubmit: async function (validateForms = false, e) {
      if (!this.form.itemNumber) this.form.itemNumber = 0
      if (!this.form.duration) this.form.duration = null
      if (!this.form.location.longitude || !this.form.location.latitude) {
        this.form.location = {longitude: null, latitude: null}
        this.$root.$emit(`updateLocation${this.itineraryItem.id}`, {longitude: this.form.location.longitude, latitude: this.form.location.latitude})
      }

      this.deleteEmptyTransfers()
      this.alert.dismissCountDown = this.alertDanger.dismissCountDown = 0
        if (this.itineraryItem.id == null) {
          this.loading = this.$t("loading.itemSave")
          await ItineraryService.createItineraryItem(this.itineraryDay.id, this.form, )
            .then((result) => this.onCreate(result))
            .catch(err => {
              console.log(err)
              this.showAlertDanger(this.$t("alerts.defaultItemCreateError"))
            }); 
        } else {
          await ItineraryService.updateItineraryItem(this.itineraryDay.id, this.form)
          .then(() => this.onUpdate())
          .catch(err => {
            console.log(err)
            this.showAlertDanger(this.$t("alerts.defaultItemUpdateError"))
          });
        }      
    },
    deleteEmptyTransfers() {
      let transfersLength = this.form.transferTypeValues.length
      this.form.transferTypeValues = this.form.transferTypeValues.filter((transfer, index) => {
        if (transfersLength > 1 && transfer.transferType == '') {
          transfersLength -= 1
          return false
        }
        return true
      })
    },

    onDeleteConfirmed: async function () {
      if (this.ammountOfEmptyPointsAfterDeletingItem() > 9) {
        this.ammountOfEmptyPointsBeingDeleted = this.ammountOfEmptyPointsAfterDeletingItem()
        this.loadingDelete = this.$t("loading.emptyItemsDelete")
        let idsToDelete = []
        for (let i = this.index -1; i >= 0; i--) {
          if (i < 0) break
          if (this.allItineraryItems[i].itineraryItemType != 'EMPTY') break
          idsToDelete.push(this.allItineraryItems[i].id)
        }
        for (let i = this.index + 1; i < this.allItineraryItems.length; i++) {
          if (this.allItineraryItems[i].itineraryItemType != 'EMPTY') break
          idsToDelete.push(this.allItineraryItems[i].id)
        }

        for (const itemId of idsToDelete) {
          await ItineraryService.deleteItineraryItem(itemId, this.itineraryDay.id)
            .then(() => {
              this.$store.commit('deleteItineraryItem', {dayId: this.itineraryDay.id, itemId: itemId})
            })
            .catch(err => {
              console.log(err)
              this.$bvModal.hide('modal-delete-itineraryItem' + this.itineraryItem.id)
              this.showAlertDanger(this.$t("alerts.defaultItemDeleteError"))
            })
        }
      }
      const isEmptyItem = this.itineraryItem.itineraryItemType == 'EMPTY'
      this.loadingDelete = isEmptyItem ? this.$t("loading.emptyItemDelete") : this.$t("loading.itemDelete")
      await ItineraryService.deleteItineraryItem(this.itineraryItem.id, this.itineraryDay.id)
        .then(() => {
          this.$store.commit('deleteItineraryItem', {dayId: this.itineraryDay.id, itemId: this.itineraryItem.id})
          this.$root.$emit('showAlertFromItem', isEmptyItem ? this.$t('alerts.emptyItemDeleted') : this.$t('alerts.defaultItemDeleted'))
          this.$emit('saveItems')
          this.$emit('checkDayPicture', this.form.itineraryItemPictures)
        })
        .catch(err => {
          console.log(err)
          this.$bvModal.hide('modal-delete-itineraryItem' + this.itineraryItem.id)
          this.showAlertDanger(this.$t("alerts.defaultItemDeleteError"))
        })
        this.ammountOfEmptyPointsBeingDeleted = null
        this.loadingDelete = ''
    },

    onCreate: function (result) {
      this.loading = ''
      this.$root.$emit('showAlertFromItem', this.$t("alerts.defaultItemCreated"));
      this.itineraryItem.id = result.data.createItineraryItem.id;
      this.$emit("itinerary-item-created", this.form);
      this.resetToDefault()
    },

    onUpdate: function () {
      this.lastSavedForm = this.createCopyOfForm(this.form)
      this.loading = ''
      this.$emit("itinerary-item-updated", this.form);
    },

    countDownChanged: function (dismissCountDown) {
      this.alert.dismissCountDown = dismissCountDown
    },

    countDownChangedDanger: function (dismissCountDown) {
      this.alertDanger.dismissCountDown = dismissCountDown
    },

    showAlert: function (notification) {
      this.$emit('showAlert', notification)
    },

    showAlertDanger: function (notification) {
      this.$emit('showAlertDanger', notification)
    },
    validateTransfers() {
      let errorCount = 0
      if (this.form.transferTypeValues.length == 1 && !this.form.transferTypeValues[0].transferType) {
        this.error.transfers[0].transferType = this.$t("inputErrors.required")
        return 1
      }

      this.error.transfers = this.form.transferTypeValues.map(t => {
        return {transferType: '', transferLength: ''}
      })

      this.form.transferTypeValues.forEach((transferValues, index) => {
        if (!transferValues.transferType) {
          errorCount += 1
          this.error.transfers[index].transferType = this.$t("inputErrors.required")
        }
        if (!transferValues.length && transferValues.length != 0) {
          errorCount += 1
          this.error.transfers[index].transferLength = this.$t("inputErrors.required")
        }
        else if (transferValues.length <= 0) {
          this.error.transfers[index].transferLength = this.$t('inputErrors.valueMustBeLargerThanZero')
          errorCount += 1
        }
      })
      return errorCount
    },
    validateGPS(gpsInputElement) {
      const input = document.getElementById(gpsInputElement)
      this.GPSErrors[gpsInputElement ==  "GPSlat" + this.itineraryDay.id + this.itineraryItem.id ? 'GPSlat' : 'GPSlong'] = this.$t("inputErrors.required")
      if (!input) return
      if (!input.value) {
        input.classList.add("error")
        if (this.itineraryItem.itineraryItemType == 'EMPTY') this.$emit('missingEmptyItemLocation')
        return true
      }
      else input.classList.remove("error")

      if (input.id == "GPSlat" + this.itineraryDay.id + this.itineraryItem.id && (input.value < -90 || input.value > 90)) {
        this.GPSErrors.GPSlat = this.$t("inputErrors.latitudeRange")
        input.classList.add('error')
        return true
        
      }
      else if (input.value < -180 || input.value > 180) {
        this.GPSErrors.GPSlong = this.$t("inputErrors.longitudeRange")
        input.classList.add('error')
        return true
      } else {
        input.classList.remove('error')
      }
    },

    isInCurrentItineraryItemList: function () {
      let output = false
      this.itemNumberError = this.$t("inputErrors.required")
      const itemNumberInput = document.getElementsByClassName('itemInput' + this.itineraryDay.id + this.itineraryItem.id)[0]
      if (!this.itineraryDay) return output
      if (this.form.itemNumber < 1) {
        itemNumberInput.classList.add('error')
        this.itemNumberError = this.$t("inputErrors.sequenceNumberNotNegative")
        return true
      }
      if (this.form.itemNumber.length > 3) {
        itemNumberInput.classList.add('error')
        this.itemNumberError = this.$t("inputErrors.sequenceNumberTooHigh")
        return true
      }
      if (!this.form.title || !this.form.title.toUpperCase().includes('VARIANTA')) {
        this.allItineraryItems.forEach((itineraryItem) => {
          if (['FINISH', 'START'].includes(itineraryItem.itineraryItemType)) return
          if (this.form.itemNumber == itineraryItem.itemNumber && this.itineraryItem.id !== itineraryItem.id) {
            itemNumberInput.classList.add('error')
            this.itemNumberError = this.$t("inputErrors.sequenceNumberUnique")
            output = true
          }
        });
      }
      return output
    },
  },

  watch: {
    editedLanguage() {
      this.sortLocalizations()
    },
    primaryLanguage() {
      this.sortLocalizations()
    },
    'itineraryItem.itemNumber'(nV) {
      this.form.itemNumber = nV
    },
    'form.countries'(nV) {
      this.countriesSearchString = ''
      this.onInputChange(true)

      if (this.filteredCountries.length) {
        this.form.countriesObjects = this.filteredCountries.slice(0, nV.length)
      }
      if (nV.length == 1) {
        if (!this.countries) return
        let country = this.countries.find(c => c.id == this.form.countries[0])
        if (!country) return
        this.preselectedCountry = country  
      }
    },
    'form.transferTypeValues': {
      handler(nV) {
        this.$store.commit('setTransferTypeValues', {transferTypeValues: this.form.transferTypeValues.slice(), dayId: this.itineraryDay.id, itemIndex: this.index})
        const isLocationSet = this.form.location.longitude != null && this.form.location.latitude != null
        if (isLocationSet) {
          this.$emit('reloadMap')
        }
      },
      deep: true
    },
    disableInputs(nV) {
      this.checkState()
    },
  },

  apollo: {
    countries: {
      query : COUNTRIES,
    },
  },
}
</script>

<style scoped lang="scss">
    @import '@/scss/variables';

.forms-container {
  padding-block: 50px 40px;
  gap: 30px;

  form {
    width: 100%;

    .form-content-container {
      display: flex;
      flex-direction: column;
      gap: 30px;
    }

  }

  &.visible-localizations {
    display: flex;
    flex-wrap: wrap;

    form {
      width: calc(50% - 15px);
    }
  }
}

.fullscreen-picture-description {
  position: absolute;
  max-width: calc(100% - 40px);
  width: max-content;
  z-index: 1;
  top: -35px;
  left: 50%;
  transform: translateX(-50%);
  background-color: $tr-white-alt;
  padding: 3px 10px;
}
.pictures-container {
  position: relative;
  max-width: 1450px;
  width: 100%;
  grid-gap: 50px 40px;
  margin-right: 40px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, auto));
  gap: 40px;

  .picture-container {
    position: relative;
    aspect-ratio: 4/3;
    
    figure {
      margin-bottom: 0;
      height: 100%;
    }

    img {
      object-fit: cover;
    }
  
    .x {
      right: -10px;
      top: -10px;
      z-index: 1
    }
  
    .sort-icon {
      background-image: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(%23a)" fill="%23222"><circle cx="9" cy="6" r="1"/><circle cx="16" cy="6" r="1"/><circle cx="9" cy="12" r="1"/><circle cx="16" cy="12" r="1"/><circle cx="9" cy="18" r="1"/><circle cx="16" cy="18" r="1"/></g><defs><clipPath id="a"><path fill="%23fff" d="M0 0h24v24H0z"/></clipPath></defs></svg>');
      background-size: 40px;
      background-repeat: no-repeat;
      background-position: center;
      height: 50px;
      width: 50px;
      cursor: move;
      position: absolute;
      z-index: 1;
      background-color: rgba(255, 255, 255, 0.85);
    }

    .icon-with-tooltip-container {
      height: 50px;
      width: 50px;
      cursor: pointer;
      position: absolute;
      z-index: 1;
      left: 60px;
      z-index: 1;

      &.order-picture {
        left: 0;
      }

      .picture-of-the-day-icon {
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.85);
        padding: 3px;
  
        &.active {
          border: 4px solid $tr-black;
          background: $tr-green;
          padding: 0;
        }
      }
    }
  }
  .add-item-picture {
    aspect-ratio: 4/3;
    border: dashed 3px $tr-light-gray;
  }
}

.dragging {
  cursor: pointer;
  opacity: 0.5;
  
  &:active {
    cursor: pointer;
  }
}


.info-icon:hover .info-content {
  display: block !important;
}
.info-icon.difficulty {
  position: absolute;
  top: 22px;
  right: 20px;

  &::after {
    background-color: transparent;
    top: -17px;
    height: 65px;
    width: 40px;
    left: calc(100% - 17px);
  }
  & .info-content {
    display: none;
    white-space: pre-line;
    position: absolute;
    top: -17px;
    left: calc(100% + 23px);
    width: 260px;
    height: fit-content;
    background-color: $tr-black;
    color: $tr-white;
    padding: 20px;
  
    & > span {
      color: $tr-green;
      cursor: pointer;
      
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.filters-container {
  margin-bottom: 20px;
  gap: 20px;
}

button.add-transfer, button.remove-transfer-button {
  background-color: transparent;
}
.transport-container {
  display: flex;
  align-items: flex-start;
  gap: 30px;

  .remove-transfer-button {
    border: none;

    & div {
      transform: rotate(45deg);
      font-size: 40px;
      margin-top: 14px;
      line-height: 30px;
      text-align: center;
      font-weight: 400;
      cursor: pointer;
    }
  }
}

.add-transfer {
  width: fit-content;
  display: flex;
  align-items: center;
  cursor: pointer;
  border: dashed 3px $tr-light-gray;
  height: 60px;
  width: 330px;
  padding-left: 10px;
  
  & div {
    display: flex;
    font-size: 21px;
    line-height: 10px;
    justify-content: center;
    align-items: center;
    height: 25px;
    width: 25px;
    background-color: $tr-black;
    color: $tr-white;
    border-radius: 50%;
    margin-right: 10px;
  }
}

.no-interaction button, .no-interaction .add-item-picture, .no-interaction .x {
  display: none !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

input[type=datetime-local] {
  padding: 5px;
}

.itinerary-item-icon {
  background-image: url("data:image/svg+xml,%3Csvg fill='none' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23a)'%3E%3Cpath d='m17 26v8h-10v-8h2v1.5h2v-1.5h2v1.5h2v-1.5h2z' stroke='%23222'/%3E%3Cpath d='m32.5 11.482c0 0.4937-0.2332 1.1836-0.6478 1.9904-0.4063 0.7904-0.9534 1.6307-1.5086 2.4056-0.554 0.7731-1.1091 1.4715-1.5262 1.9772-0.1181 0.1431-0.2249 0.2706-0.3174 0.3798-0.0925-0.1092-0.1993-0.2367-0.3174-0.3798-0.4171-0.5057-0.9722-1.2041-1.5262-1.9772-0.5552-0.7749-1.1023-1.6152-1.5086-2.4056-0.4146-0.8068-0.6478-1.4967-0.6478-1.9904 0-2.1974 1.789-3.9821 4-3.9821s4 1.7847 4 3.9821z' stroke='%23222'/%3E%3Ccircle cx='29' cy='21' r='1' fill='%23222'/%3E%3Ccircle cx='29' cy='24' r='1' fill='%23222'/%3E%3Ccircle cx='29' cy='30' r='1' fill='%23222'/%3E%3Ccircle cx='29' cy='33' r='1' fill='%23222'/%3E%3Ccircle cx='21' cy='12' r='1' fill='%23222'/%3E%3Ccircle cx='26' cy='33' r='1' fill='%23222'/%3E%3Ccircle cx='18' cy='12' r='1' fill='%23222'/%3E%3Ccircle cx='29' cy='27' r='1' fill='%23222'/%3E%3Ccircle cx='23' cy='33' r='1' fill='%23222'/%3E%3Ccircle cx='15' cy='12' r='1' fill='%23222'/%3E%3Ccircle cx='20' cy='33' r='1' fill='%23222'/%3E%3Cpath d='m9.5 9.6009 2 1.3334v2.5657h-4v-2.5657l2-1.3334z' stroke='%23222'/%3E%3Ccircle cx='28.5' cy='11.5' r='1.5' stroke='%23222'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Crect width='40' height='40' fill='%23fff'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
  background-size: 40px 40px;
  background-repeat: no-repeat;
  background-position: center center;
  width: 50px;
  height: 50px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 51px;
  text-align: center;
  color: $tr-black;
  margin: 0 auto;

  &.start-icon {
    background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23a)'%3E%3Cpath d='m16 22 13-5-13-5v10Zm0-12v24' stroke='%23222'/%3E%3Ccircle cx='25' cy='33' r='1' fill='%23222'/%3E%3Ccircle cx='22' cy='33' r='1' fill='%23222'/%3E%3Ccircle cx='19' cy='33' r='1' fill='%23222'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath fill='%23fff' d='M0 0h40v40H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
  }
  &.finish-icon {
    background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23a)'%3E%3Cpath d='M34 12v10H16V12h18Zm-18-2v24' stroke='%23222'/%3E%3Ccircle cx='13' cy='33' r='1' fill='%23222'/%3E%3Ccircle cx='10' cy='33' r='1' fill='%23222'/%3E%3Ccircle cx='7' cy='33' r='1' fill='%23222'/%3E%3Cpath fill='%23222' d='M16 12h3v2h-3zm3 2h3v2h-3zm-3 2h3v2h-3zm3 2h3v2h-3zm-3 2h3v2h-3zm6-8h3v2h-3zm3 2h3v2h-3zm-3 2h3v2h-3zm3 2h3v2h-3zm-3 2h3v2h-3zm6-8h3v2h-3zm3 2h3v2h-3zm-3 2h3v2h-3zm3 2h3v2h-3zm-3 2h3v2h-3z'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath fill='%23fff' d='M0 0h40v40H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
  }
}

.dropdown-input {
  height: 60px;
  width: 100%;
  padding-inline: 20px;
  border: none;
  border-bottom: solid 2px $tr-gray;
  background-color: $tr-white;
}

.copy-icon {
  height: 50px;
  width: 50px;
  margin-top: 5px;
  background: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.8 18.588h-1.249v1.306H5.25V6.506h1.249V5.2H4v16h12.8v-2.612Z" fill="%23222"/><path d="M8.267 2v14.933H20v-9.59L14.963 2H8.267Zm10.56 13.689H9.44V3.244h3.813v5.912h5.574v6.533Zm0-7.778h-4.4V3.244h.05l4.35 4.614v.053Z" fill="%23222"/></svg>');
  background-size: 40px;
  background-position: center;
  background-repeat: no-repeat;
  aspect-ratio: 1;
  border: 2px solid $tr-black;
  
  &:hover {
    background-color: #f7f7f7;
  }
}

</style>

<style lang="scss">

.difficulty-select .dropdown button.btn.dropdown-toggle::after {
  right: 50px;
}

.map-with-search-container {

  & .zoom .button-group{
      box-shadow: 0 0 2px 0 rgb(0 0 0 / 65%) !important;
  }

  & .smap-suggest {
    position: absolute;
    max-width: 330px;
    top: 37px;
    left: 10px;
  }
  .smap img[src*="//api.mapy.cz/v"]{
    filter: grayscale(100%);
}
}
</style>
