<template>
  <div class="picture-list-container grid font-weight-bold text-left m-0">
    <Picture v-for="picture in pictures" :key="picture.id" :picture="picture" @onPictureClick="onPictureClick" :imgURL="imgURL"/>
    <router-link v-if="!travelTipPictures" to='/admin/managePicture' style="position: absolute; bottom: 0; right: 80px">
      <div class="plus"></div>
    </router-link>
  </div>
</template>

<script>
import {CLEVERTRIP_IMG_URL}  from '@/definitions';
import Picture from "./Picture.vue";

export default {
  name: "PictureList",

  components: {
    Picture
  },

  props: {
    travelTipPictures: Boolean,
    pictures: Array,
  },

  data() {
    return {
      imgURL: CLEVERTRIP_IMG_URL
    }
  },

  methods: {
    onPictureClick: function (picture) {
      if (this.travelTipPictures) {
        this.$emit('onPictureClick', picture)
      }
      else this.$router.push({ name: "adminManagePicture", params: { pictureId: picture.id } });
    },
  }
}
</script>

<style scoped lang="scss">

.plus {
  position: fixed;
  bottom: 60px;
}

.picture-list-container {
  position: relative;
  max-width: 1440px;
  width: 100%;
  grid-gap: 40px 30px;
  margin-right: 40px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, auto));
}

</style>
