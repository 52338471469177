import gql from 'graphql-tag'

export const GET_ITINERARY_ITEMS_BY_ITINERARY_DAY_ID = gql`
    query GetItineraryItemsQuery($itineraryDayId: Int!) {
        itineraryItems: itineraryItemsAdmin(itineraryDayId: $itineraryDayId) {
            id
            itineraryItemType
            duration
            difficulty
            itemNumber
            localizations {
                language
                title,
                description,
                warning,
                translated
            }
            transferTypeValues {
                transferType
                length
            },
            location {
                latitude
                longitude
            }
            countries {
                id
                code
                nameCzech
                nameEnglish
            }
            itineraryItemPictures {
                id
                fileName
                originalLanguage
                localizations {
                    language
                    title
                }
            }
        }
    }`;