<template>
    <div>
      <a v-if="!isManagingLocalizations" class="collapse-toggle" v-b-toggle.collapseEquipments>{{ $t("general.equipments") }} ({{chosenTravelTipEquipments.length}})</a>
      <b-collapse v-if="!isManagingLocalizations" id="collapseEquipments" style="padding-top: 30px" >
        <div class="d-flex align-items-center mb-3" v-if="isAuthorizedToEdit">
          <h3 class="tr__h3 mb-0">{{ $t("admin.templates") }}</h3>
          <div class="ml-2 info-icon d-inline-block" v-tooltip="{content: $t('info.equipmentTemplates'), placement: 'right-start'}"></div>
        </div>


        <div class="template-list mb-4" v-if="isAuthorizedToEdit">
          <TrekioButton @click="applyTemplate(template)" height="30px" width="auto" secondary v-for="template in templatesWithEquipmentInPrimaryLanguage" :key="template.id" v-tooltip.right-start="template.equipments.map(eq => '- ' + eq.localizations[0].title).join('\n')">
            {{ template.localizations[0].title }}
          </TrekioButton>
        </div>
        <div class="equipment-buttons" v-if="isAuthorizedToEdit">
          <TrekioButton secondary @click="clearEquipments">{{ $t("buttons.clear") }}</TrekioButton>
          <TrekioButton @click="$bvModal.show('equipment-list-modal')">{{ $t("buttons.addEquipment") }}</TrekioButton>
        </div>
        <div class="px-3 pb-5">
          <div v-for="category in chosenEquipmentsByCategories" :key="category.id">
            <div class="category-container">

              <p class="category-title" :class="{'underline font-weight-bold': category.id != -1 && !category.localizations.find(loc => loc.language === primaryLanguage).title}" 
                v-tooltip="category.id != -1 && !category.localizations.find(loc => loc.language === primaryLanguage).title ? category.localizations.filter(loc => loc.language != primaryLanguage && loc.title).map(loc => (`${loc.language}: ${loc.title}`)).join('\n') : null"
              >
                {{
                  category.id === -1 ? $t("general.other") :
                  primaryLanguage && category.localizations.find(loc => loc.language === primaryLanguage).title ?
                  category.localizations.find(loc => loc.language === primaryLanguage).title : $t("admin.notTranslated")
                }}
              </p>
              <ul>
                <li class="equipment" v-for="equipment in category.equipments.filter(eq => eq.quantity > 0)" :key="equipment.id">
                  <button class="img-button remove-equipment-button" @click="equipment.quantity = 0"></button>
                  <input type="number" v-model="equipment.quantity" @click.stop="" min="0" max="20" placeholder="0x">
                  <Checkbox v-tooltip.top="$t('info.markAsImportant')" class="checkbox" v-model="equipment.importance"/>
                  <p :class="{'underline font-weight-bold': !equipment.localizations.find(loc => loc.language === primaryLanguage).title}" 
                    v-tooltip="!equipment.localizations.find(loc => loc.language === primaryLanguage).title ? equipment.localizations.filter(loc => loc.language != primaryLanguage && loc.title).map(loc => (`${loc.language}: ${loc.title}`)).join('\n') : null"
                  >
                    {{
                      primaryLanguage && equipment.localizations.find(loc => loc.language === primaryLanguage).title ?
                      equipment.localizations.find(loc => loc.language === primaryLanguage).title : $t("admin.notTranslated")
                    }}
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <b-modal id="equipment-list-modal" hide-footer hide-header>
          <TrekioInput class="mb-4" white-bg id="equipment-search" searchInput v-model="equipmentSearchString"></TrekioInput>
          <button @click="$bvModal.hide('equipment-list-modal')" class="img-button close-modal"></button>
          <div class="category-container" v-for="category in filteredEquipmentList" :key="category.id">
            <div v-if="category.equipments.filter(eq => eq.quantity === 0).length > 0">
              <p class="category-title" :class="{'underline font-weight-bold': category.id != -1 && !category.localizations.find(loc => loc.language === primaryLanguage).title}" 
                v-tooltip="category.id != -1 && !category.localizations.find(loc => loc.language === primaryLanguage).title ? category.localizations.filter(loc => loc.language != primaryLanguage && loc.title).map(loc => (`${loc.language}: ${loc.title}`)).join('\n') : null"
              >
                {{
                  category.id === -1 ? $t("general.other") :
                  primaryLanguage && category.localizations.find(loc => loc.language === primaryLanguage).title ?
                  category.localizations.find(loc => loc.language === primaryLanguage).title : $t("admin.notTranslated")
                }}
              </p>
              <ul>
                <li v-for="equipment in category.equipments.filter(eq => eq.quantity === 0)" :key="equipment.id"
                  class="equipment-to-add" :class="{active: equipment.quantity > 0, }" 
                >
                  <button @click="addEquipment(equipment)"                       
                    v-tooltip="!equipment.localizations.find(loc => loc.language === primaryLanguage).title ? equipment.localizations.filter(loc => loc.language != primaryLanguage && loc.title).map(loc => (`${loc.language}: ${loc.title}`)).join('\n') : null"
                  >
                    <p :class="{'underline font-weight-bold': !equipment.localizations.find(loc => loc.language === primaryLanguage).title}" 
                    >
                      {{
                        primaryLanguage && equipment.localizations.find(loc => loc.language === primaryLanguage).title ?
                        equipment.localizations.find(loc => loc.language === primaryLanguage).title : $t("admin.notTranslated")
                      }}
                    </p>
                    <span>+</span>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </b-modal>
      </b-collapse>
    </div>
</template>

<script>
  import Checkbox from '../Checkbox.vue';
  import { EQUIPMENT_TEMPLATES_ADMIN, TRAVEL_TIP_EQUIPMENTS } from "../../api/graphql/query/EquipmentQuery";
  import { EQUIPMENTS_ADMIN } from "../../api/graphql/query/EquipmentQuery";
  import EquipmentService from '../../services/EquipmentService';
  import sharedUtils from '../../utils/sharedUtils';

    export default {
        props: {
            primaryLanguage: {
              type: String
            },
            isManagingLocalizations: {
              type: Boolean
            },
            preferredLanguage: {
              type: String
            },
            travelTipId: {
              type: Number
            },
            isCurrentUserTipAuthor: {
              type: Boolean
            },
            state: {
              type: String
            }
        },

        data() {
          return {
            equipmentListByCategories: [],
            equipmentSearchString: "",
          }
        },

        components: {
            Checkbox
        },

        computed: {
          filteredEquipmentList() {
            if (!this.equipmentListByCategories) return []
            if (this.equipmentSearchString != "") {
              const filteredEquipmentList = []
              this.equipmentListByCategories.forEach(category => {
                filteredEquipmentList.push({
                  ...category,
                  equipments: category.equipments.filter(eq => {
                    const equipmentTitle = eq.localizations.find(loc => loc.language === this.primaryLanguage).title
                    return sharedUtils.removeAccents(equipmentTitle).toLowerCase().includes(sharedUtils.removeAccents(this.equipmentSearchString).toLowerCase())
                  })
                })
              })
              return filteredEquipmentList
            }
            return this.equipmentListByCategories
          },
          chosenEquipmentsByCategories() {
            if (this.equipmentListByCategories.length === 0) return []
            return this.equipmentListByCategories.filter(category => category.equipments.some(eq => eq.quantity > 0))
          },
          isAuthorizedToEdit() {
              const userRole = this.$store.state.currentUserRole
              const isSuperuser = userRole === "ADMIN_SUPERVISOR"
              const isAuthorCondition = userRole === "AUTHOR" && [null, 'IN_PROGRESS', 'TO_REWORK'].includes(this.state);
              const isAdminCondition = userRole === "ADMIN" && (
                  !['TO_APPROVE', 'DECLINED'].includes(this.state) ||
                  (this.isCurrentUserTipAuthor && [null, 'IN_PROGRESS', 'TO_REWORK'].includes(this.state))
              );

              return isSuperuser || isAuthorCondition || isAdminCondition;
          },
          templatesWithEquipmentInPrimaryLanguage() {
            if (!this.equipmentTemplatesAdmin || this.equipmentTemplatesAdmin.length == 0) return
            return this.equipmentTemplatesAdmin.map(template => {
                return {
                ...template,
                localizations: template.localizations.filter(loc => loc.language === this.primaryLanguage && loc.title !== ''),
                equipments: template.equipments.map(equipment => {
                    return {
                    ...equipment,
                    localizations: equipment.localizations.filter(loc => loc.language === this.primaryLanguage && loc.title !== '')
                    }
                }).filter(equipment => equipment.localizations.length > 0)
                }
            }).filter(template => {
                return template.localizations.length > 0 && template.equipments.length > 0 
            }).sort((a, b) => a.localizations[0].title.localeCompare(b.localizations[0].title))
          },
          chosenTravelTipEquipments() {
            if (!this.equipmentListByCategories) return
            return this.equipmentListByCategories.flatMap(category => category.equipments)
              .filter(eq => eq.quantity > 0)
          },
        },

        methods: {
          addEquipment(equipment) {
            equipment.quantity = 1
          },
          clearEquipments() {
            this.equipmentListByCategories.forEach(category => {
              category.equipments.forEach(eq => {
                  eq.quantity = 0
                  eq.importance = false
              })
            })
          },
          applyTemplate(template) {
            const equipmentIdsToSelect = template.equipments.map(eq => eq.id)
            this.equipmentListByCategories.forEach(category => {
              category.equipments.forEach(eq => {
                if (equipmentIdsToSelect.includes(eq.id)) {
                  eq.quantity = 1
                }
              })
            })
          },
          async setTravelTipEquipments() {
                await EquipmentService.assignEquipmentToTravelTip(this.travelTipId, this.chosenTravelTipEquipments)
                    .then((resp) => {
                        this.$apollo.queries.travelTipEquipments.refetch({
                            travelTipId: this.travelTipId
                        });
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
        },

        apollo: {
          travelTipEquipments: {
            query: TRAVEL_TIP_EQUIPMENTS,
            variables: function() {
              return {
                travelTipId: this.travelTipId
              }
            },
            skip: function() {
              return !this.equipmentsAdmin || !this.travelTipId
            },
            result({data}) {
              if (!this.equipmentsAdmin) return

              data.travelTipEquipments.forEach(travelTipEquipment => {
                const category = this.equipmentListByCategories.find(category => {
                  return category.id == (travelTipEquipment.equipment.category ? travelTipEquipment.equipment.category.id : -1)
                })
                let equipmentIndex = category.equipments.findIndex(equipment => equipment.id == travelTipEquipment.equipment.id)
                
                if (equipmentIndex !== -1) {
                  category.equipments[equipmentIndex].quantity = travelTipEquipment.quantity;
                  category.equipments[equipmentIndex].importance = travelTipEquipment.importance == "NICE_TO_HAVE" ? false : true
                }
              })
            }
          },

          equipmentsAdmin: {
            query: EQUIPMENTS_ADMIN,
            result({data}) {
              let categoriesArray = [];
              data.equipmentsAdmin.forEach((equipment) => {
                const categoryId = equipment.category?.id;

                const categoryObject = categoriesArray.find((category) => category.id === (categoryId ? categoryId : -1));

                if (!categoryId && !categoryObject) {
                  categoriesArray.push({
                      id: -1,
                      code: "DEFAULT",
                      equipments: [{...equipment, quantity: 0, importance: false}]
                    });
                }

                else if (!categoryObject) {
                    categoriesArray.push({
                      ...equipment.category,
                      id: equipment.category ? equipment.category.id : -1,
                      equipments: [{...equipment, quantity: 0, importance: false}],
                    });
                } else {
                  categoryObject.equipments.push({...equipment, quantity: 0, importance: false}) 
                }
              });
              categoriesArray.forEach(category => {
                category.equipments.sort((a, b) => {
                  return a.localizations.find(loc => loc.language === this.primaryLanguage).title.localeCompare(b.localizations.find(loc => loc.language === this.primaryLanguage).title)
                })
              })
              categoriesArray = categoriesArray.filter(category => category.equipments.length > 0)
              this.equipmentListByCategories = categoriesArray;
            }
          },

          equipmentTemplatesAdmin: {
            query: EQUIPMENT_TEMPLATES_ADMIN,
          }
        }
    }

</script>

<style lang="scss" scoped>
  @import '@/scss/variables';

  .template-list {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;

  }
  .equipment-buttons {
    display: flex;
    gap: 20px;
    margin-bottom: 30px;
  }

  .category-container {
    &:not(:last-of-type) {
      margin-bottom: 30px;
    }
    
      .category-title {
        display: inline-block;
        font-size: 20px;
        margin-bottom: 10px;
      }
    
      ul {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        padding-inline: 0;
        list-style: none;
        
        .equipment-to-add {
          border: 1px solid $tr-gray;
          flex-basis: 195px;
          
          button {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-inline: 10px;
            border: none;
            background: none;
            font-size: 20px;
            line-height: 20px;
            text-align: left;

            &:hover {
              background-color: $tr-light-gray;
            }

            p {
              margin-bottom: 0;
              margin-right: 10px;
              word-break: break-word;
            }
          }

        }

        .equipment {
          display: flex;
          align-items: center;
          min-height: 40px;
          flex-basis: 290px;

          .no-interaction & {
            opacity: 0.3;
            pointer-events: none;
          }

          .checkbox {
              margin-inline: 5px 10px;
          }
          
          p {
            margin: 0;
            word-break: break-word;
          }
          
          input {
            border: none;
            padding-left: 5px;
            background-color: #eee;
          }

          .remove-equipment-button {
            background-image: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(%23a)"><path d="M4.858 19.142 19 5" stroke="%23222"/><path d="M4.858 19.142 19 5M4.858 19.142 19 5M4.858 19.142 19 5M4.858 19.142 19 5" stroke="%23000" stroke-opacity=".2"/><path d="M19.142 19.142 5 5" stroke="%23222"/><path d="M19.142 19.142 5 5m14.142 14.142L5 5m14.142 14.142L5 5m14.142 14.142L5 5" stroke="%23000" stroke-opacity=".2"/></g><defs><clipPath id="a"><path fill="%23fff" d="M0 0h24v24H0z"/></clipPath></defs></svg>');
            background-size: 15px;
            width: 30px;
            height: 30px;
            background-repeat: no-repeat;
            background-position: center;
            flex-shrink: 0;
          }
        }
      }
    }

    .close-modal {
      background-image: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(%23a)"><path d="M4.858 19.142 19 5" stroke="%23222"/><path d="M4.858 19.142 19 5M4.858 19.142 19 5M4.858 19.142 19 5M4.858 19.142 19 5" stroke="%23000" stroke-opacity=".2"/><path d="M19.142 19.142 5 5" stroke="%23222"/><path d="M19.142 19.142 5 5m14.142 14.142L5 5m14.142 14.142L5 5m14.142 14.142L5 5" stroke="%23000" stroke-opacity=".2"/></g><defs><clipPath id="a"><path fill="%23fff" d="M0 0h24v24H0z"/></clipPath></defs></svg>');
      background-size: 30px;
      width: 30px;
      height: 30px;
      position: absolute;
      right: 40px;
      top: 40px;
      background-repeat: no-repeat;
      background-position: center;
    }


    .collapse-toggle {
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 25px;
    color: $tr-black;
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    border-bottom: dotted 3px $tr-light-gray;
    border-top: dotted 3px $tr-light-gray;
    height: 80px;
    cursor: pointer;

    &:hover{
      color: $tr-black;
      text-decoration: none;
    }

    &::before{
      content: "";
      position: relative;
      display: inline-block;
      width: 35px;
      height: 20px;
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='17' viewBox='0 0 24 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 5.5L10.5 14L22.5 2' stroke='%233BEA7E' stroke-width='3'/%3E%3C/svg%3E%0A");
      background-position: left top;
      background-repeat: no-repeat;
      background-size: 24px 17px;
    }

    &::after{
      content: "";
      position: relative;
      display: inline-block;
      width: 40px;
      height: 20px;
      background-position: left top;
      background-repeat: no-repeat;
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='15' viewBox='0 0 24 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M22 2L12 12L2 2' stroke='%23222222' stroke-width='3'/%3E%3C/svg%3E%0A");
      background-size: 24px 15px;
      margin-left: auto;
    }

    &[aria-expanded="true"]{
      &::after{
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='15' viewBox='0 0 24 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 13L12 3L22 13' stroke='%23222222' stroke-width='3'/%3E%3C/svg%3E%0A");
      }
    }
  }

  
</style>

<style>
  #equipment-list-modal .modal-body   {
    padding: 40px;
  }
</style>