import { apolloClient } from "@/api/apolloClient";
import { CREATE_EQUIPMENT, UPDATE_EQUIPMENT, DELETE_EQUIPMENT, CREATE_EQUIPMENT_CATEGORY, UPDATE_EQUIPMENT_CATEGORY, DELETE_EQUIPMENT_CATEGORY, CREATE_EQUIPMENT_TEMPLATE, UPDATE_EQUIPMENT_TEMPLATE, DELETE_EQUIPMENT_TEMPLATE, ASSIGN_EQUIPMENT_TO_TRAVEL_TIP, ASSIGN_EQUIPMENT_TO_USER_TRAVEL_TIP } from '../api/graphql/mutation/EquipmentMutation';
import { EQUIPMENTS_ADMIN, EQUIPMENT_CATEGORIES_ADMIN, EQUIPMENT_TEMPLATES_ADMIN, PERSONAL_EQUIPMENTS } from "../api/graphql/query/EquipmentQuery";
import { CREATE_PERSONAL_EQUIPMENT, UPDATE_PERSONAL_EQUIPMENT, DELETE_PERSONAL_EQUIPMENT } from '../api/graphql/mutation/EquipmentMutation';

export default {
  createEquipment: function (form) {
    console.log("Creating new equipment: " + form.title);
    const { categoryId, originalLanguage, localizations } = form;

    return apolloClient.mutate({
      mutation: CREATE_EQUIPMENT,
      variables: {
        categoryId,
        originalLanguage,
        localizations
      },
      update: (store, {data: {createEquipment}}) => {
        const data = store.readQuery({query: EQUIPMENTS_ADMIN})
        const newData = {equipmentsAdmin: [...data.equipmentsAdmin, createEquipment]}
        store.writeQuery({query: EQUIPMENTS_ADMIN, data: newData})
      }
    }).then((result) => {
      console.log("Equipment created: ", result);
      return result;
    });
  },

  updateEquipment: function (form, categoryToUpdate = null) {
    console.log("Updating equipment: " + form.equipmentId);
    const { equipmentId, categoryId, originalLanguage, localizations } = form;

    return apolloClient.mutate({
      mutation: UPDATE_EQUIPMENT,
      variables: {
        equipmentId,
        categoryId: categoryId,
        originalLanguage,
        localizations
      },
      update: (store, {data: {updateEquipment}}) => {
        const data = store.readQuery({query: EQUIPMENTS_ADMIN})
        const newData = {equipmentsAdmin: data.equipmentsAdmin.map(eq => {
          if (eq.id === equipmentId) {
            return {
              ...eq,
              localizations: localizations,
              category: categoryToUpdate ? categoryToUpdate : eq.category
            }
          }
          return eq
        })}
        store.writeQuery({query: EQUIPMENTS_ADMIN, data: newData})
      }
    }).then((result) => {
      console.log("Equipment updated: ", result);
      return result;
    });
  },

  deleteEquipment: function (equipmentId) {
    console.log("Deleting equipment: " + equipmentId);

    return apolloClient.mutate({
      mutation: DELETE_EQUIPMENT,
      variables: {
        equipmentId
      },
      update: (store, {data: {deleteEquipment}}) => {
        const data = store.readQuery({query: EQUIPMENTS_ADMIN})
        const newData = {equipmentsAdmin: data.equipmentsAdmin.filter(eq => eq.id !== equipmentId)}
        store.writeQuery({query: EQUIPMENTS_ADMIN, data: newData})
      }
    }).then((result) => {
      console.log("Equipment deleted: ", result);
      return result;
    });
  },

  createEquipmentCategory: function (form) {
    console.log("Creating new equipment category: " + form.title);
    const { originalLanguage, localizations, iconId } = form;

    return apolloClient.mutate({
      mutation: CREATE_EQUIPMENT_CATEGORY,
      variables: {
        originalLanguage,
        localizations,
        iconId
      },
      update: (store, {data: {createEquipmentCategory}}) => {
        let data = store.readQuery({query: EQUIPMENT_CATEGORIES_ADMIN})
        const newData = {equipmentCategoriesAdmin: [createEquipmentCategory, ...data.equipmentCategoriesAdmin]}
        store.writeQuery({query: EQUIPMENT_CATEGORIES_ADMIN, data: newData})
      }
    }).then((result) => {
      console.log("Equipment category created: ", result);
      return result;
    });
  },

  updateEquipmentCategory: function (form) {
    console.log("Updating equipment category: " + form.equipmentCategoryId);
    const { id, originalLanguage, localizations, iconId } = form;

    return apolloClient.mutate({
      mutation: UPDATE_EQUIPMENT_CATEGORY,
      variables: {
        equipmentCategoryId: id,
        originalLanguage,
        localizations,
        iconId
      },
    }).then((result) => {
      console.log("Equipment category updated: ", result);
      return result;
    });
  },

  deleteEquipmentCategory: function (equipmentCategoryId, equipmentIdsInCategory) {
    console.log("Deleting equipment category: " + equipmentCategoryId);

    return apolloClient.mutate({
      mutation: DELETE_EQUIPMENT_CATEGORY,
      variables: {
        equipmentCategoryId
      },
      update: (store, {data: {createEquipmentCategory}}) => {
        let data = store.readQuery({query: EQUIPMENT_CATEGORIES_ADMIN})
        const newData = {equipmentCategoriesAdmin: data.equipmentCategoriesAdmin.filter(category => category.id !== equipmentCategoryId)}
        store.writeQuery({query: EQUIPMENT_CATEGORIES_ADMIN, data: newData})
        let data2 = store.readQuery({query: EQUIPMENTS_ADMIN})
        if (!data2) return
        const newData2 = {equipmentsAdmin: data2.equipmentsAdmin.map(eq => {
          if (equipmentIdsInCategory.includes(eq.id)) {
            return {
              ...eq,
              category: null
            }
          }          
          return {
            ...eq
          }
        })}
        store.writeQuery({query: EQUIPMENTS_ADMIN, data: newData2})
      }
    }).then((result) => {
      console.log("Equipment category deleted: ", result);
      return result;
    });
  },

  createEquipmentTemplate: function (form) {
    console.log("Creating new equipment template: " + form.title);
    const { equipmentIds, originalLanguage, localizations } = form;

    return apolloClient.mutate({
      mutation: CREATE_EQUIPMENT_TEMPLATE,
      variables: {
        equipmentIds,
        originalLanguage,
        localizations
      },
      update: (store, {data: {createEquipmentTemplate}}) => {
        const data = store.readQuery({query: EQUIPMENT_TEMPLATES_ADMIN})
        const newData = {equipmentTemplatesAdmin: [createEquipmentTemplate, ...data.equipmentTemplatesAdmin]}
        store.writeQuery({query: EQUIPMENT_TEMPLATES_ADMIN, data: newData})
      }
    }).then((result) => {
      console.log("Equipment template created: ", result);
      return result;
    });
  },

  updateEquipmentTemplate: function (form) {
    console.log("Updating equipment template: " + form.equipmentTemplateId);
    const { equipmentTemplateId, equipmentIds, originalLanguage, localizations } = form;

    return apolloClient.mutate({
      mutation: UPDATE_EQUIPMENT_TEMPLATE,
      variables: {
        equipmentTemplateId,
        equipmentIds,
        originalLanguage,
        localizations
      },
    }).then((result) => {
      console.log("Equipment template updated: ", result);
      return result;
    });
  },

  deleteEquipmentTemplate: function (equipmentTemplateId) {
    console.log("Deleting equipment template: " + equipmentTemplateId);

    return apolloClient.mutate({
      mutation: DELETE_EQUIPMENT_TEMPLATE,
      variables: {
        equipmentTemplateId
      },
      update: (store, {data: {deleteEquipmentTemplate}}) => {
        const data = store.readQuery({query: EQUIPMENT_TEMPLATES_ADMIN})
        const newData = {equipmentTemplatesAdmin: data.equipmentTemplatesAdmin.filter(template => template.id != equipmentTemplateId)}
        store.writeQuery({query: EQUIPMENT_TEMPLATES_ADMIN, data: newData})
      }
    }).then((result) => {
      console.log("Equipment template deleted: ", result);
      return result;
    });
  },

  assignEquipmentToTravelTip: function (travelTipId, equipments) {
    console.log("Assigning equipment to travel tip: " + travelTipId);
    const equipmentsWrite = equipments.map(eq => {
      return {
        equipmentId: eq.id,
        quantity: eq.quantity,
        importance: eq.importance ? "MANDATORY" : "NICE_TO_HAVE"
      }
    })

    return apolloClient.mutate({
      mutation: ASSIGN_EQUIPMENT_TO_TRAVEL_TIP,
      variables: {
        travelTipId,
        equipments: equipmentsWrite
      },
    }).then((result) => {
      console.log("Equipment assigned to travel tip: ", result);
      return result;
    });
  },

  assignEquipmentToUserTravelTip: function (travelTipId, equipments) {
    console.log("Assigning equipment to travel tip: " + travelTipId);
    return apolloClient.mutate({
      mutation: ASSIGN_EQUIPMENT_TO_USER_TRAVEL_TIP,
      variables: {
        travelTipId,
        equipments
      },
    }).then((result) => {
      console.log("Equipment assigned to travel tip: ", result);
      return result;
    });
  },

  createPersonalEquipment: function (form) {
    console.log("Creating new equipment: " + form.title);
    const { equipmentCategoryId, title, productLink, travelTipId, quantity, settled, packed } = form;

    return apolloClient.mutate({
      mutation: CREATE_PERSONAL_EQUIPMENT,
      variables: {
        equipmentCategoryId,
        title,
        productLink,
        travelTipId,
        quantity,
        settled,
        packed
      },
      update: async (store, {data: {createPersonalEquipment}}) => {
        const data = await store.readQuery({query: PERSONAL_EQUIPMENTS, variables: {travelTipId: travelTipId}})
        const newData = {personalEquipmentsForCurrentUser: [...data.personalEquipmentsForCurrentUser, createPersonalEquipment]}
        store.writeQuery({query: PERSONAL_EQUIPMENTS, variables: {travelTipId: travelTipId}, data: newData})
      }
    }).then((result) => {
      console.log("Equipment created: ", result);
      return result;
    });
  },

  updatePersonalEquipment: function (form) {
    console.log("Updating equipment: " + form.personalEquipmentId);
    const { id, equipmentCategoryId, title, productLink, travelTipId, quantity, settled, packed } = form;

    return apolloClient.mutate({
      mutation: UPDATE_PERSONAL_EQUIPMENT,
      variables: {
        personalEquipmentId: id,
        equipmentCategoryId,
        title,
        productLink,
        quantity,
        settled,
        packed
      },
      update: (store, {data: {updatePersonalEquipment}}) => {
        const data = store.readQuery({query: PERSONAL_EQUIPMENTS, variables: {travelTipId}})
        const newData = {personalEquipmentsForCurrentUser: data.personalEquipmentsForCurrentUser.map(eq => {
          if (eq.id === id) {
            return {...eq, title, productLink, quantity, settled, packed}
          }
          return eq
        })}
        store.writeQuery({query: PERSONAL_EQUIPMENTS, variables: {travelTipId}, data: newData})
      }
    }).then((result) => {
      console.log("Equipment updated: ", result);
      return result;
    });
  },

  deletePersonalEquipment: function (personalEquipmentId, travelTipId) {
    console.log("Deleting equipment: " + personalEquipmentId);

    return apolloClient.mutate({
      mutation: DELETE_PERSONAL_EQUIPMENT,
      variables: {
        personalEquipmentId
      },
      update: (store, {data: {deleteEquipment}}) => {
        const data = store.readQuery({query: PERSONAL_EQUIPMENTS, variables: {travelTipId}})
        const newData = {personalEquipmentsForCurrentUser: data.personalEquipmentsForCurrentUser.filter(eq => eq.id !== personalEquipmentId)}
        store.writeQuery({query: PERSONAL_EQUIPMENTS, variables: {travelTipId}, data: newData})
      }
    }).then((result) => {
      console.log("Equipment deleted: ", result);
      return result;
    });
  },
};
