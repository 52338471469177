import gql from 'graphql-tag';

export const ASSIGN_EQUIPMENT_TO_TRAVEL_TIP = gql`
  mutation AssignEquipmentToTravelTip($travelTipId: ID!, $equipments: [TravelTipEquipmentWrite]) {
    assignEquipmentToTravelTip(travelTipId: $travelTipId, equipments: $equipments) {
      equipment {
        id
      }
    }
  }
`;

export const ASSIGN_EQUIPMENT_TO_USER_TRAVEL_TIP = gql`
  mutation AssignEquipmentToUserTravelTip($travelTipId: ID!, $equipments: [TravelTipUserEquipmentWrite]) {
    assignEquipmentToUserTravelTip(travelTipId: $travelTipId, equipments: $equipments) {
      equipment {
        id
      }
    }
  }
`;

export const CREATE_EQUIPMENT = gql`
  mutation CreateEquipment($categoryId: ID, $originalLanguage: String!, $localizations: [EquipmentLocalizationInput]) {
    createEquipment(categoryId: $categoryId, originalLanguage: $originalLanguage, localizations: $localizations) {
      id
      originalLanguage
      localizations {
        language
        title
        productLink
      }
      category {
        id
        originalLanguage
        localizations {
          language
          title
        }
      }
    }
  }
`;

export const UPDATE_EQUIPMENT = gql`
  mutation UpdateEquipment($equipmentId: ID!, $categoryId: ID, $originalLanguage: String!, $localizations: [EquipmentLocalizationInput]) {
    updateEquipment(equipmentId: $equipmentId, categoryId: $categoryId, originalLanguage: $originalLanguage, localizations: $localizations) {
      id
      category {
        id
        originalLanguage
        localizations {
          language
          title
        }
      }
      originalLanguage
      localizations {
        language
        title
        productLink
      }
    }
  }
`;

export const DELETE_EQUIPMENT = gql`
  mutation DeleteEquipment($equipmentId: ID!) {
    deleteEquipment(equipmentId: $equipmentId)
  }
`;

export const CREATE_EQUIPMENT_CATEGORY = gql`
  mutation CreateEquipmentCategory($iconId: ID, $originalLanguage: String!, $localizations: [EquipmentCategoryLocalizationInput]) {
    createEquipmentCategory(iconId: $iconId, originalLanguage: $originalLanguage, localizations: $localizations) {
      id
      originalLanguage
      localizations {
        language
        title
        published
      }
      icon {
        id
        originalLanguage
        localizations {
          language
          title
        }
        fileName
        author {
          id
        }
      }
    }
  }
`;

export const UPDATE_EQUIPMENT_CATEGORY = gql`
  mutation UpdateEquipmentCategory($equipmentCategoryId: ID!, $iconId: ID, $originalLanguage: String!, $localizations: [EquipmentCategoryLocalizationInput]) {
    updateEquipmentCategory(equipmentCategoryId: $equipmentCategoryId, iconId: $iconId, originalLanguage: $originalLanguage, localizations: $localizations) {
      id
      originalLanguage
      localizations {
        language
        title
        published
      }
      icon {
        id
        originalLanguage
        localizations {
          language
          title
        }
        fileName
        author {
          id
        }
      }
    }
  }
`;

export const DELETE_EQUIPMENT_CATEGORY = gql`
  mutation DeleteEquipmentCategory($equipmentCategoryId: ID!) {
    deleteEquipmentCategory(equipmentCategoryId: $equipmentCategoryId)
  }
`;

export const CREATE_EQUIPMENT_TEMPLATE = gql`
  mutation CreateEquipmentTemplate($equipmentIds: [ID], $originalLanguage: String!, $localizations: [EquipmentTemplateLocalizationInput]) {
    createEquipmentTemplate(equipmentIds: $equipmentIds, originalLanguage: $originalLanguage, localizations: $localizations) {
      id
      originalLanguage
      localizations {
        language
        title
      }
      equipments {
        id
        category {
          id
          icon {
            id
            localizations {
              title
            }
            fileName
            author {
              id
            }
          }
        }
        originalLanguage
        localizations {
          title
          productLink
        }
      }
    }
  }
`;

export const UPDATE_EQUIPMENT_TEMPLATE = gql`
  mutation UpdateEquipmentTemplate($equipmentTemplateId: ID!, $equipmentIds: [ID], $originalLanguage: String!, $localizations: [EquipmentTemplateLocalizationInput]) {
    updateEquipmentTemplate(equipmentTemplateId: $equipmentTemplateId, equipmentIds: $equipmentIds, originalLanguage: $originalLanguage, localizations: $localizations) {
      id
      originalLanguage
      localizations {
        language
        title
      }
      equipments {
        id
        category {
          id
          icon {
            id
            localizations {
              title
            }
            fileName
            author {
              id
            }
          }
        }
        originalLanguage
        localizations {
          title
          productLink
        }
      }
    }
  }
`;

export const DELETE_EQUIPMENT_TEMPLATE = gql`
  mutation DeleteEquipmentTemplate($equipmentTemplateId: ID!) {
    deleteEquipmentTemplate(equipmentTemplateId: $equipmentTemplateId)
  }
`;

export const CREATE_PERSONAL_EQUIPMENT = gql`
  mutation createPersonalEquipment($equipmentCategoryId: ID, $title: String!, $productLink: String, $travelTipId: ID!, $quantity: Int, $settled: Boolean!, $packed: Boolean!) {
    createPersonalEquipment(equipmentCategoryId: $equipmentCategoryId, title: $title, productLink: $productLink, travelTipId: $travelTipId, quantity: $quantity, settled: $settled, packed: $packed) {
      id
      title
      productLink
      travelTipId
      quantity
      settled
      packed
      category {
        id
        originalLanguage
        localizations {
          language
          title
        }
        icon {
          id
          fileName
        }
      }
    }
  }
`;

export const UPDATE_PERSONAL_EQUIPMENT = gql`
  mutation updatePersonalEquipment($personalEquipmentId: ID!, $equipmentCategoryId: ID, $title: String!, $productLink: String, $quantity: Int, $settled: Boolean!, $packed: Boolean!) {
    updatePersonalEquipment(personalEquipmentId: $personalEquipmentId, equipmentCategoryId: $equipmentCategoryId, title: $title, productLink: $productLink, quantity: $quantity, settled: $settled, packed: $packed)
  }
`;

export const DELETE_PERSONAL_EQUIPMENT = gql`
  mutation deletePersonalEquipment($personalEquipmentId: ID!) {
    deletePersonalEquipment(personalEquipmentId: $personalEquipmentId)
  }
`;




